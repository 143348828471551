class FaceoffsPlayersController {
  private players: {
    _id: string;
    fullName: string;
    jerseyNumber: string;
    totalEvents: any[];
    winEvents: any[];
    loseEvents: any[];
    opponents?: any[];
  }[];
  private eventFilter;
  private onUpdateFilter;

  constructor(private GameService) {}

  isFilterSelected(player, value: 'totalEvents' | 'winEvents' | 'loseEvents') {
    if (player._id !== this.eventFilter.playerId) {
      return false;
    }
    if (this.eventFilter.opponentId) {
      return false;
    }
    return this.eventFilter.value === value;
  }

  opponentFilter(player, opponent) {
    const {playerId, opponentId} = this.eventFilter;
    this.onUpdateFilter({
      $playerId: player._id,
      $value: '',
      $opponentId: player._id === playerId && opponentId === opponent._id ? '' : opponent._id,
    });
  }

  playerFilter(player) {
    const {playerId} = this.eventFilter;
    this.onUpdateFilter({
      $playerId: playerId === player._id ? '' : player._id,
      $value: '',
      $opponentId: '',
    });
  }

  valueFilter(player, newValue) {
    const {playerId, value} = this.eventFilter;
    this.onUpdateFilter({
      $playerId: player._id,
      $value: playerId === player._id && value === newValue ? '' : newValue,
      $opponentId: '',
    });
  }

  isOpponentAvailable(player) {
    return player.opponents && player.opponents.length;
  }

  playEvents(events) {
    this.GameService.openVirtualClipViewer('Faceoffs', events);
  }
}

angular.module('app.general').component('faceoffsPlayers', {
  templateUrl: 'general/components/faceoffs-players.html',
  controller: FaceoffsPlayersController as any,
  bindings: {
    players: '<',
    eventFilter: '<',
    onUpdateFilter: '&',
  },
});
