class ShareWithStaffAutocomplete {
  readonly selections;
  readonly fixedSelections;
  readonly excludeIds;
  readonly disableSelectAll;
  private onSelectionChange;

  private currentUser;
  private fetchPromise;
  private staffSuggestions;
  private staffSuggestions1;
  private staffSuggestions2;
  private selectAllState;

  private searchText: string;

  constructor(
    private $q,
    private OrganizationService,
    private UserService,
    private _,
  ) {
    this.currentUser = UserService.getUser();
    this.fetchPromise = $q(() => {
      //
    });
    this.staffSuggestions = [];
    this.selectAllState = {
      staff: false,
    };
  }

  $onInit() {
    this.fetchSuggestions();
  }

  $onChanges(changes) {
    if (changes.selections.currentValue !== changes.selections.previousValue) {
      this._syncState();
    }
  }

  select($selection) {
    if (!this.isSelected($selection)) {
      const selections = (this.selections || []).concat([$selection]);
      this.onSelectionChange({$selections: selections});
    }
  }

  selectAll(users, position) {
    let selections = this.selections || [];
    const shouldSelectAll = this.selectAllState[position];

    if (shouldSelectAll) {
      const tobeSelected = users.filter((user) => !this.isSelected(user));
      selections = selections.concat(tobeSelected);
      this.onSelectionChange({$selections: selections});
    } else {
      selections = selections.filter(
        (selection) => !users.some((user) => selection._id === user._id),
      );
      this.onSelectionChange({$selections: selections});
    }
  }

  unselect($selection, $index) {
    const selections = (this.selections || []).filter((item, index) => index !== $index);
    this.onSelectionChange({$selections: selections});
  }

  isSelected(item) {
    return (this.selections || []).some(
      (selection) => selection._id === item._id && selection.organizationId === item.organizationId,
    );
  }

  searchForSuggestions(searchText: string) {
    this.searchText = searchText;
  }

  fetchSuggestions() {
    const excludeIds = this.excludeIds || [];

    this.fetchPromise = this.OrganizationService.listMembersWithHierarchy().then((members) => {
      const suggestions = (members || []).filter(
        (user) => excludeIds.indexOf(user._id) === -1 && user._id !== this.currentUser._id,
      );

      this.staffSuggestions = suggestions
        .filter(
          (member) => member.roles.length > 1 || (member.roles[0] && member.roles[0] !== 'Player'),
        )
        .map((user) => this._getUserDisplay(user));

      const [col1, col2] = _.chunk(
        this.staffSuggestions,
        Math.ceil(this.staffSuggestions.length / 2),
      );

      this.staffSuggestions1 = col1;
      this.staffSuggestions2 = col2;
    });
  }

  _syncState() {
    this.selectAllState.staff =
      this.staffSuggestions &&
      this.staffSuggestions.length &&
      this.staffSuggestions.every((user) => this.isSelected(user));
  }

  _getUserDisplay(user: any) {
    const firstName = this._.get(user, 'profile.firstName', '');
    const lastName = this._.get(user, 'profile.lastName', '');
    const position = this._.get(user, 'profile.playerPosition', '');
    const fullName = this._.joinIfPresent(' ', firstName, lastName);
    const roleDisplay = `${user.functions.join(', ') || user.roles.join(', ')} <${
      user.organization.name
    }>`;

    return {
      _id: this._.get(user, '_id', ''),
      type: 'user',
      organizationId: this._.get(user, 'organization._id', ''),
      roles: roleDisplay,
      display: fullName,
      position,
    };
  }
}

angular.module('app.general').component('shareWithStaffAutocomplete', {
  templateUrl: 'general/components/sharing/share-with-staff-autocomplete.html',
  controller: ShareWithStaffAutocomplete as any,
  bindings: {
    selections: '<',
    fixedSelections: '<',
    excludeIds: '<',
    disableSelectAll: '<',
    placeholder: '@',
    onSelectionChange: '&?',
  },
});
