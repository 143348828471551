(function () {
  angular.module('app.general').filter('percentage', percentageFilter);

  function percentageFilter() {
    return function (input) {
      if (typeof input !== 'number' || Number.isNaN(input)) {
        return '0%';
      }
      return (input * 100).toFixed(0) + '%';
    };
  }
})();
