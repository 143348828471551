(function () {
  'use strict';

  angular.module('app.general').directive('mdTabs', mdTabsDirective);

  function mdTabsDirective($mdUtil, $rootScope) {
    return {
      restrict: 'E',
      require: 'mdTabs',

      link: function ($scope, $element, $attr, $mdTabs) {
        $mdTabs.scroll = angular.noop;
        $mdTabs.selectSubscribers = $mdTabs.selectSubscribers || [];

        const $original = {
          select: $mdTabs.select,
          incrementIndex: $mdTabs.incrementIndex,
          insertTab: $mdTabs.insertTab,
          removeTab: $mdTabs.removeTab,
        };

        $mdTabs.select = function (tabIndex) {
          $mdTabs.selectSubscribers.forEach(function (subscriber) {
            subscriber(tabIndex);
          });

          $mdUtil.nextTick(function () {
            $original.select(tabIndex);
          });
        };

        // used by swipe and keyboard events
        $mdTabs.incrementIndex = function () {
          // eslint-disable-next-line prefer-rest-params
          $original.incrementIndex.apply($mdTabs, arguments);

          const selectedIndex = $mdTabs.selectedIndex;
          $mdTabs.selectSubscribers.forEach(function (subscriber) {
            subscriber(selectedIndex);
          });
        };

        $mdTabs.insertTab = function () {
          // eslint-disable-next-line prefer-rest-params
          const result = $original.insertTab.apply($mdTabs, arguments);

          $mdUtil.nextTick(function () {
            $rootScope.$broadcast('$mdTabs:modified');
          });
          return result;
        };

        $mdTabs.removeTab = function () {
          // eslint-disable-next-line prefer-rest-params
          const result = $original.removeTab.apply($mdTabs, arguments);

          $mdUtil.nextTick(function () {
            $rootScope.$broadcast('$mdTabs:modified');
          });
          return result;
        };
      },
    };
  }
})();
