var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var StandAloneAppService = (function () {
    function StandAloneAppService($q, ENV, $http, Toastr, BUILD) {
        this.$q = $q;
        this.ENV = ENV;
        this.$http = $http;
        this.Toastr = Toastr;
        this.BUILD = BUILD;
        var cloudfrontUrl = 'https://d3cjdsggcpzzw0.cloudfront.net';
        var TAGGING_EDITOR_MODULE_URL = {
            production: "".concat(cloudfrontUrl, "/manifests/manifest.taggingEditor.production.json"),
            staging: "".concat(cloudfrontUrl, "/manifests/manifest.taggingEditor.staging.json"),
            development: "".concat(cloudfrontUrl, "/manifests/manifest.taggingEditor.development.json"),
            local: "http://localhost:9005/wg-taggingEditor.js",
        };
        var VIDEO_UPLOAD_MODULE_URL = {
            production: "".concat(cloudfrontUrl, "/manifests/manifest.uploader.production.json"),
            staging: "".concat(cloudfrontUrl, "/manifests/manifest.uploader.staging.json"),
            development: "".concat(cloudfrontUrl, "/manifests/manifest.uploader.development.json"),
            local: "http://localhost:9005/wg-uploader.js",
        };
        var ADVANCE_ANALYTICS_URL = {
            production: "".concat(cloudfrontUrl, "/manifests/manifest.advancedAnalytics.production.json"),
            staging: "".concat(cloudfrontUrl, "/manifests/manifest.advancedAnalytics.staging.json"),
            development: "".concat(cloudfrontUrl, "/manifests/manifest.advancedAnalytics.development.json"),
            local: "http://localhost:9005/wg-advancedAnalytics.js",
        };
        var PLAYER_RATING_URL = {
            production: "".concat(cloudfrontUrl, "/manifests/manifest.playerRating.production.json"),
            staging: "".concat(cloudfrontUrl, "/manifests/manifest.playerRating.staging.json"),
            development: "".concat(cloudfrontUrl, "/manifests/manifest.playerRating.development.json"),
            local: "http://localhost:9005/wg-playerRating.js",
        };
        this.moduleResolver = {
            urls: {
                SCTaggingEditorModule: TAGGING_EDITOR_MODULE_URL[ENV],
                SCUploaderModule: VIDEO_UPLOAD_MODULE_URL[ENV],
                SCAdvancedAnalyticsModule: ADVANCE_ANALYTICS_URL[ENV],
                SCPlayerRatingModule: PLAYER_RATING_URL[ENV],
            },
            scriptUrls: {},
            modules: {},
        };
    }
    StandAloneAppService.prototype.loadModule = function (moduleName) {
        var _this = this;
        var scriptId = "".concat(moduleName, "-js");
        var existedScript = document.getElementById(scriptId);
        if (existedScript) {
            existedScript.parentNode.removeChild(existedScript);
        }
        var $setScriptUrlPromise = this.setScriptUrl(moduleName);
        this.moduleResolver.modules[moduleName] = this.$q(function (resolve, reject) {
            $setScriptUrlPromise
                .then(function (scriptUrl) {
                var script = document.createElement('script');
                script.async = true;
                script.id = scriptId;
                script.crossOrigin = 'anonymous';
                script.onload = function () {
                    var moduleNs = window[moduleName];
                    if (moduleNs) {
                        resolve(moduleNs);
                    }
                    else {
                        reject({
                            mesasge: "Failed to load ".concat(moduleName, " widget: no exported namespace"),
                        });
                    }
                };
                script.onerror = function () {
                    reject({
                        mesasge: "Failed to load ".concat(moduleName, " widget: script download failed"),
                    });
                };
                script.src = scriptUrl;
                document.head.appendChild(script);
            })
                .catch(function (err) { return reject(err); });
        }).catch(function (err) {
            var errorMessage = err.message || "Failed to load ".concat(moduleName);
            _this.Toastr.error(errorMessage);
            if (window.trackJs) {
                window.trackJs.track("WIDGET_LOAD_ERROR: ".concat(errorMessage));
            }
            throw new Error(errorMessage);
        });
        return this.moduleResolver.modules[moduleName];
    };
    StandAloneAppService.prototype.loadTaggingEditorModule = function () {
        return this.loadModule('SCTaggingEditorModule');
    };
    StandAloneAppService.prototype.loadVideoUploadModule = function () {
        return this.loadModule('SCUploaderModule');
    };
    StandAloneAppService.prototype.loadAdvanceAnalyticsModule = function () {
        return this.loadModule('SCAdvancedAnalyticsModule');
    };
    StandAloneAppService.prototype.loadPlayerRatingModule = function () {
        return this.loadModule('SCPlayerRatingModule');
    };
    StandAloneAppService.prototype.setScriptUrl = function (moduleName) {
        var _this = this;
        return this.$q(function (resolve, reject) {
            if (_this.moduleResolver.scriptUrls[moduleName]) {
                resolve(_this.moduleResolver.scriptUrls[moduleName]);
                return;
            }
            var moduleUrl = _this.moduleResolver.urls[moduleName];
            if (moduleUrl.endsWith('.json')) {
                _this.loadManifest(moduleUrl, moduleName)
                    .then(function (data) {
                    _this.moduleResolver.scriptUrls[moduleName] = data.url;
                    resolve(data.url);
                })
                    .catch(function () {
                    return reject({
                        message: "Failed to load ".concat(moduleName, " widget: failed to load module manifest file"),
                    });
                });
            }
            else {
                _this.moduleResolver.scriptUrls[moduleName] = moduleUrl;
                resolve(moduleUrl);
            }
        });
    };
    StandAloneAppService.prototype.loadAllManifests = function () {
        var _this = this;
        var urlKeys = Object.keys(this.moduleResolver.urls);
        var namesAndUrls = urlKeys.map(function (key) {
            return { url: _this.moduleResolver.urls[key], name: key };
        });
        var promises = namesAndUrls.map(function (nameAndUrl) {
            return _this.loadManifest(nameAndUrl.url, nameAndUrl.name).then(function (manifest) {
                return __assign({ name: nameAndUrl.name }, manifest);
            });
        });
        return this.$q.all(promises);
    };
    StandAloneAppService.prototype.loadManifest = function (moduleUrl, moduleName) {
        var _this = this;
        return this.$q(function (resolve, reject) {
            _this.$http
                .get(moduleUrl, { headers: { 'Cache-Control': 'no-cache' } })
                .then(function (res) {
                if (!res || !res.data || !res.data.url) {
                    reject({ message: "Failed to load manifest file for ".concat(moduleName, " widget") });
                }
                else {
                    var _a = res.data, url = _a.url, version = _a.version, commit = _a.commit;
                    resolve({ url: url, version: version, commit: commit });
                }
            })
                .catch(function () {
                reject({
                    message: "Failed to load manifest file for ".concat(moduleName, " widget"),
                });
            });
        });
    };
    return StandAloneAppService;
}());
angular.module('app.general').service('StandAloneAppService', StandAloneAppService);