(function () {
  'use strict';

  angular.module('app.general').component('deviceTablet', {
    templateUrl: 'general/components/device-tablet.html',
    controller: TabletDeviceController,
  });

  function TabletDeviceController($location, RuntimeRequirements) {
    this.basicRuntimeAvailable = RuntimeRequirements.isMinimal;
    this.recommended = RuntimeRequirements.recommendedVersions;

    this.proceed = function () {
      RuntimeRequirements.ignoreRecommendation();
      RuntimeRequirements.returnBack();
    };
  }
})();
