class BusyModalController {
  private promise;

  constructor(private $q) {
    this.promise = $q(function () {});
  }
}

angular.module('app.general').component('busyModal', {
  templateUrl: 'general/components/busy-modal.html',
  controller: BusyModalController,
  bindings: {
    text: '@',
  },
});
