class History {
  private history = [];

  constructor(private $state) {}

  add(state: angular.ui.IState, params) {
    if (!this.isStateOverlay(state)) {
      this.history.unshift({state, params});
    }

    this.history.splice(20);
  }

  goBack() {
    this.$state.go(this.lastState.state, this.lastState.params);
  }

  private isStateOverlay(state: angular.ui.IState) {
    return /^profile/.test(state.name) || /^settings/.test(state.name);
  }

  private get lastState() {
    return this.history[0] || this.defaultState;
  }

  private get defaultState() {
    return {state: 'root.platform', params: {}};
  }
}

angular.module('app.general').service('History', History);
