class VersionChangeDetectorController {
  private timer;
  private currentVersion;
  private checkInterval;
  private modalVisible;
  private widgetManifests = [];

  constructor(
    private ENV,
    private $http,
    private $uibModal,
    private WakeUpTimer,
    private $timeout,
    private StandAloneAppService,
  ) {
    this.checkInterval = 10000;
    this.modalVisible = false;
  }

  $onInit() {
    this.$http
      .get('/version.json?t=' + Date.now())
      .then((res) => {
        this.currentVersion = res.data && res.data.sha;
      })
      .then(() => {
        this.StandAloneAppService.loadAllManifests().then((manifests) => {
          this.widgetManifests = manifests;
        });
      })
      .catch(angular.noop);

    this.WakeUpTimer.onWakeUp(() => this.doCheck());
    this.timer = setInterval(() => {
      this.doCheck();
    }, this.checkInterval);
  }

  doCheck() {
    this.$http
      .get('/version.json?t=' + Date.now())
      .then((res) => {
        if (
          res.data &&
          res.data.sha &&
          this.currentVersion &&
          res.data.sha !== this.currentVersion
        ) {
          this.onNewVersionAvailable();
        }
      })
      .then(() => this.StandAloneAppService.loadAllManifests())
      .then((manifests) => {
        for (const manifest of manifests) {
          const currentManifest = this.widgetManifests.find((m) => m.name === manifest.name);
          if (currentManifest && currentManifest.commit !== manifest.commit) {
            this.onNewVersionAvailable();
            break;
          }
        }
      })
      .catch(angular.noop);
  }

  onNewVersionAvailable() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.modalVisible) {
      return;
    }

    this.modalVisible = true;
    this.$uibModal.open({
      size: 'sm',
      template: '<new-version-modal />',
      backdrop: 'static', // prevent close on clicking background
      keyboard: false, // prevent esc button
    });
  }
}

angular.module('app.general').component('versionChangeDetector', {
  template: '<span></span>',
  controller: VersionChangeDetectorController,
});
