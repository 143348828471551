angular.module('app.general').filter('scAgentLicense', scAgentLicenseFilter);

function scAgentLicenseFilter() {
  return function (license) {
    switch (license) {
      case 'NHLPA':
        return 'NHLPA (USA/CAN)';

      case 'KHL':
        return 'KHL (RUS)';

      case 'DEL':
        return 'DEL (GER)';

      case 'DEL2':
        return 'DEL2 (GER)';

      case 'SICO':
        return 'SICO (SWE)';

      case 'PHPA':
        return 'PHPA (USA/CAN)';

      case 'FHPA':
        return 'FHPA (FIN)';

      case 'SECO':
        return 'SECO (SUI)';

      case 'National League':
        return 'National League';

      case 'FFHG':
        return 'FFHG (FRA)';

      case 'CSLH':
        return 'CSLH (CZE)';

      default:
        return license;
    }
  };
}
