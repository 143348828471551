(function () {
  'use strict';

  angular
    .module('app.general')
    .directive('tabStateActive', tabStateActiveDirective)
    .controller('TabStateActiveController', TabStateActiveController);

  function tabStateActiveDirective($timeout) {
    return {
      restrict: 'A',
      controller: 'TabStateActiveController',
      require: ['tabStateActive', '^?mdTabs'],
      link: function ($scope, $element, $attr, controllers) {
        controllers[0].setClassHandler = function () {
          $element.addClass($attr.tabStateActive);

          if (controllers[1]) {
            var mdTabIndex = controllers[1].getTabElementIndex($element);
            // fix-up for hs#246, when select too early, title of the tab is not set yet
            $timeout(function () {
              controllers[1].select(mdTabIndex);
            });
          }
        };

        controllers[0].removeClassHandler = function () {
          $element.removeClass($attr.tabStateActive);
        };
      },
    };
  }

  function TabStateActiveController($scope, $state, $timeout) {
    var observableState;
    this.setObservableState = setObservableState;

    this.setClassHandler = angular.noop;
    this.removeClassHandler = angular.noop;

    var vm = this;

    function setObservableState(state) {
      observableState = state;
      $timeout(checkIfObservingStateActive);
    }

    function checkIfObservingStateActive(event, toState, toParams, fromState, fromParams) {
      if ($state.includes(observableState) && angular.equals(toParams, fromParams)) {
        vm.setClassHandler();
      } else {
        vm.removeClassHandler();
      }
    }

    $scope.$on('$stateChangeSuccess', checkIfObservingStateActive);
  }
})();
