(function () {
  'use strict';

  angular.module('app.general').directive('scTouchFormInputs', scTouchFormInputs);

  function scTouchFormInputs() {
    return {
      restrict: 'A',
      require: '^form',

      link: function ($scope, $element, $attr, $formCtrl) {
        $scope.$watch(
          () => $formCtrl.$$controls.length,
          () => {
            $formCtrl.$$controls.forEach((model) => {
              model.$setTouched();
            });
          },
        );
      },
    };
  }
})();
