(function () {
  'use strict';

  angular.module('app.general').filter('scUserDisplayName', scUserDisplayName);

  function scUserDisplayName() {
    return function (user) {
      var firstName = _.get(user, 'profile.firstName') || _.get(user, 'firstName');
      var lastName = _.get(user, 'profile.lastName') || _.get(user, 'lastName');
      var username = _.get(user, 'username');

      if (firstName || lastName) {
        return firstName + ' ' + lastName;
      } else {
        return username;
      }
    };
  }
})();
