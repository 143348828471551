'use strict';
var STORAGE_KEY = 'app:requirements:ignore-recommendation';
var BROWSER_REQUIREMENTS = {
    Chrome: { minimal: 55, recommended: 60 },
    Firefox: { minimal: 50, recommended: 58 },
    Edge: { minimal: 12, recommended: 60 },
    Safari: { minimal: 10, recommended: null },
};
var RuntimeRequirements = (function () {
    function RuntimeRequirements($location) {
        this.$location = $location;
        var uaParser = new window.UAParser();
        var browser = uaParser.getBrowser();
        var device = uaParser.getDevice();
        this.version = parseInt(browser.version);
        var recommendedRequirement = _.get(BROWSER_REQUIREMENTS, "".concat(browser.name, ".recommended"));
        var minimalRequirement = _.get(BROWSER_REQUIREMENTS, "".concat(browser.name, ".minimal"));
        this.isVendorRecommended = !!recommendedRequirement;
        this.isRecommended = !!recommendedRequirement && this.version >= recommendedRequirement;
        this.isMinimal = !!minimalRequirement && this.version >= minimalRequirement;
        this.isTablet = device.type === 'tablet';
        this.isMobile = device.type === 'mobile';
        this.ignoringRecommendations = localStorage.getItem(STORAGE_KEY) !== null;
    }
    Object.defineProperty(RuntimeRequirements.prototype, "isRecommendationIgnored", {
        get: function () {
            return this.ignoringRecommendations;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RuntimeRequirements.prototype, "minimalVersions", {
        get: function () {
            return _.mapValues(BROWSER_REQUIREMENTS, function (item) { return item.minimal; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RuntimeRequirements.prototype, "recommendedVersions", {
        get: function () {
            return _.mapValues(BROWSER_REQUIREMENTS, function (item) { return item.recommended; });
        },
        enumerable: false,
        configurable: true
    });
    RuntimeRequirements.prototype.setReturnUrl = function (url) {
        this.returnUrl = url;
    };
    RuntimeRequirements.prototype.ignoreRecommendation = function () {
        this.ignoringRecommendations = true;
        localStorage.setItem(STORAGE_KEY, 'true');
    };
    RuntimeRequirements.prototype.returnBack = function () {
        if (this.returnUrl) {
            this.$location.url(this.returnUrl);
            this.returnUrl = null;
        }
        else {
            this.$location.url('/');
        }
    };
    return RuntimeRequirements;
}());
angular.module('app.general').service('RuntimeRequirements', RuntimeRequirements);