class CommunicationMemberCache {
  private byId;
  private promiseById;

  constructor(
    private $q,
    private $http,
    private SCConfiguration,
    private _,
  ) {
    this.byId = {};
    this.promiseById = {};
  }

  update(channels) {
    channels = channels || [];
    channels.forEach((channel) => {
      (channel.users || []).forEach((user) => (this.byId[`user:${user.id}`] = user));
      (channel.organizations || []).forEach((org) => (this.byId[`org:${org.id}`] = org));
    });
  }

  getMember(type, id) {
    if (!type || !id) {
      return this.$q.resolve();
    }

    const key = `${type}:${id}`;

    const member = this.byId[key];
    if (member) {
      return this.$q.resolve(member);
    }

    const existingPromise = this.promiseById[key];
    if (existingPromise) {
      return existingPromise;
    }

    const promise = this.fetchMember(type, id).then((member) => {
      this.byId[`${type}:${id}`] = member;
      return member;
    });
    this.promiseById[key] = promise;

    return promise;
  }

  getDisplay(type, id) {
    const member = this.byId[`${type}:${id}`];

    if (member) {
      return this.$q.resolve(this._getDisplay(type, member));
    }

    return this.fetchMember(type, id).then((member) => {
      this.byId[`${type}:${id}`] = member;
      return this._getDisplay(type, member);
    });
  }

  getDisplayAndPicture(type, id) {
    const member = this.byId[`${type}:${id}`];

    if (member) {
      return this.$q.resolve({
        name: this._getDisplay(type, member),
        picture: this._getPicture(type, member),
      });
    }

    return this.fetchMember(type, id).then((member) => {
      this.byId[`${type}:${id}`] = member;
      return {
        name: this._getDisplay(type, member),
        picture: this._getPicture(type, member),
      };
    });
  }

  _getDisplay(type, member) {
    if (type === 'user') {
      return this._.joinIfPresent(
        ' ',
        this._.get(member, 'first_name'),
        this._.get(member, 'last_name'),
      );
    }

    return _.get(member, 'name');
  }

  _getPicture(type, member) {
    switch (type) {
      case 'user':
        return member.avatar || '/img/phimg-player.jpg';

      case 'org':
        return '/img/phimg-club.jpg';
    }
  }

  fetchMember(type, id) {
    const url = [
      this.SCConfiguration.getEndpoint(),
      'api/communication/member-info',
      type,
      id,
    ].join('/');

    return this.$http.get(url).then((response) => response.data && response.data[0]);
  }

  // this called seems not to be related to app.communication module
  // but it will helps app.communcation module not to be a dependency to app.team module
  // TODO: considered moving TeamService to general ?
  fetchTeamLeagues(teamId) {
    const url = this.SCConfiguration.getEndpoint() + `/api/teams/${teamId}/leagues`;
    return this.$http.get(url).then((response) => response.data);
  }
}

angular.module('app.general').service('CommunicationMemberCache', CommunicationMemberCache);
