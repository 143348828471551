(function () {
  'use strict';

  angular
    .module('app.general')
    .directive('tabSwitcher', tabSwitcherDirective)
    .controller('TabSwitcherController', TabSwitcherController);

  function tabSwitcherDirective($rootScope) {
    function checkRequirements($attr) {
      if (!$attr.state && !$attr.module) {
        throw new Error(
          'You should specify state name with "state" or module name with "module" attribute'
        );
      }
      if ($attr.tabTitle === undefined) {
        throw new Error('You should specify state name with "tab-title" attribute');
      }
    }

    function isDisabled($attr) {
      return $attr.disabled !== undefined;
    }

    function bindToElement($element, $attr, $controller) {
      $element.click(function (event) {
        if (isDisabled($attr)) {
          return false;
        }

        $rootScope.$apply(function () {
          if ($attr.target === '_blank' || event.ctrlKey || event.metaKey) {
            $controller.openInNewBackgroundTab();
          } else if ($attr.target === '_newtab') {
            $controller.openInNewForegroundTab();
          } else if (event.button === 0) {
            $controller.openInCurrentTab();
          } else if (event.button === 1) {
            $controller.openInNewBackgroundTab();
          } else {
            $controller.openInCurrentTab();
          }
        });

        return false;
      });
    }

    function bindToMdTabs($element, $attr, $controller, $mdTabs) {
      $mdTabs.selectSubscribers = $mdTabs.selectSubscribers || [];
      $mdTabs.selectSubscribers.push(function (tabIndex) {
        if ($mdTabs.getTabElementIndex($element) === tabIndex) {
          $controller.openInCurrentTab();
        }
      });
    }

    return {
      restrict: 'A',
      controller: 'TabSwitcherController',
      require: ['tabSwitcher', '^?tabStateActive', '^?mdTabs'],

      link: function ($scope, $element, $attr, controllers) {
        var $controller = controllers[0],
          $tabStateActive = controllers[1],
          $mdTabs = controllers[2];

        checkRequirements($attr);

        $controller.setState($attr.module, $attr.state);
        if ($tabStateActive) {
          $tabStateActive.setObservableState($controller.getState());
        }

        $attr.$observe('tabTitle', function (title) {
          $controller.setTitle($attr.module, title);

          if ($attr.noTitle === undefined) {
            $attr.$set('title', $controller.getTitle());
          }
        });

        if ($attr.params) {
          $scope.$watch(
            $attr.params,
            function (params) {
              $controller.setParams(params);
              $attr.$set('href', $controller.getUrl());
            },
            true
          );
        } else {
          $controller.setParams(null);
        }

        if ($mdTabs && $mdTabs.getTabElementIndex($element) >= 0) {
          bindToMdTabs($element, $attr, $controller, $mdTabs);
        } else {
          bindToElement($element, $attr, $controller);
        }
      },
    };
  }

  function TabSwitcherController($rootScope, $state, $stateParams, Tabs) {
    var state = null,
      params = null,
      title = null;

    this.openInCurrentTab = openInCurrentTab;
    this.openInNewForegroundTab = openInNewForegroundTab;
    this.openInNewBackgroundTab = openInNewBackgroundTab;

    this.getState = function () {
      return state;
    };
    this.getTitle = function () {
      return title;
    };
    this.getParams = function () {
      return params;
    };
    this.getUrl = getUrl;

    this.setState = setState;
    this.setParams = setParams;
    this.setTitle = setTitle;

    function openInCurrentTab() {
      Tabs.goTo(state, params, title);
    }

    function openInNewForegroundTab() {
      Tabs.newForegroundTab(state, params, title);
    }

    function openInNewBackgroundTab() {
      Tabs.newBackgroundTab(state, params, title);
    }

    function setState(moduleName, stateName) {
      if (moduleName) {
        state = $rootScope.getDefaultStateForModule(moduleName);
      } else {
        state = stateName;
      }
    }

    function setParams(newParams) {
      params = _.extend({}, $stateParams, newParams);
    }

    function setTitle(moduleName, newTitle) {
      var prefix = '';
      if (moduleName) {
        prefix = $rootScope.getDefaultTitlePrefixForModule(moduleName) + ' ';
      }

      title = prefix + newTitle;
    }

    function getUrl() {
      return $state.href(state, params);
    }
  }
})();
