class PageVisibility {
  private hiddenProp;
  private visibilityEvent;
  private state;

  constructor(
    private $window,
    private $document,
    private $rootScope,
  ) {
    this.state = {
      isVisible: true,
    };

    // Set the name of the hidden property and the change event for visibility
    if (typeof $document[0].hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      this.hiddenProp = 'hidden';
      this.visibilityEvent = 'visibilitychange';
    } else if (typeof $document[0].msHidden !== 'undefined') {
      this.hiddenProp = 'msHidden';
      this.visibilityEvent = 'msvisibilitychange';
    } else if (typeof $document[0].webkitHidden !== 'undefined') {
      this.hiddenProp = 'webkitHidden';
      this.visibilityEvent = 'webkitvisibilitychange';
    }
    if ($window.addEventListener) {
      $window.addEventListener(
        this.visibilityEvent,
        () => {
          this.state.isVisible = !$document[0][this.hiddenProp];
          $rootScope.$digest();
          $rootScope.$broadcast('$visibilitychange', this.state.isVisible);
        },
        true,
      );
    }
  }
}

angular.module('app.general').service('PageVisibility', PageVisibility);
