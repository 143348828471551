(function () {
  'use strict';

  angular.module('app.general').directive('expandableIcon', expandableIconDirective);

  function expandableIconDirective() {
    var releaseTimeout;

    return {
      restrict: 'C',
      link: function ($scope, $element) {
        var wrappers = [
          $element.closest('md-content'),
          $element.closest('md-tab-content'),
          $element.closest('md-tabs'),
        ];

        $element.on('mouseenter', function () {
          clearTimeout(releaseTimeout);

          wrappers.forEach(function (element) {
            element.css('overflow', 'visible');
          });
        });

        $element.on('mouseleave', function () {
          releaseTimeout = setTimeout(function () {
            wrappers.forEach(function (element) {
              element.css('overflow', '');
            });
          }, 200);
        });
      },
    };
  }
})();
