(() => {
  class PlayerProfileCard {
    private playerId;
    private player;
    private $promise;

    constructor(private PlayerService) {}

    public $onInit() {
      this.$promise = this.PlayerService.getPlayer(this.playerId).then((player) => {
        this.player = player;
      });
    }
  }

  angular.module('app.general').component('playerProfileCard', {
    templateUrl: 'general/components/player-profile-card.html',
    controller: PlayerProfileCard,
    bindings: {
      playerId: '<',
    },
  });
})();
