'use strict';

class NotificationCenterController {
  constructor(
    private $state,
    private UserService,
  ) {}

  dropAdminAccess() {
    return this.UserService.dropAdminAccess().then(() => {
      this.$state.go('root.platform.countries');
    });
  }
}

angular.module('app.general').component('dropAdminBanner', {
  templateUrl: 'general/components/drop-admin-banner.html',
  controller: NotificationCenterController,
});
