'use strict';
var ScheduledMaintenanceMessage = (function () {
    function ScheduledMaintenanceMessage(ScheduledMaintenance) {
        this.ScheduledMaintenance = ScheduledMaintenance;
    }
    ScheduledMaintenanceMessage.prototype.$onInit = function () { };
    ScheduledMaintenanceMessage.prototype.showBanner = function () {
        this.ScheduledMaintenance.showBanner();
    };
    ScheduledMaintenanceMessage.prototype.isScheduledMaintenanceBannerVisible = function () {
        return this.ScheduledMaintenance.isScheduledMaintenanceBannerVisible;
    };
    Object.defineProperty(ScheduledMaintenanceMessage.prototype, "nextScheduledMaintenance", {
        get: function () {
            return this.ScheduledMaintenance.getNextScheduledMaintenance();
        },
        enumerable: false,
        configurable: true
    });
    return ScheduledMaintenanceMessage;
}());
angular.module('app.general').component('scheduledMaintenanceMessage', {
    controller: ScheduledMaintenanceMessage,
    templateUrl: 'general/components/scheduled-maintenance-message.html',
});