var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var UserProfileTitle = (function () {
    function UserProfileTitle($q, CommunicationMemberCache, _) {
        this.$q = $q;
        this.CommunicationMemberCache = CommunicationMemberCache;
        this._ = _;
    }
    UserProfileTitle.prototype.$onInit = function () {
        var _this = this;
        this.$promise = this.CommunicationMemberCache.getMember('user', this.userId).then(function (user) {
            var roles = (user.roles || [])
                .filter(function (role) { return role && !!role.id; })
                .map(function (role) {
                if (role.functions && role.functions.length) {
                    role.functionsDisplay = role.functions.join(', ');
                }
                else {
                    role.functionsDisplay = role.roles.map(function (r) { return r.display; }).join(', ');
                }
                return role;
            });
            _this.user = __assign(__assign({}, user), { displayName: user.profile
                    ? "".concat(user.profile.firstName, " ").concat(user.profile.lastName)
                    : 'Name not available', displayTitle: roles
                    .map(function (role) { return "".concat(role.functionsDisplay, " <").concat(role.resourceName, ">"); })
                    .join(', ') });
        });
    };
    return UserProfileTitle;
}());
angular.module('app.communication').component('userProfileTitle', {
    templateUrl: 'general/components/user-profile-title.html',
    controller: UserProfileTitle,
    bindings: {
        userId: '<',
    },
});