class FaceoffsMapController {
  private faceoffEvents;
  private spots;
  private selectedSpots;
  private onSpotSelected;
  private ZONES = [
    {
      key: 'dz',
      text: 'game.Defending_zone',
    },
    {
      key: 'nz',
      text: 'game.Neutral_zone',
    },
    {
      key: 'oz',
      text: 'game.Attacking_zone',
    },
  ];
  private ZONE_SPOTS = {
    dz: [1, 5],
    nz: [0, 2, 3, 6, 7],
    oz: [4, 8],
  };

  constructor(
    private GameUtils,
    private GameService,
  ) {}

  $onChanges(changes) {
    if (changes.faceoffEvents && changes.faceoffEvents.currentValue) {
      this.calculateFaceoffSpots(changes.faceoffEvents.currentValue);
    }
  }
  calculateFaceoffSpots(faceoffEvents) {
    this.spots = this.GameUtils.faceoffSpots.map((spot) => ({
      x: spot.x,
      y: spot.y,
      totalEvents: [],
      winEvents: [],
      loseEvents: [],
    }));
    if (faceoffEvents) {
      faceoffEvents.forEach((event) => {
        this.spots[event.extra.spotTeamPerspective].totalEvents.push(event);
        if (event.extra.isWinner === true) {
          this.spots[event.extra.spotTeamPerspective].winEvents.push(event);
        }
        if (event.extra.isWinner === false) {
          this.spots[event.extra.spotTeamPerspective].loseEvents.push(event);
        }
      });
    }
  }

  selectSpot(spotIdx, value) {
    if (
      (value === 'totalEvents' && this.spots[spotIdx].totalEvents.length === 0) ||
      (value === 'winEvents' && this.spots[spotIdx].winEvents.length === 0) ||
      (value === 'loseEvents' && this.spots[spotIdx].loseEvents.length === 0)
    ) {
      return;
    }
    this.onSpotSelected({
      $spotIdx: spotIdx,
      $value: value === this.selectedSpots[spotIdx] ? '' : value,
    });
  }
  selectZone(zoneKey) {
    const zoneSpots = this.ZONE_SPOTS[zoneKey];
    const newVal = this.selectedSpots
      .filter((_, i) => zoneSpots.indexOf(i) > -1)
      .some((s) => s !== 'totalEvents')
      ? 'totalEvents'
      : '';
    this.selectedSpots.forEach((_, i) => {
      this.onSpotSelected({
        $spotIdx: i,
        $value: zoneSpots.indexOf(i) > -1 ? newVal : '',
      });
    });
  }
  isZoneSelected(zoneKey) {
    const zoneSpots = this.ZONE_SPOTS[zoneKey];
    const isZoneSpotsSelected = this.selectedSpots
      .filter((_, i) => zoneSpots.indexOf(i) > -1)
      .every((s) => s === 'totalEvents');
    const isOtherSpotsNotSelected = this.selectedSpots
      .filter((_, i) => zoneSpots.indexOf(i) === -1)
      .every((s) => s === '');
    return isZoneSpotsSelected && isOtherSpotsNotSelected;
  }

  getEventsInZone(zone) {
    if (!this.spots || !this.ZONE_SPOTS) {
      return [];
    }
    const totalEvents = this.spots.reduce(
      (currentValue, acc, spotIdx) =>
        this.ZONE_SPOTS[zone].includes(spotIdx)
          ? [...currentValue, ...acc.totalEvents]
          : currentValue,
      [],
    );
    return {
      totalEvents,
      winEvents: totalEvents.filter((evt) => evt.extra.isWinner),
      loseEvents: totalEvents.filter((evt) => !evt.extra.isWinner),
    };
  }
}

angular.module('app.general').component('faceoffsMap', {
  templateUrl: 'general/components/faceoffs-map.html',
  controller: FaceoffsMapController as any,
  bindings: {
    faceoffEvents: '<',
    selectedSpots: '<',
    onSpotSelected: '&',
  },
});
