(function () {
  'use strict';

  angular
    .module('app.general')
    .factory('AdminLoginRequestInterceptor', AdminLoginRequestInterceptor);

  /**
   * This interceptor is used to bypass jwtInterceptor when doing admin-login
   * So the application won't redirect user back to login page if admin-login failed
   */
  function AdminLoginRequestInterceptor($rootScope, $q) {
    var isAdminLoginRequest = false;
    return {
      request: function (config) {
        isAdminLoginRequest = config.url.indexOf('admin-login') > -1;
        return config;
      },
      responseError: function (response) {
        if (
          isAdminLoginRequest &&
          response.status === 401 &&
          response.data.message === 'INVALID_LOGIN_OR_PASSWORD'
        ) {
          response.status = 400;
        }
        return $q.reject(response);
      },
    };
  }
})();
