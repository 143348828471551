'use strict';
var SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX = 'scheduled-maintenance:banner-dismissed:';
var SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
var scheduledMaintenanceList = [
    {
        name: 'infrastructure-update-2023-07-03',
        startDate: new Date(Date.UTC(2023, 6, 3, 0, 0)),
        endDate: new Date(Date.UTC(2023, 6, 14, 23, 59)),
    },
];
var ScheduledMaintenance = (function () {
    function ScheduledMaintenance() {
    }
    Object.defineProperty(ScheduledMaintenance.prototype, "isScheduledMaintenanceBannerVisible", {
        get: function () {
            var nextScheduledMaintenance = this.getNextScheduledMaintenance();
            if (!nextScheduledMaintenance) {
                return false;
            }
            var item = localStorage.getItem(SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name);
            return !item;
        },
        enumerable: false,
        configurable: true
    });
    ScheduledMaintenance.prototype.dismissBanner = function () {
        var nextScheduledMaintenance = this.getNextScheduledMaintenance();
        if (!nextScheduledMaintenance) {
            return;
        }
        localStorage.setItem(SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name, new Date().toUTCString());
    };
    ScheduledMaintenance.prototype.showBanner = function () {
        var nextScheduledMaintenance = this.getNextScheduledMaintenance();
        if (!nextScheduledMaintenance) {
            return;
        }
        localStorage.removeItem(SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name);
    };
    ScheduledMaintenance.prototype.getNextScheduledMaintenance = function () {
        return scheduledMaintenanceList
            .filter(function (m) { return isScheduledMaintenanceInNextSevenDays(m) || isScheduledMaintenanceAtTheMoment(m); })
            .sort(function (m1, m2) { return m1.startDate.valueOf() - m2.startDate.valueOf(); })[0];
    };
    return ScheduledMaintenance;
}());
function isScheduledMaintenanceAtTheMoment(m) {
    return (m.startDate.valueOf() <= new Date().valueOf() && m.endDate.valueOf() > new Date().valueOf());
}
function isScheduledMaintenanceInNextSevenDays(m) {
    var millisecondsToStartDate = m.startDate.valueOf() - new Date().valueOf();
    return millisecondsToStartDate > 0 && millisecondsToStartDate < SEVEN_DAYS_IN_MILLISECONDS;
}
angular.module('app.general').service('ScheduledMaintenance', ScheduledMaintenance);