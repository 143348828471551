var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var EventStrengthFilter = (function () {
    function EventStrengthFilter() {
        this.strengthOptions = {
            all: [],
            es: [],
            pp: [],
            pk: [],
            en: [],
            ea: [],
        };
    }
    EventStrengthFilter.prototype.changeStrengthFilter = function (type, strengthOption) {
        var newFilterValue = {
            type: type,
            strength: strengthOption
                ? this.filterValue.strength.indexOf(strengthOption) > -1
                    ? this.filterValue.strength.filter(function (s) { return s !== strengthOption; })
                    : __spreadArray(__spreadArray([], this.filterValue.strength, true), [strengthOption], false)
                : [],
        };
        this.onChange({ $filterValue: newFilterValue });
    };
    EventStrengthFilter.prototype.getStrengthFilterTitle = function () {
        var _this = this;
        return (this.strengthOptions[this.filterValue.type]
            .filter(function (strengthOption) { return _this.filterValue.strength.indexOf(strengthOption) > -1; })
            .join(', ') || 'All');
    };
    EventStrengthFilter.prototype.isStrengthSelected = function (option) {
        return !!this.filterValue.strength.find(function (strengthOption) { return strengthOption === option; });
    };
    return EventStrengthFilter;
}());
angular.module('app.general').component('eventStrengthFilter', {
    templateUrl: 'general/components/event-strength-filter.html',
    controller: EventStrengthFilter,
    bindings: {
        strengthOptions: '<',
        filterValue: '<',
        onChange: '&',
    },
});