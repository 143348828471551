(function () {
  'use strict';

  class AdminLoginController {
    private $userService;
    private $element;
    private $timeout;
    private modalInstance;

    private loginPromise;
    private username;
    private password;
    private message;

    constructor($q, $element, $timeout, UserService) {
      this.$userService = UserService;
      this.$element = $element;
      this.$timeout = $timeout;
      this.loginPromise = $q.resolve();
    }

    $postLink() {
      this.$timeout(() => {
        this.$element.find('input').first().focus();
      }, 200);
    }

    login() {
      this.message = '';

      if (!this.password) {
        return;
      }

      this.loginPromise = this.$userService
        .adminLogin(this.password)
        .then(() => this.modalInstance.close())
        .catch(() => (this.message = 'login_failed'));
    }

    cancel() {
      this.modalInstance.dismiss('cancel');
    }
  }

  angular.module('app.general').component('adminLoginModal', {
    templateUrl: 'general/components/admin-login-modal.html',
    controller: AdminLoginController,
    bindings: {
      modalInstance: '<',
    },
  });
})();
