angular.module('app.general').filter('scStripComma', scStripCommaFilter);

function scStripCommaFilter() {
  return function (input = '') {
    return input
      .split(',')
      .filter((v) => v !== ' ')
      .join(',');
  };
}
