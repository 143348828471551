(function () {
  'use strict';

  angular.module('app.general').service('MyLazyLoad', MyLazyLoad);

  /**
   * We used to modify $ocLazyLoad internal in app/js/config/app.js
   * to support appending the BUILD number to the end of js file.
   *
   * Something like this:
   *
    // Cache busting
    var orignalBuildElement = $delegate.buildElement;
    $delegate.buildElement = function (type, path, params) {
        path = path.replace(/\.js$/, '.' + BUILD + '.js');

        return orignalBuildElement(type, path, params);
    };
   *
   *
   * However, that will breaks the internal caching mechanism of $ocLazyLoad.
   * Which resulted in the module will be loaded multiple time and the script tag
   * will be append to the HTML body indefinitely.
   *
   * This MyLazyLoad service is a wrapper around $ocLazyLoad, so that we can perform
   * the append of BUILD number externally, then call the original $ocLazyLoad.load method.
   *
   * No need to touch the internals, $ocLazyLoad will be happy again.
   */
  function MyLazyLoad(BUILD) {
    return {
      load: function ($ocLazyLoad, modules) {
        if (angular.isArray(modules)) {
          return $ocLazyLoad.load(
            modules.map(function (path) {
              return path.replace(/\.js$/, '.' + BUILD + '.js');
            })
          );
        }

        if (angular.isString(modules)) {
          return $ocLazyLoad.load(modules.replace(/\.js$/, '.' + BUILD + '.js'));
        }

        throw new Error('lazyload: modules must be either string or array');
      },
    };
  }
})();
