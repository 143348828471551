var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var Action;
(function (Action) {
    Action["Accept"] = "ACCEPT";
    Action["Reject"] = "REJECT";
    Action["Undecided"] = "UNDECIDED";
})(Action || (Action = {}));
var Kind;
(function (Kind) {
    Kind["Conflict"] = "CONFLICT";
    Kind["Confirmed"] = "CONFIRMED";
    Kind["Unconfirmed"] = "UNCONFIRMED";
    Kind["Placeholder"] = "PLACEHOLDER";
})(Kind || (Kind = {}));
var PalyerRepresentationChangesModalController = (function () {
    function PalyerRepresentationChangesModalController(RepresentationService) {
        this.$representationService = RepresentationService;
    }
    PalyerRepresentationChangesModalController.prototype.$onInit = function () {
        var _this = this;
        var playerIds = __spreadArray(__spreadArray([], this.unconfirmed.map(function (item) { return item.player._id; }), true), this.conflicts.map(function (item) { return item.player._id; }), true);
        this.promise = this.$representationService.queryPlayerRerepresentation(playerIds.find(function (item) { return !!item; })).$promise;
        this.promise.then(function (representations) {
            _this.confirmed = representations.filter(function (item) { return !!item.confirmed; });
            _this.recalculateChoices();
        });
    };
    PalyerRepresentationChangesModalController.prototype.cancel = function () {
        this.$uibModal.dismiss();
    };
    PalyerRepresentationChangesModalController.prototype.confirm = function () {
        var _this = this;
        this.promise = this.$representationService.applyChangesBulk(this.collectChanges());
        this.promise.then(function () { return _this.$uibModal.close(); });
    };
    PalyerRepresentationChangesModalController.prototype.toggle = function (choice, option) {
        switch (option.action) {
            case Action.Undecided:
            case Action.Reject:
                this.enable(choice, option);
                choice.options
                    .filter(function (item) { return item !== option; })
                    .forEach(function (option) { return (option.action = Action.Reject); });
                break;
        }
        this.isChanged = this.collectChanges().length > 0;
    };
    PalyerRepresentationChangesModalController.prototype.enable = function (choice, option) {
        option.action = Action.Accept;
        if (option.kind === Kind.Placeholder) {
            return;
        }
        if (option.kind === Kind.Conflict) {
            this.findNestedRelatedChoices(choice).forEach(function (choice) {
                for (var _i = 0, _a = choice.options; _i < _a.length; _i++) {
                    var option_1 = _a[_i];
                    switch (option_1.kind) {
                        case Kind.Confirmed:
                        case Kind.Unconfirmed:
                        case Kind.Conflict:
                            option_1.action = Action.Reject;
                            break;
                        case Kind.Placeholder:
                            option_1.action = Action.Accept;
                            break;
                    }
                }
            });
        }
        this.findParentRelatedChoices(choice).forEach(function (choice) {
            if (choice.options.some(function (item) { return item.kind === Kind.Conflict && item.action === Action.Accept; })) {
                for (var _i = 0, _a = choice.options; _i < _a.length; _i++) {
                    var option_2 = _a[_i];
                    option_2.action = Action.Undecided;
                }
            }
        });
    };
    PalyerRepresentationChangesModalController.prototype.findNestedRelatedChoices = function (choice) {
        return this.choices
            .filter(function (item) { return item !== choice; })
            .filter(function (item) { return _.isMatch(item.filter, choice.filter); });
    };
    PalyerRepresentationChangesModalController.prototype.findParentRelatedChoices = function (choice) {
        return this.choices
            .filter(function (item) { return item !== choice; })
            .filter(function (item) {
            return !item.filter.region ||
                (item.filter.region === choice.filter.region && !item.filter.country);
        });
    };
    PalyerRepresentationChangesModalController.prototype.recalculateChoices = function () {
        this.choices = [];
        var affectedRegions = __spreadArray(__spreadArray([], this.unconfirmed.map(function (item) { return _.pick(item, ['region', 'country']); }), true), this.conflicts.map(function (item) { return item.filter; }), true);
        var _loop_1 = function (region) {
            var options = [];
            this_1.conflicts.filter(isConflictInRegionStrict(region)).forEach(function (conflict) {
                options.push({
                    kind: Kind.Conflict,
                    action: Action.Undecided,
                    conflict: conflict,
                });
            });
            this_1.unconfirmed.filter(isRepresentationInRegionStrict(region)).forEach(function (representation) {
                options.push({
                    kind: Kind.Unconfirmed,
                    action: Action.Undecided,
                    representations: [representation],
                });
            });
            if (this_1.confirmed.filter(isRepresentationInRegionRelaxed(region)).length) {
                options.push({
                    kind: Kind.Confirmed,
                    action: Action.Undecided,
                    representations: this_1.confirmed.filter(isRepresentationInRegionRelaxed(region)),
                });
            }
            if (options.length === 1 && _.has(options[0], 'representations.0.type') === false) {
                options.push({
                    kind: Kind.Placeholder,
                    action: Action.Undecided,
                });
            }
            this_1.choices.push({
                filter: region,
                options: options,
            });
        };
        var this_1 = this;
        for (var _i = 0, _a = _.uniqWith(affectedRegions, _.isEqual); _i < _a.length; _i++) {
            var region = _a[_i];
            _loop_1(region);
        }
        this.choices = _.sortBy(this.choices, function (item) { return "".concat(item.filter.region || '', ":").concat(item.filter.country || ''); });
    };
    PalyerRepresentationChangesModalController.prototype.collectChanges = function () {
        var options = _.flatten(this.choices.map(function (item) { return item.options; }));
        var changes = options.map(function (option) {
            if (option.action === Action.Accept) {
                switch (option.kind) {
                    case Kind.Conflict:
                        return [
                            {
                                kind: 'conflict-accept',
                                conflict: { _id: option.conflict._id },
                            },
                        ];
                    case Kind.Unconfirmed:
                        return [
                            {
                                kind: 'confirm',
                                representation: { _id: option.representations[0]._id },
                            },
                        ];
                    case Kind.Confirmed:
                    case Kind.Placeholder:
                        return null;
                }
            }
            if (option.action === Action.Reject) {
                switch (option.kind) {
                    case Kind.Conflict:
                        return [
                            {
                                kind: 'conflict-reject',
                                conflict: { _id: option.conflict._id },
                            },
                        ];
                    case Kind.Unconfirmed:
                    case Kind.Confirmed:
                        return option.representations.map(function (item) { return ({
                            kind: 'remove',
                            representation: { _id: item._id },
                        }); });
                    case Kind.Placeholder:
                        return null;
                }
            }
        });
        return _.flatten(changes).filter(function (item) { return !!item; });
    };
    return PalyerRepresentationChangesModalController;
}());
function isConflictInRegionStrict(region) {
    return function (conflict) {
        return conflict.filter.region === region.region && conflict.filter.country === region.country;
    };
}
function isRepresentationInRegionStrict(region) {
    return function (representation) {
        return representation.region === region.region && representation.country === region.country;
    };
}
function isRepresentationInRegionRelaxed(region) {
    return function (representation) {
        return _.isMatch(representation, region);
    };
}
angular.module('app.organisation').component('modalPlayerRepresentationChanges', {
    templateUrl: 'general/modals/player-representation-changes.html',
    controller: PalyerRepresentationChangesModalController,
    bindings: {
        $uibModal: '<modalInstance',
        unconfirmed: '<',
        conflicts: '<',
    },
});
;