(function () {
  'use strict';

  angular.module('app.general').directive('scDemoOverlay', scDemoOverlayDirective);

  function scDemoOverlayDirective() {
    return {
      restrict: 'A',
      templateUrl: 'general/views/scDemoOverlayDirectiveView.html',
      transclude: true,

      link: function ($scope, $element, $attr) {
        $attr.$addClass('demo-wrap');

        $scope.header = $attr.header || 'general.Mock.Get_in_touch';
        $scope.commingSoon = $attr.commingSoon !== 'false';
        $scope.body = $attr.body;
      },
    };
  }
})();
