(function () {
  angular.module('app.general').directive('imageOnload', imageOnloadDirective);

  function imageOnloadDirective() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('load', function () {
          scope.$apply(attrs.imageOnload);
        });
        element.bind('error', function () {
          scope.$apply(attrs.imageOnError);
        });
      },
    };
  }
})();
