'use strict';
var ScheduledMaintenanceBanner = (function () {
    function ScheduledMaintenanceBanner(ScheduledMaintenance) {
        this.ScheduledMaintenance = ScheduledMaintenance;
    }
    ScheduledMaintenanceBanner.prototype.$onInit = function () { };
    ScheduledMaintenanceBanner.prototype.dismissBanner = function () {
        this.ScheduledMaintenance.dismissBanner();
    };
    Object.defineProperty(ScheduledMaintenanceBanner.prototype, "nextScheduledMaintenance", {
        get: function () {
            return this.ScheduledMaintenance.getNextScheduledMaintenance();
        },
        enumerable: false,
        configurable: true
    });
    return ScheduledMaintenanceBanner;
}());
angular.module('app.general').component('scheduledMaintenanceBanner', {
    controller: ScheduledMaintenanceBanner,
    templateUrl: 'general/components/scheduled-maintenance-banner.html',
});