(function () {
  'use strict';

  angular.module('app.general').filter('scBmi', scBmiFilter);

  function scBmiFilter() {
    return function (profile) {
      if (!profile || !profile.weight || !profile.height) {
        return null;
      }

      return (profile.weight / Math.pow(profile.height / 100, 2)).toFixed(2);
    };
  }
})();
