'use strict';

const STORAGE_KEY = 'app:requirements:ignore-recommendation';

const BROWSER_REQUIREMENTS = {
  Chrome: {minimal: 55, recommended: 60},
  Firefox: {minimal: 50, recommended: 58},
  Edge: {minimal: 12, recommended: 60},
  Safari: {minimal: 10, recommended: null},
};

class RuntimeRequirements {
  private version: number;
  private ignoringRecommendations: boolean;
  private returnUrl: string;

  isVendorRecommended: boolean;
  isRecommended: boolean;
  isMinimal: boolean;
  isTablet: boolean;
  isMobile: boolean;

  constructor(private $location) {
    const uaParser = new window.UAParser();
    const browser = uaParser.getBrowser();
    const device = uaParser.getDevice();

    this.version = parseInt(browser.version);

    const recommendedRequirement = _.get(BROWSER_REQUIREMENTS, `${browser.name}.recommended`);
    const minimalRequirement = _.get(BROWSER_REQUIREMENTS, `${browser.name}.minimal`);

    this.isVendorRecommended = !!recommendedRequirement;
    this.isRecommended = !!recommendedRequirement && this.version >= recommendedRequirement;
    this.isMinimal = !!minimalRequirement && this.version >= minimalRequirement;
    this.isTablet = device.type === 'tablet';
    this.isMobile = device.type === 'mobile';

    this.ignoringRecommendations = localStorage.getItem(STORAGE_KEY) !== null;
  }

  get isRecommendationIgnored() {
    return this.ignoringRecommendations;
  }

  get minimalVersions() {
    return _.mapValues(BROWSER_REQUIREMENTS, (item) => item.minimal);
  }

  get recommendedVersions() {
    return _.mapValues(BROWSER_REQUIREMENTS, (item) => item.recommended);
  }

  setReturnUrl(url: string) {
    this.returnUrl = url;
  }

  ignoreRecommendation() {
    this.ignoringRecommendations = true;
    localStorage.setItem(STORAGE_KEY, 'true');
  }

  returnBack() {
    if (this.returnUrl) {
      this.$location.url(this.returnUrl);
      this.returnUrl = null;
    } else {
      this.$location.url('/');
    }
  }
}

angular.module('app.general').service('RuntimeRequirements', RuntimeRequirements);
