class MediaLinksController {
  private links;

  $onChanges(changesObj) {
    if (changesObj.entity && changesObj.entity.currentValue) {
      const entity = changesObj.entity.currentValue;

      this.links = _.flatten(
        Object.keys(entity.links || {}).map((key) => {
          const url = entity.links[key] as null | string | string[];

          if (!url) {
            return null;
          }

          if (typeof url === 'string') {
            return {
              type: key,
              url,
            };
          }

          if (Array.isArray(url)) {
            return url.map((item) => ({
              type: key,
              url: item,
            }));
          }

          return [];
        }),
      ).filter((link) => {
        return link && link.url && link.url.startsWith('http');
      });
    }
  }
}

angular.module('app.general').component('mediaLinks', {
  templateUrl: 'general/components/media-links.html',
  controller: MediaLinksController as any,
  bindings: {
    entity: '<',
  },
});
