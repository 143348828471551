var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var FaceoffsMapController = (function () {
    function FaceoffsMapController(GameUtils, GameService) {
        this.GameUtils = GameUtils;
        this.GameService = GameService;
        this.ZONES = [
            {
                key: 'dz',
                text: 'game.Defending_zone',
            },
            {
                key: 'nz',
                text: 'game.Neutral_zone',
            },
            {
                key: 'oz',
                text: 'game.Attacking_zone',
            },
        ];
        this.ZONE_SPOTS = {
            dz: [1, 5],
            nz: [0, 2, 3, 6, 7],
            oz: [4, 8],
        };
    }
    FaceoffsMapController.prototype.$onChanges = function (changes) {
        if (changes.faceoffEvents && changes.faceoffEvents.currentValue) {
            this.calculateFaceoffSpots(changes.faceoffEvents.currentValue);
        }
    };
    FaceoffsMapController.prototype.calculateFaceoffSpots = function (faceoffEvents) {
        var _this = this;
        this.spots = this.GameUtils.faceoffSpots.map(function (spot) { return ({
            x: spot.x,
            y: spot.y,
            totalEvents: [],
            winEvents: [],
            loseEvents: [],
        }); });
        if (faceoffEvents) {
            faceoffEvents.forEach(function (event) {
                _this.spots[event.extra.spotTeamPerspective].totalEvents.push(event);
                if (event.extra.isWinner === true) {
                    _this.spots[event.extra.spotTeamPerspective].winEvents.push(event);
                }
                if (event.extra.isWinner === false) {
                    _this.spots[event.extra.spotTeamPerspective].loseEvents.push(event);
                }
            });
        }
    };
    FaceoffsMapController.prototype.selectSpot = function (spotIdx, value) {
        if ((value === 'totalEvents' && this.spots[spotIdx].totalEvents.length === 0) ||
            (value === 'winEvents' && this.spots[spotIdx].winEvents.length === 0) ||
            (value === 'loseEvents' && this.spots[spotIdx].loseEvents.length === 0)) {
            return;
        }
        this.onSpotSelected({
            $spotIdx: spotIdx,
            $value: value === this.selectedSpots[spotIdx] ? '' : value,
        });
    };
    FaceoffsMapController.prototype.selectZone = function (zoneKey) {
        var _this = this;
        var zoneSpots = this.ZONE_SPOTS[zoneKey];
        var newVal = this.selectedSpots
            .filter(function (_, i) { return zoneSpots.indexOf(i) > -1; })
            .some(function (s) { return s !== 'totalEvents'; })
            ? 'totalEvents'
            : '';
        this.selectedSpots.forEach(function (_, i) {
            _this.onSpotSelected({
                $spotIdx: i,
                $value: zoneSpots.indexOf(i) > -1 ? newVal : '',
            });
        });
    };
    FaceoffsMapController.prototype.isZoneSelected = function (zoneKey) {
        var zoneSpots = this.ZONE_SPOTS[zoneKey];
        var isZoneSpotsSelected = this.selectedSpots
            .filter(function (_, i) { return zoneSpots.indexOf(i) > -1; })
            .every(function (s) { return s === 'totalEvents'; });
        var isOtherSpotsNotSelected = this.selectedSpots
            .filter(function (_, i) { return zoneSpots.indexOf(i) === -1; })
            .every(function (s) { return s === ''; });
        return isZoneSpotsSelected && isOtherSpotsNotSelected;
    };
    FaceoffsMapController.prototype.getEventsInZone = function (zone) {
        var _this = this;
        if (!this.spots || !this.ZONE_SPOTS) {
            return [];
        }
        var totalEvents = this.spots.reduce(function (currentValue, acc, spotIdx) {
            return _this.ZONE_SPOTS[zone].includes(spotIdx)
                ? __spreadArray(__spreadArray([], currentValue, true), acc.totalEvents, true) : currentValue;
        }, []);
        return {
            totalEvents: totalEvents,
            winEvents: totalEvents.filter(function (evt) { return evt.extra.isWinner; }),
            loseEvents: totalEvents.filter(function (evt) { return !evt.extra.isWinner; }),
        };
    };
    return FaceoffsMapController;
}());
angular.module('app.general').component('faceoffsMap', {
    templateUrl: 'general/components/faceoffs-map.html',
    controller: FaceoffsMapController,
    bindings: {
        faceoffEvents: '<',
        selectedSpots: '<',
        onSpotSelected: '&',
    },
});