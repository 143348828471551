(function () {
  'use strict';

  function AccountTitleController() {
    this.transformRoleName = function (role) {
      return role.display;
    };

    this.hasAccountFunctions = function () {
      return (
        this.account &&
        this.account.functions &&
        this.account.functions.filter((item) => !!item).length > 0
      );
    };
  }

  angular.module('app.general').component('scAccountTitle', {
    templateUrl: 'general/components/sc-account-title.html',
    controller: AccountTitleController,
    bindings: {account: '<'},
  });
})();
