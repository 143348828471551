class ModalDataRequestController {
  private modalInstance;
  private gameStat;
  private player;
  private activeRequests;
  private activePersonalRequests;
  private $initPromise;

  constructor(
    private UserRequestService,
    private UserService,
  ) {}

  $onInit() {
    this.$initPromise = this.UserRequestService.getActiveRequests().then((data) => {
      this.activeRequests = data;
      this.activePersonalRequests = data.filter(
        (req) => req.requesters._id === this.UserService.getUser()._id,
      );
    });
  }
  close() {
    this.modalInstance.dismiss('cancel');
  }

  getColorClass(requests) {
    if (!requests) {
      return 'text-green';
    }
    if (requests.length <= 3) {
      return 'text-green';
    }
    if (requests.length <= 5) {
      return 'text-orange';
    }
    return 'text-red';
  }

  confirm() {
    this.modalInstance.close();
  }
}

angular.module('app.general').component('modalDataRequest', {
  templateUrl: 'general/modals/data-request.html',
  controller: ModalDataRequestController as any,
  bindings: {
    modalInstance: '<',
    gameStat: '<',
    player: '<',
  },
});
