class UserProfileTitle {
  private userId;

  private user;
  private $promise;

  constructor(
    private $q,
    private CommunicationMemberCache,
    private _,
  ) {}

  $onInit() {
    this.$promise = this.CommunicationMemberCache.getMember('user', this.userId).then((user) => {
      const roles = (user.roles || [])
        .filter((role) => role && !!role.id)
        .map((role) => {
          if (role.functions && role.functions.length) {
            role.functionsDisplay = role.functions.join(', ');
          } else {
            role.functionsDisplay = role.roles.map((r) => r.display).join(', ');
          }
          return role;
        });

      this.user = {
        ...user,
        displayName: user.profile
          ? `${user.profile.firstName} ${user.profile.lastName}`
          : 'Name not available',
        displayTitle: roles
          .map((role) => `${role.functionsDisplay} <${role.resourceName}>`)
          .join(', '),
      };
    });
  }
}

angular.module('app.communication').component('userProfileTitle', {
  templateUrl: 'general/components/user-profile-title.html',
  controller: UserProfileTitle as any,
  bindings: {
    userId: '<',
  },
});
