(function () {
  'use strict';

  angular.module('app.general').filter('domain', domainFilter);

  function domainFilter() {
    return function (url) {
      if (!url) {
        return '';
      }

      var parser = document.createElement('a');
      parser.href = url;

      return parser.hostname.replace('www.', '');
    };
  }
})();
