var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var types = ['shot', 'missedShot', 'blockedShot', 'goal'];
var ShootingMapController = (function () {
    function ShootingMapController(ENV, $filter, $uibModal, GameService, GameUtils) {
        this.ENV = ENV;
        this.$filter = $filter;
        this.$uibModal = $uibModal;
        this.GameService = GameService;
        this.GameUtils = GameUtils;
        this.spotsType = 'field';
        this.selections = { all: true };
        this.onlyPlayableEvents = false;
        for (var _i = 0, types_1 = types; _i < types_1.length; _i++) {
            var type = types_1[_i];
            this.selections[type] = false;
        }
        this.origin = { x: 180, y: 48 };
        this.ratio = 360 / 300;
        this.isZoneEnabled = false;
        this.zones = [
            {
                id: 'z1',
                cx: 180,
                cy: 48,
                r: 160,
                style: { fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)' },
                textPos: { x: 180, y: 160 },
                priority: 1,
            },
            {
                id: 'z2',
                cx: 0,
                cy: 100,
                r: 150,
                style: { fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)' },
                textPos: { x: 80, y: 130 },
                priority: 2,
            },
            {
                id: 'z3',
                cx: 360,
                cy: 100,
                r: 150,
                style: { fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)' },
                textPos: { x: 260, y: 130 },
                priority: 3,
            },
            {
                id: 'z4',
                cx: 180,
                cy: 48,
                r: 60,
                style: { fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)' },
                textPos: { x: 180, y: 70 },
                priority: 4,
            },
        ];
        this.zoneStats = {};
        var gsW = 400 / 3;
        var gsH = 265 / 3;
        this.goalSections = [
            { id: 'g0', section: 2, x: 2 * gsW, y: 0, w: gsW, h: gsH },
            { id: 'g1', section: 1, x: gsW, y: 0, w: gsW, h: gsH },
            { id: 'g2', section: 0, x: 0, y: 0, w: gsW, h: gsH },
            { id: 'g3', section: 5, x: 2 * gsW, y: gsH, w: gsW, h: gsH },
            { id: 'g4', section: 4, x: gsW, y: gsH, w: gsW, h: gsH },
            { id: 'g5', section: 3, x: 0, y: gsH, w: gsW, h: gsH },
            { id: 'g6', section: 8, x: 2 * gsW, y: 2 * gsH, w: gsW, h: gsH },
            { id: 'g7', section: 7, x: gsW, y: 2 * gsH, w: gsW, h: gsH },
            { id: 'g8', section: 6, x: 0, y: 2 * gsH, w: gsW, h: gsH },
        ];
        this.goalSectionStats = {};
    }
    ShootingMapController.prototype.$onChanges = function (changes) {
        if (changes && changes.gameEvents && changes.gameEvents.currentValue) {
            this.transformedGameEvents = this.transform(changes.gameEvents.currentValue);
            this.classify(this.transformedGameEvents);
            this.generateZoneStats(this.allVisibleEvents);
            this.generateGoalSectionStats(this.allVisibleEvents);
        }
    };
    ShootingMapController.prototype.onSpotsTypeChange = function () {
        if (this.spotsType === 'goal') {
            this.selections.all = false;
            for (var _i = 0, types_2 = types; _i < types_2.length; _i++) {
                var type = types_2[_i];
                this.selections[type] = false;
            }
            this.selections.shot = true;
            this.selections.goal = true;
            this.onSelectionsChange('shot');
            this.onSelectionsChange('goal');
        }
        else {
            this.selections.all = true;
            for (var _a = 0, types_3 = types; _a < types_3.length; _a++) {
                var type = types_3[_a];
                this.selections[type] = false;
            }
            this.onSelectionsChange('all');
        }
    };
    ShootingMapController.prototype.onSelectionsChange = function (type) {
        var _this = this;
        var anyTypeChecked = types.some(function (t) { return !!_this.selections[t]; });
        if (type === 'all' && this.selections[type]) {
            for (var _i = 0, types_4 = types; _i < types_4.length; _i++) {
                var t = types_4[_i];
                this.selections[t] = false;
            }
        }
        else if (anyTypeChecked) {
            this.selections.all = false;
        }
        else {
            this.selections.all = true;
        }
        this.generateZoneStats(this.allVisibleEvents);
        this.generateGoalSectionStats(this.allVisibleEvents);
    };
    ShootingMapController.prototype.isVisible = function (type) {
        return this.selections.all || this.selections[type];
    };
    ShootingMapController.prototype.tooltipText = function (evt) {
        var gameTime = this.$filter('secondsToTime')(evt.inGameTime);
        var game = evt.game;
        var gameTitle = game ? "".concat(game.home.scName, "-").concat(game.away.scName, "-").concat(game.date) : '';
        if (evt.player) {
            var playerName = "(#".concat(evt.player.jerseyNumber, ") ").concat(evt.player.scName || evt.player.firstName + ' ' + evt.player.lastName);
            return "".concat(gameTime, " // P").concat(evt.periodNumber, " // ").concat(gameTime, " // ").concat(playerName, " // (").concat(Math.round(evt.x), ", ").concat(Math.round(evt.y), ")");
        }
        return "".concat(gameTime, " // P").concat(evt.periodNumber, " // ").concat(gameTitle, " // ").concat(evt.type, " // (").concat(Math.round(evt.x), ", ").concat(Math.round(evt.y), ")");
    };
    ShootingMapController.prototype.transform = function (gameEvents) {
        var _this = this;
        var transformed = gameEvents
            .filter(function (evt) {
            return (!Number.isNaN(evt.scLocationX) && !Number.isNaN(evt.scLocationY)) ||
                (evt.extra &&
                    !Number.isNaN(parseFloat(evt.extra.locationX)) &&
                    !Number.isNaN(parseFloat(evt.extra.locationY)));
        })
            .map(function (evt) {
            var locX = parseFloat(evt.extra.locationX);
            var locY = parseFloat(evt.extra.locationY);
            var computed = evt.computed, scLocationX = evt.scLocationX, scLocationY = evt.scLocationY;
            if (computed && computed.perspectiveX && computed.perspectiveY) {
                var rawX = computed.perspectiveX;
                var rawY = computed.perspectiveY;
                var ratio = 360 / 640;
                var e = __assign(__assign({}, evt), { x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio, y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio });
                return e;
            }
            if (scLocationX && scLocationY) {
                var rawX = scLocationX;
                var rawY = scLocationY;
                var ratio = 360 / 640;
                var e = __assign(__assign({}, evt), { x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio, y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio });
                return e;
            }
            if (evt.dataSource === 'shl' || evt.dataSource === 'allsvenskan-statnet') {
                return __assign(__assign({}, evt), { x: _this.origin.x + locY * _this.ratio, y: _this.origin.y + locX * _this.ratio });
            }
            if (evt.dataSource.includes('del-official') ||
                evt.dataSource.includes('chl-official') ||
                evt.dataSource.includes('ebel-official') ||
                evt.dataSource.includes('tipsportextraliga-official') ||
                evt.dataSource.includes('magentasportcup-official') ||
                evt.dataSource.includes('eihl-official')) {
                return __assign(__assign({}, evt), { x: locX >= 0 ? 180 - locY * (360 / 200) : 180 + locY * (360 / 200), y: 360 - Math.abs(locX * (360 / 100)) });
            }
            if (evt.dataSource.indexOf('iceberg') > -1) {
                return __assign(__assign({}, evt), { x: (locX / Math.abs(locX)) * locY * (360 / 3000) + 1500 * (360 / 3000), y: (3000 - Math.abs(locX)) * (360 / 3000) });
            }
            if (evt.dataSource.indexOf('liiga-official') > -1 ||
                evt.dataSource.indexOf('mestis-official') > -1 ||
                evt.dataSource.indexOf('nuorten-sm-liiga-official') > -1) {
                return __assign(__assign({}, evt), { x: (locX <= 500 ? 500 - locY : locY) * (360 / 500), y: (locX <= 500 ? locX : 1000 - locX) * (360 / 500) });
            }
            if (evt.dataSource === 'nhl-official') {
                return __assign(__assign({}, evt), { x: locX <= 0 ? (locY + 42.5) * (360 / (42.5 * 2)) : (-locY + 42.5) * (360 / (42.5 * 2)), y: locX <= 0 ? (locX + 100) * (360 / 100) : (100 - locX) * (360 / 100) });
            }
            if (evt.dataSource === 'nla-official' || evt.dataSource === 'nlb-official') {
                return __assign(__assign({}, evt), { x: locY <= 30 ? locX * (360 / 27) : (27 - locX) * (360 / 27), y: locY <= 30 ? locY * (360 / 30) : (60 - locY) * (360 / 30) });
            }
            if (evt.dataSource.indexOf('stathletes') > -1) {
                var e = __assign(__assign({}, evt), { x: (locY * 360) / 85, y: ((200 - locX) * 360) / 100 + 10 });
                return e;
            }
            if (evt.dataSource === 'allsvenskan-impleo') {
                var rawX = locX * 1280;
                var rawY = locY * 640;
                var ratio = 360 / 640;
                var e = __assign(__assign({}, evt), { x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio, y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio });
                return e;
            }
            if (evt.dataSource === 'ahl-official') {
                var MAX_X = 580;
                var MAX_Y = 340;
                var MIDDLE_X = MAX_X / 2;
                var ratio = 360 / MAX_Y;
                return __assign(__assign({}, evt), { x: locX <= MIDDLE_X ? (MAX_Y - locY) * ratio : locY * ratio, y: locX <= MIDDLE_X ? locX * ratio : (MAX_X - locX) * ratio });
            }
            if (evt.dataSource.indexOf('-stellar') > -1) {
                var MAX_X = 60;
                var MAX_Y = 30;
                var xRatio = 360 / (MAX_X / 2);
                var yRatio = 360 / MAX_Y;
                var offsettedX = locX - 1;
                var offsettedY = locY + 1.7;
                var x = (MAX_Y - offsettedY) * yRatio;
                var y = (MAX_X - offsettedX) * xRatio;
                var e = __assign(__assign({}, evt), { x: x, y: y });
                return e;
            }
            if (evt.dataSource.indexOf('-lunar') > -1) {
                var MAX_X = 100;
                var MAX_Y = 50;
                var xRatio = 360 / MAX_X;
                var yRatio = 360 / (MAX_Y * 2);
                var x = (locY + MAX_Y) * yRatio;
                var y = (MAX_X - locX) * xRatio;
                var e = __assign(__assign({}, evt), { x: x, y: y });
                return e;
            }
            return evt;
        });
        return transformed;
    };
    ShootingMapController.prototype.classify = function (gameEvents) {
        var _this = this;
        var shotsOnGoal = gameEvents
            .filter(function (evt) { return (_this.onlyPlayableEvents ? !!evt.videoUrl : true); })
            .filter(function (evt) { return _this.GameUtils.isShotOnGoal(evt); })
            .map(function (evt) { return (__assign(__assign({}, evt), { x: evt.x, y: evt.y, r: 4, color: evt.videoUrl ? 'green' : 'lightgreen', tooltip: "Shot On Goal: ".concat(_this.tooltipText(evt)) })); });
        var missedShots = gameEvents
            .filter(function (evt) { return (_this.onlyPlayableEvents ? !!evt.videoUrl : true); })
            .filter(function (evt) { return _this.GameUtils.isMissedShot(evt); })
            .map(function (evt) { return (__assign(__assign({}, evt), { x: evt.x, y: evt.y, r: 6, color: evt.videoUrl ? 'black' : 'darkgray', tooltip: "Missed Shot: ".concat(_this.tooltipText(evt)) })); });
        var blockedShots = gameEvents
            .filter(function (evt) { return (_this.onlyPlayableEvents ? !!evt.videoUrl : true); })
            .filter(function (evt) { return _this.GameUtils.isBlockedShot(evt); })
            .map(function (evt) { return (__assign(__assign({}, evt), { x: evt.x, y: evt.y, r: 6, color: evt.videoUrl ? 'darkorange' : 'orange', tooltip: "Blocked Shot: ".concat(_this.tooltipText(evt)) })); });
        var goals = gameEvents
            .filter(function (evt) { return (_this.onlyPlayableEvents ? !!evt.videoUrl : true); })
            .filter(function (evt) { return _this.GameUtils.isGoal(evt); })
            .map(function (evt) { return (__assign(__assign({}, evt), { x: evt.x, y: evt.y, r: 4, color: evt.videoUrl ? 'red' : 'lightcoral', tooltip: "Goal: ".concat(_this.tooltipText(evt)) })); });
        this.shotsOnGoal = shotsOnGoal;
        this.missedShots = missedShots;
        this.goals = goals;
        this.blockedShots = blockedShots;
    };
    ShootingMapController.prototype.generateZoneStats = function (gameEvents) {
        var _this = this;
        var prioritizedZones = _.orderBy(this.zones, ['priority'], ['desc']);
        var eventsByZoneId = {
            others: [],
        };
        gameEvents.forEach(function (evt) {
            var zoneFound = false;
            for (var _i = 0, prioritizedZones_1 = prioritizedZones; _i < prioritizedZones_1.length; _i++) {
                var zone = prioritizedZones_1[_i];
                var d = Math.hypot(evt.x - zone.cx, evt.y - zone.cy);
                if (d < zone.r) {
                    eventsByZoneId[zone.id] = eventsByZoneId[zone.id] || [];
                    eventsByZoneId[zone.id].push(evt);
                    zoneFound = true;
                    break;
                }
            }
            if (!zoneFound) {
                eventsByZoneId.others.push(evt);
            }
        });
        this.zoneStats = Object.keys(eventsByZoneId).reduce(function (acc, key) {
            var events = eventsByZoneId[key] || [];
            var allShots = events.length;
            var shotsOnGoal = events.filter(function (evt) { return _this.GameUtils.isGoal(evt) || _this.GameUtils.isShotOnGoal(evt); }).length;
            var saves = events.filter(function (evt) { return !_this.GameUtils.isGoal(evt); }).length;
            acc[key] = {
                events: events,
                allShots: allShots,
                shotsOnGoal: shotsOnGoal,
                saves: saves,
                percentage: allShots ? Number((shotsOnGoal / allShots) * 100).toFixed(1) : '',
                savePctg: allShots ? Number((saves / allShots) * 100).toFixed(1) : '',
            };
            return acc;
        }, {});
    };
    ShootingMapController.prototype.generateGoalSectionStats = function (gameEvents) {
        var _this = this;
        var eventsByGoalSection = _.groupBy(gameEvents.filter(function (evt) { return evt.type === 'goal' || evt.type === 'shot'; }), function (evt) { return evt.scGoalSection || evt.extra.goalSection; });
        this.goalSectionStats = this.goalSections.reduce(function (acc, section) {
            var events = eventsByGoalSection[section.section] || [];
            var allShots = events.length;
            var goals = events.filter(function (evt) { return _this.GameUtils.isGoal(evt); }).length;
            var saves = events.filter(function (evt) { return !_this.GameUtils.isGoal(evt); }).length;
            acc[section.id] = {
                events: events,
                allShots: allShots,
                goals: goals,
                saves: saves,
                percentage: allShots ? Number((goals / allShots) * 100).toFixed(1) : '',
                savePctg: allShots ? Number((saves / allShots) * 100).toFixed(1) : '',
            };
            return acc;
        }, {});
    };
    ShootingMapController.prototype.playEvents = function (events) {
        var title = events.length === 1 ? events[0].tooltip : "".concat(this.titleText, "'s Playlist");
        this.GameService.openVirtualClipViewer(title, events);
    };
    ShootingMapController.prototype.playAll = function () {
        this.playEvents(this.allVisibleEvents);
    };
    ShootingMapController.prototype.openHeatMap = function () {
        this.GameService.openHeatMap(this.allVisibleEvents);
    };
    ShootingMapController.prototype.playZone = function (id) {
        this.playEvents(this.zoneStats[id].events);
    };
    ShootingMapController.prototype.playGoalSection = function (section) {
        if (this.goalSectionStats[section.id].events.length) {
            this.playEvents(this.goalSectionStats[section.id].events);
        }
    };
    Object.defineProperty(ShootingMapController.prototype, "allVisibleEvents", {
        get: function () {
            var allVisibleEvents = [];
            if (this.isVisible('shot')) {
                allVisibleEvents = allVisibleEvents.concat(this.shotsOnGoal || []);
            }
            if (this.isVisible('missedShot')) {
                allVisibleEvents = allVisibleEvents.concat(this.missedShots || []);
            }
            if (this.isVisible('goal')) {
                allVisibleEvents = allVisibleEvents.concat(this.goals || []);
            }
            if (this.isVisible('blockedShot')) {
                allVisibleEvents = allVisibleEvents.concat(this.blockedShots || []);
            }
            return allVisibleEvents;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ShootingMapController.prototype, "playableEvents", {
        get: function () {
            var allPlayableEvents = this.allVisibleEvents.filter(function (evt) { return evt.videoUrl; });
            return this.spotsType === 'goal'
                ? allPlayableEvents.filter(function (evt) { return evt.type === 'shot' || evt.type === 'goal'; })
                : allPlayableEvents;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ShootingMapController.prototype, "showGoalSectionDetailValue", {
        get: function () {
            return (this.spotsType === 'goal' &&
                ((this.selections.goal && this.selections.shot) ||
                    (!this.selections.goal && !this.selections.shot)));
        },
        enumerable: false,
        configurable: true
    });
    ShootingMapController.prototype.getGoalSectionDetailValue = function (gs) {
        if (this.isGoalieMap) {
            if ((this.selections.goal && this.selections.shot) ||
                (!this.selections.goal && !this.selections.shot)) {
                return (this.goalSectionStats[gs.id].savePctg || '0.0') + '%';
            }
            else if (this.selections.goal && !this.selections.shot) {
                return this.goalSectionStats[gs.id].goals;
            }
            else if (!this.selections.goal && this.selections.shot) {
                return this.goalSectionStats[gs.id].saves;
            }
            else {
                return '';
            }
        }
        else {
            if ((this.selections.goal && this.selections.shot) ||
                (!this.selections.goal && !this.selections.shot)) {
                return (this.goalSectionStats[gs.id].percentage || '0.0') + '%';
            }
            else if (this.selections.goal && !this.selections.shot) {
                return this.goalSectionStats[gs.id].goals;
            }
            else if (!this.selections.goal && this.selections.shot) {
                return this.goalSectionStats[gs.id].allShots;
            }
            else {
                return '';
            }
        }
    };
    Object.defineProperty(ShootingMapController.prototype, "showZoneDetailValue", {
        get: function () {
            return (this.isZoneEnabled &&
                this.spotsType === 'field' &&
                (this.selections.all ||
                    (this.selections.goal &&
                        this.selections.shot &&
                        this.selections.missedShot &&
                        this.selections.blockedShot)));
        },
        enumerable: false,
        configurable: true
    });
    ShootingMapController.prototype.getZoneDetailValue = function (zoneId) {
        var _a, _b, _c, _d;
        var isAllSelectionsSelected = this.selections.all ||
            (this.selections.goal &&
                this.selections.shot &&
                this.selections.missedShot &&
                this.selections.blockedShot);
        if (this.isGoalieMap) {
            return isAllSelectionsSelected
                ? (((_a = this.zoneStats[zoneId]) === null || _a === void 0 ? void 0 : _a.savePctg) || '0.0') + '%'
                : ((_b = this.zoneStats[zoneId]) === null || _b === void 0 ? void 0 : _b.allShots) || 0;
        }
        else {
            return isAllSelectionsSelected
                ? (((_c = this.zoneStats[zoneId]) === null || _c === void 0 ? void 0 : _c.percentage) || '0.0') + '%'
                : ((_d = this.zoneStats[zoneId]) === null || _d === void 0 ? void 0 : _d.allShots) || 0;
        }
    };
    return ShootingMapController;
}());
angular.module('app.general').component('shootingMap', {
    templateUrl: 'general/components/shooting-map.html',
    controller: ShootingMapController,
    bindings: {
        titleText: '<',
        gameEvents: '<',
        isGoalieMap: '<',
    },
});
;