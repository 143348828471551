import {GameEvent} from '@app/js/game/types';

const types = ['shot', 'missedShot', 'blockedShot', 'goal'];

class ShootingMapController {
  private range;
  private titleText;
  private gameEvents;
  private transformedGameEvents;

  private selections;
  private shotsOnGoal;
  private missedShots;
  private blockedShots;
  private goals;

  private onlyPlayableEvents;
  private spotsType;

  private origin;
  private ratio;
  private zones;
  private zoneStats;
  private zonesPriorities;
  private isZoneEnabled;

  private goalSections;
  private goalSectionStats;
  private isGoalieMap;

  constructor(
    private ENV,
    private $filter,
    private $uibModal,
    private GameService,
    private GameUtils,
  ) {
    this.spotsType = 'field';
    this.selections = {all: true};
    this.onlyPlayableEvents = false;
    for (const type of types) {
      this.selections[type] = false;
    }

    this.origin = {x: 180, y: 48};
    this.ratio = 360 / 300;
    this.isZoneEnabled = false;

    this.zones = [
      {
        id: 'z1',
        cx: 180,
        cy: 48,
        r: 160,
        style: {fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)'},
        textPos: {x: 180, y: 160},
        priority: 1,
      },
      {
        id: 'z2',
        cx: 0,
        cy: 100,
        r: 150,
        style: {fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)'},
        textPos: {x: 80, y: 130},
        priority: 2,
      },
      {
        id: 'z3',
        cx: 360,
        cy: 100,
        r: 150,
        style: {fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)'},
        textPos: {x: 260, y: 130},
        priority: 3,
      },
      {
        id: 'z4',
        cx: 180,
        cy: 48,
        r: 60,
        style: {fill: 'rgb(226, 244, 254)', stroke: 'rgb(85, 85, 85)'},
        textPos: {x: 180, y: 70},
        priority: 4,
      },
    ];

    this.zoneStats = {};

    const gsW = 400 / 3;
    const gsH = 265 / 3;
    this.goalSections = [
      {id: 'g0', section: 2, x: 2 * gsW, y: 0, w: gsW, h: gsH},
      {id: 'g1', section: 1, x: gsW, y: 0, w: gsW, h: gsH},
      {id: 'g2', section: 0, x: 0, y: 0, w: gsW, h: gsH},
      {id: 'g3', section: 5, x: 2 * gsW, y: gsH, w: gsW, h: gsH},
      {id: 'g4', section: 4, x: gsW, y: gsH, w: gsW, h: gsH},
      {id: 'g5', section: 3, x: 0, y: gsH, w: gsW, h: gsH},
      {id: 'g6', section: 8, x: 2 * gsW, y: 2 * gsH, w: gsW, h: gsH},
      {id: 'g7', section: 7, x: gsW, y: 2 * gsH, w: gsW, h: gsH},
      {id: 'g8', section: 6, x: 0, y: 2 * gsH, w: gsW, h: gsH},
    ];
    this.goalSectionStats = {};
  }

  public $onChanges(changes) {
    if (changes && changes.gameEvents && changes.gameEvents.currentValue) {
      this.transformedGameEvents = this.transform(changes.gameEvents.currentValue);
      this.classify(this.transformedGameEvents);
      this.generateZoneStats(this.allVisibleEvents);
      this.generateGoalSectionStats(this.allVisibleEvents);
    }
  }

  public onSpotsTypeChange() {
    if (this.spotsType === 'goal') {
      this.selections.all = false;
      for (const type of types) {
        this.selections[type] = false;
      }
      this.selections.shot = true;
      this.selections.goal = true;
      this.onSelectionsChange('shot');
      this.onSelectionsChange('goal');
    } else {
      this.selections.all = true;
      for (const type of types) {
        this.selections[type] = false;
      }
      this.onSelectionsChange('all');
    }
  }

  public onSelectionsChange(type) {
    const anyTypeChecked = types.some((t) => !!this.selections[t]);

    if (type === 'all' && this.selections[type]) {
      for (const t of types) {
        this.selections[t] = false;
      }
    } else if (anyTypeChecked) {
      this.selections.all = false;
    } else {
      this.selections.all = true;
    }

    this.generateZoneStats(this.allVisibleEvents);
    this.generateGoalSectionStats(this.allVisibleEvents);
  }

  public isVisible(type) {
    return this.selections.all || this.selections[type];
  }

  public tooltipText(evt: GameEvent & {x: number; y: number}) {
    const gameTime = this.$filter('secondsToTime')(evt.inGameTime);
    const game = evt.game;

    const gameTitle = game ? `${game.home.scName}-${game.away.scName}-${game.date}` : '';

    if (evt.player) {
      const playerName = `(#${evt.player.jerseyNumber}) ${
        evt.player.scName || evt.player.firstName + ' ' + evt.player.lastName
      }`;

      return `${gameTime} // P${evt.periodNumber} // ${gameTime} // ${playerName} // (${Math.round(
        evt.x,
      )}, ${Math.round(evt.y)})`;
    }

    return `${gameTime} // P${evt.periodNumber} // ${gameTitle} // ${evt.type} // (${Math.round(
      evt.x,
    )}, ${Math.round(evt.y)})`;
  }

  public transform(gameEvents) {
    const transformed = gameEvents
      .filter(
        (evt) =>
          (!Number.isNaN(evt.scLocationX) && !Number.isNaN(evt.scLocationY)) ||
          (evt.extra &&
            !Number.isNaN(parseFloat(evt.extra.locationX)) &&
            !Number.isNaN(parseFloat(evt.extra.locationY))),
      )
      .map((evt) => {
        const locX = parseFloat(evt.extra.locationX);
        const locY = parseFloat(evt.extra.locationY);

        const {computed, scLocationX, scLocationY} = evt;
        if (computed && computed.perspectiveX && computed.perspectiveY) {
          const rawX = computed.perspectiveX;
          const rawY = computed.perspectiveY;
          const ratio = 360 / 640;

          const e = {
            ...evt,
            x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio,
            y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio,
          };

          return e;
        }
        // conversions of our own coordinate via the new tagging tool
        if (scLocationX && scLocationY) {
          const rawX = scLocationX;
          const rawY = scLocationY;
          const ratio = 360 / 640;

          const e = {
            ...evt,
            x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio,
            y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio,
          };

          return e;
        }

        if (evt.dataSource === 'shl' || evt.dataSource === 'allsvenskan-statnet') {
          return {
            ...evt,
            x: this.origin.x + locY * this.ratio,
            y: this.origin.y + locX * this.ratio,
          };
        }

        if (
          evt.dataSource.includes('del-official') ||
          evt.dataSource.includes('chl-official') ||
          evt.dataSource.includes('ebel-official') ||
          evt.dataSource.includes('tipsportextraliga-official') ||
          evt.dataSource.includes('magentasportcup-official') ||
          evt.dataSource.includes('eihl-official')
        ) {
          return {
            ...evt,
            x: locX >= 0 ? 180 - locY * (360 / 200) : 180 + locY * (360 / 200),
            y: 360 - Math.abs(locX * (360 / 100)),
          };
        }

        // KHL Dimensions: 6000 x 3000
        //             (-1500)
        //
        // -3000       (0,0)       3000
        //
        //             (1500)
        //
        if (evt.dataSource.indexOf('iceberg') > -1) {
          return {
            ...evt,
            x: (locX / Math.abs(locX)) * locY * (360 / 3000) + 1500 * (360 / 3000),
            y: (3000 - Math.abs(locX)) * (360 / 3000),
          };
        }

        // Liiga Dimensions: 1000 x 500
        //             (500)
        //
        // 1000                    0
        //
        //             (0)
        //
        if (
          evt.dataSource.indexOf('liiga-official') > -1 ||
          evt.dataSource.indexOf('mestis-official') > -1 ||
          evt.dataSource.indexOf('nuorten-sm-liiga-official') > -1
        ) {
          return {
            ...evt,
            x: (locX <= 500 ? 500 - locY : locY) * (360 / 500),
            y: (locX <= 500 ? locX : 1000 - locX) * (360 / 500),
          };
        }

        if (evt.dataSource === 'nhl-official') {
          return {
            ...evt,
            x: locX <= 0 ? (locY + 42.5) * (360 / (42.5 * 2)) : (-locY + 42.5) * (360 / (42.5 * 2)),
            y: locX <= 0 ? (locX + 100) * (360 / 100) : (100 - locX) * (360 / 100),
          };
        }

        if (evt.dataSource === 'nla-official' || evt.dataSource === 'nlb-official') {
          return {
            ...evt,
            x: locY <= 30 ? locX * (360 / 27) : (27 - locX) * (360 / 27),
            y: locY <= 30 ? locY * (360 / 30) : (60 - locY) * (360 / 30),
          };
        }

        /**
         * For the rink x,y coordinates: the range is from (0 = x,0 = y) -> (200 = x ,85 = y).
         * The coordinates are not static as the origin (0,0) always occurs in a team’s defensive zone.
         * The (0,0) point occurs in the corner to the right of the net from a shooter's perspective.
         * The (200,85) point occurs in the offensive zone, to the right of the net from a shooter's
         * perspective.
         */
        if (evt.dataSource.indexOf('stathletes') > -1) {
          const e = {
            ...evt,
            x: (locY * 360) / 85,
            // 10 is the padding from top to the goal line
            // here we're just guessing the number, 10 seems to be ok
            // initial we're using 50 for padding, but that seems wrong
            y: ((200 - locX) * 360) / 100 + 10,
          };
          return e;
        }

        /**
         * -------------1280---------------------
         * |                                    |
         * |                                   640
         * |                                    |
         * (0,0)---------------------------------
         */
        if (evt.dataSource === 'allsvenskan-impleo') {
          const rawX = locX * 1280;
          const rawY = locY * 640;
          const ratio = 360 / 640;

          const e = {
            ...evt,
            x: rawX <= 640 ? (640 - rawY) * ratio : rawY * ratio,
            y: rawX <= 640 ? rawX * ratio : (1280 - rawX) * ratio,
          };
          return e;
        }

        /*
         * Home                                Away
         * -------------600----------------------
         * |                                    |
         * |                                    |
         * 300                                  |
         * |                                    |
         * |----------------------------------[0,0]
         *
         *
         * Away                                Home
         * [0, 0]-------------600---------------
         * |                                    |
         * |                                    |
         * 300                                  |
         * |                                    |
         * |-------------------------------------
         *
         * [0,0] is always in the AWAY team side
         */
        if (evt.dataSource === 'ahl-official') {
          const MAX_X = 580;
          const MAX_Y = 340;
          const MIDDLE_X = MAX_X / 2;
          const ratio = 360 / MAX_Y;

          return {
            ...evt,
            x: locX <= MIDDLE_X ? (MAX_Y - locY) * ratio : locY * ratio,
            y: locX <= MIDDLE_X ? locX * ratio : (MAX_X - locX) * ratio,
          };
        }

        /*
         * Similar to NorthAmerican dimension
         *
         * Team                                Opp
         * 25.9------------------------------------
         * |                                      |
         * |                                      |
         * |                                      |
         * |                                      |
         * 0------------------------------------60.96
         *
         */
        if (evt.dataSource.indexOf('-stellar') > -1) {
          const MAX_X = 60;
          const MAX_Y = 30;
          const xRatio = 360 / (MAX_X / 2);
          const yRatio = 360 / MAX_Y;

          // as MAX_X & MAX_Y is just approximate the exact coordinate
          // to make it align with our coorindate
          //
          // Some offsets have been chosen after many trials and error to account for those loss
          const offsettedX = locX - 1;
          const offsettedY = locY + 1.7;

          const x = (MAX_Y - offsettedY) * yRatio;
          const y = (MAX_X - offsettedX) * xRatio;

          const e = {...evt, x, y};
          return e;
        }

        /*
         * lunar
         *
         * DZ                                    .OZ
         * ----------------(-50)-------------------
         * |                                      |
         * |                                      |
         * -100            (0,0)                 100
         * |                                      |
         * |                                      |
         * -----------------(50)-------------------
         *
         *
         */
        if (evt.dataSource.indexOf('-lunar') > -1) {
          const MAX_X = 100;
          const MAX_Y = 50;
          const xRatio = 360 / MAX_X;
          const yRatio = 360 / (MAX_Y * 2);

          const x = (locY + MAX_Y) * yRatio;
          const y = (MAX_X - locX) * xRatio;

          const e = {...evt, x, y};
          return e;
        }

        return evt;
      });

    return transformed;
  }

  public classify(gameEvents) {
    const shotsOnGoal = gameEvents
      .filter((evt) => (this.onlyPlayableEvents ? !!evt.videoUrl : true))
      .filter((evt) => this.GameUtils.isShotOnGoal(evt))
      .map((evt) => ({
        ...evt,
        x: evt.x,
        y: evt.y,
        r: 4,
        color: evt.videoUrl ? 'green' : 'lightgreen',
        tooltip: `Shot On Goal: ${this.tooltipText(evt)}`,
      }));

    const missedShots = gameEvents
      .filter((evt) => (this.onlyPlayableEvents ? !!evt.videoUrl : true))
      .filter((evt) => this.GameUtils.isMissedShot(evt))
      .map((evt) => ({
        ...evt,
        x: evt.x,
        y: evt.y,
        r: 6,
        color: evt.videoUrl ? 'black' : 'darkgray',
        tooltip: `Missed Shot: ${this.tooltipText(evt)}`,
      }));

    const blockedShots = gameEvents
      .filter((evt) => (this.onlyPlayableEvents ? !!evt.videoUrl : true))
      .filter((evt) => this.GameUtils.isBlockedShot(evt))
      .map((evt) => ({
        ...evt,
        x: evt.x,
        y: evt.y,
        r: 6,
        color: evt.videoUrl ? 'darkorange' : 'orange',
        tooltip: `Blocked Shot: ${this.tooltipText(evt)}`,
      }));

    const goals = gameEvents
      .filter((evt) => (this.onlyPlayableEvents ? !!evt.videoUrl : true))
      .filter((evt) => this.GameUtils.isGoal(evt))
      .map((evt) => ({
        ...evt,
        x: evt.x,
        y: evt.y,
        r: 4,
        color: evt.videoUrl ? 'red' : 'lightcoral',
        tooltip: `Goal: ${this.tooltipText(evt)}`,
      }));

    this.shotsOnGoal = shotsOnGoal;
    this.missedShots = missedShots;
    this.goals = goals;
    this.blockedShots = blockedShots;
  }

  public generateZoneStats(gameEvents) {
    const prioritizedZones: any = _.orderBy(this.zones, ['priority'], ['desc']);
    const eventsByZoneId = {
      others: [],
    };

    gameEvents.forEach((evt) => {
      let zoneFound = false;
      for (const zone of prioritizedZones) {
        const d = Math.hypot(evt.x - zone.cx, evt.y - zone.cy);
        if (d < zone.r) {
          eventsByZoneId[zone.id] = eventsByZoneId[zone.id] || [];
          eventsByZoneId[zone.id].push(evt);
          zoneFound = true;
          break;
        }
      }

      if (!zoneFound) {
        eventsByZoneId.others.push(evt);
      }
    });

    this.zoneStats = Object.keys(eventsByZoneId).reduce((acc, key) => {
      const events = eventsByZoneId[key] || [];
      const allShots = events.length;
      const shotsOnGoal = events.filter(
        (evt) => this.GameUtils.isGoal(evt) || this.GameUtils.isShotOnGoal(evt),
      ).length;
      const saves = events.filter((evt) => !this.GameUtils.isGoal(evt)).length;

      acc[key] = {
        events,
        allShots,
        shotsOnGoal,
        saves,
        percentage: allShots ? Number((shotsOnGoal / allShots) * 100).toFixed(1) : '',
        savePctg: allShots ? Number((saves / allShots) * 100).toFixed(1) : '',
      };

      return acc;
    }, {});
  }

  public generateGoalSectionStats(gameEvents) {
    const eventsByGoalSection = _.groupBy(
      gameEvents.filter((evt) => evt.type === 'goal' || evt.type === 'shot'),
      (evt) => evt.scGoalSection || evt.extra.goalSection,
    );

    this.goalSectionStats = this.goalSections.reduce((acc, section) => {
      const events = eventsByGoalSection[section.section] || [];
      const allShots = events.length;
      const goals = events.filter((evt) => this.GameUtils.isGoal(evt)).length;
      const saves = events.filter((evt) => !this.GameUtils.isGoal(evt)).length;

      acc[section.id] = {
        events,
        allShots,
        goals,
        saves,
        percentage: allShots ? Number((goals / allShots) * 100).toFixed(1) : '',
        savePctg: allShots ? Number((saves / allShots) * 100).toFixed(1) : '',
      };

      return acc;
    }, {});
  }

  public playEvents(events) {
    const title = events.length === 1 ? events[0].tooltip : `${this.titleText}'s Playlist`;
    this.GameService.openVirtualClipViewer(title, events);
  }

  public playAll() {
    this.playEvents(this.allVisibleEvents);
  }

  public openHeatMap() {
    this.GameService.openHeatMap(this.allVisibleEvents);
  }

  public playZone(id) {
    this.playEvents(this.zoneStats[id].events);
  }

  public playGoalSection(section) {
    if (this.goalSectionStats[section.id].events.length) {
      this.playEvents(this.goalSectionStats[section.id].events);
    }
  }

  get allVisibleEvents() {
    let allVisibleEvents = [];

    if (this.isVisible('shot')) {
      allVisibleEvents = allVisibleEvents.concat(this.shotsOnGoal || []);
    }

    if (this.isVisible('missedShot')) {
      allVisibleEvents = allVisibleEvents.concat(this.missedShots || []);
    }

    if (this.isVisible('goal')) {
      allVisibleEvents = allVisibleEvents.concat(this.goals || []);
    }

    if (this.isVisible('blockedShot')) {
      allVisibleEvents = allVisibleEvents.concat(this.blockedShots || []);
    }

    return allVisibleEvents;
  }

  get playableEvents() {
    const allPlayableEvents = this.allVisibleEvents.filter((evt) => evt.videoUrl);
    return this.spotsType === 'goal'
      ? allPlayableEvents.filter((evt) => evt.type === 'shot' || evt.type === 'goal')
      : allPlayableEvents;
  }

  get showGoalSectionDetailValue() {
    // show goal section detail if both goal and shot are selected or both are not selected
    return (
      this.spotsType === 'goal' &&
      ((this.selections.goal && this.selections.shot) ||
        (!this.selections.goal && !this.selections.shot))
    );
  }

  getGoalSectionDetailValue(gs) {
    if (this.isGoalieMap) {
      if (
        (this.selections.goal && this.selections.shot) ||
        (!this.selections.goal && !this.selections.shot)
      ) {
        return (this.goalSectionStats[gs.id].savePctg || '0.0') + '%';
      } else if (this.selections.goal && !this.selections.shot) {
        return this.goalSectionStats[gs.id].goals;
      } else if (!this.selections.goal && this.selections.shot) {
        return this.goalSectionStats[gs.id].saves;
      } else {
        return '';
      }
    } else {
      if (
        (this.selections.goal && this.selections.shot) ||
        (!this.selections.goal && !this.selections.shot)
      ) {
        return (this.goalSectionStats[gs.id].percentage || '0.0') + '%';
      } else if (this.selections.goal && !this.selections.shot) {
        return this.goalSectionStats[gs.id].goals;
      } else if (!this.selections.goal && this.selections.shot) {
        return this.goalSectionStats[gs.id].allShots;
      } else {
        return '';
      }
    }
  }

  get showZoneDetailValue() {
    return (
      this.isZoneEnabled &&
      this.spotsType === 'field' &&
      (this.selections.all ||
        (this.selections.goal &&
          this.selections.shot &&
          this.selections.missedShot &&
          this.selections.blockedShot))
    );
  }

  getZoneDetailValue(zoneId) {
    const isAllSelectionsSelected =
      this.selections.all ||
      (this.selections.goal &&
        this.selections.shot &&
        this.selections.missedShot &&
        this.selections.blockedShot);
    if (this.isGoalieMap) {
      return isAllSelectionsSelected
        ? (this.zoneStats[zoneId]?.savePctg || '0.0') + '%'
        : this.zoneStats[zoneId]?.allShots || 0;
    } else {
      return isAllSelectionsSelected
        ? (this.zoneStats[zoneId]?.percentage || '0.0') + '%'
        : this.zoneStats[zoneId]?.allShots || 0;
    }
  }
}

angular.module('app.general').component('shootingMap', {
  templateUrl: 'general/components/shooting-map.html',
  controller: ShootingMapController,
  bindings: {
    titleText: '<',
    gameEvents: '<',
    isGoalieMap: '<',
  },
});
