class ConnectionStatus {
  private state;
  private callbacks;

  constructor(
    private $window,
    private $rootScope,
  ) {
    this.state = {
      isOnline: $window.navigator ? $window.navigator.onLine : true,
    };

    this.callbacks = [];

    if ($window.addEventListener) {
      $window.addEventListener(
        'online',
        () => {
          this.state.isOnline = true;
          this.callbacks.forEach((callback) => callback(this.state.isOnline));
          $rootScope.$digest();
        },
        true,
      );

      $window.addEventListener(
        'offline',
        () => {
          this.state.isOnline = false;
          this.callbacks.forEach((callback) => callback(this.state.isOnline));
          $rootScope.$digest();
        },
        true,
      );
    }
  }

  onChange(callback) {
    this.callbacks.push(callback);
  }
}

angular.module('app.general').service('ConnectionStatus', ConnectionStatus);
