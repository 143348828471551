var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var PlaylistGamesController = (function () {
    function PlaylistGamesController($q, GameService) {
        this.$q = $q;
        this.GameService = GameService;
    }
    PlaylistGamesController.prototype.$onInit = function () {
        var _this = this;
        if (this.list.game) {
            return;
        }
        var unifyGameId = function (game) {
            if (!game.home._id) {
                game.home._id = game.home.scId;
            }
            if (!game.away._id) {
                game.away._id = game.away.scId;
            }
            return game;
        };
        var gamesFromEvents = _.uniqBy((this.list.events || []).map(function (item) { return unifyGameId(item.game); }), function (item) { return "".concat(item.home._id, "-").concat(item.away._id, "-").concat(item.date); });
        if (!gamesFromEvents) {
            return;
        }
        this.$gamesPromise = this.$q
            .all(gamesFromEvents.map(function (game) {
            return _this.GameService.getGameDetail(game.home._id, game.away._id, game.date).then(function (response) { return (__assign({ key: "".concat(game.home._id, "-").concat(game.away._id, "-").concat(game.date) }, response.game)); });
        }))
            .then(function (games) {
            _this.games = games;
        });
    };
    return PlaylistGamesController;
}());
angular.module('app.general').component('playlistGames', {
    templateUrl: 'general/components/playlist-games.html',
    controller: PlaylistGamesController,
    bindings: {
        list: '<',
    },
});