var History = (function () {
    function History($state) {
        this.$state = $state;
        this.history = [];
    }
    History.prototype.add = function (state, params) {
        if (!this.isStateOverlay(state)) {
            this.history.unshift({ state: state, params: params });
        }
        this.history.splice(20);
    };
    History.prototype.goBack = function () {
        this.$state.go(this.lastState.state, this.lastState.params);
    };
    History.prototype.isStateOverlay = function (state) {
        return /^profile/.test(state.name) || /^settings/.test(state.name);
    };
    Object.defineProperty(History.prototype, "lastState", {
        get: function () {
            return this.history[0] || this.defaultState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(History.prototype, "defaultState", {
        get: function () {
            return { state: 'root.platform', params: {} };
        },
        enumerable: false,
        configurable: true
    });
    return History;
}());
angular.module('app.general').service('History', History);