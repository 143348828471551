function LastCallAsyncCaching() {
  const cache = (LastCallAsyncCaching.cache =
    LastCallAsyncCaching.cache || new Map<string, {state: string; value: any}>());

  return function (target, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function () {
      // eslint-disable-next-line prefer-rest-params
      const state = JSON.stringify(arguments);

      if (cache.has(propertyKey) && cache.get(propertyKey).state === state) {
        return (window as any).AngularPromise.resolve(cache.get(propertyKey).value);
      }

      // eslint-disable-next-line prefer-rest-params
      return original.apply(this, arguments).then((value) => {
        cache.set(propertyKey, {state, value});
        return value;
      });
    };
  };
}
