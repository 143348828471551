(function () {
  angular.module('app.general').factory('LinkifyIt', LinkifyIt);

  function LinkifyIt($window) {
    if (!$window.LinkifyIt) {
      throw new Error('Missing LinkifyIt library!');
    }

    return $window.LinkifyIt;
  }
})();
