(function () {
  'use strict';

  const LOCALSTORAGE_KEY = 'app:tabs';

  angular
    .module('app.general')
    .service('Tabs', TabsService)
    .service('TabsStorage', TabsStorageService);

  function TabsStorageService() {
    this.get = getDataFromLocalStorage;
    this.set = setDataToLocalStorage;

    function getDataFromLocalStorage() {
      let data;

      try {
        data = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
      } catch (e) {
        /* empty */
      }

      return data && data.constructor === Array ? data : [];
    }

    function setDataToLocalStorage(data) {
      data = JSON.stringify(data);
      localStorage.setItem(LOCALSTORAGE_KEY, data);
    }
  }

  function TabsService($state, $rootScope, $window, TabsStorage) {
    const tabs = TabsStorage.get();
    let activeTab = undefined,
      parentPageContext;

    initialize();

    this.goTo = function (stateName, params, title) {
      if (!stateName) {
        throw new Error('You should pass `stateName` param to `goTo` function');
      }
      if (!title) {
        throw new Error('You should pass `title` param to `goTo` function');
      }

      activeTab.state = stateName;
      activeTab.params = params;
      activeTab.title = title;

      navigateToActiveTab();
      TabsStorage.set(tabs);
    };

    this.newForegroundTab = function (stateName, params, title) {
      if (!stateName) {
        throw new Error('You should pass `stateName` param to `newForegroundTab` function');
      }
      if (!title) {
        throw new Error('You should pass `title` param to `newForegroundTab` function');
      }

      const tab = createTab(stateName, params, title);
      insertAfterActiveTab(tab);

      this.setActive(tab);
      TabsStorage.set(tabs);
    };

    this.newBackgroundTab = function (stateName, params, title) {
      if (!stateName) {
        throw new Error('You should pass `stateName` param to `newBackgroundTab` function');
      }
      if (!title) {
        throw new Error('You should pass `title` param to `newBackgroundTab` function');
      }

      insertAfterActiveTab(createTab(stateName, params, title));
      TabsStorage.set(tabs);
    };

    this.newForegroundTabWithHome = function () {
      this.newForegroundTab($rootScope.getDefaultStateForModule('home'), {}, 'Home');
    };

    this.goHome = function () {
      this.goTo($rootScope.getDefaultStateForModule('home'), {}, 'Home');
    };

    this.closeTab = function (tab) {
      if (tabs.length <= 1) {
        this.goHome();
        TabsStorage.set(tabs);
        return;
      }

      if (activeTab === tab) {
        const offset = _.last(tabs) === tab ? -1 : 1,
          newActiveTabIndex = _.findIndex(tabs, equalsByReference(activeTab)) + offset;

        activeTab = tabs[newActiveTabIndex];
        navigateToActiveTab();
      }

      _.remove(tabs, equalsByReference(tab));
      TabsStorage.set(tabs);
    };

    this.getTabs = function () {
      return tabs;
    };

    this.getActive = function () {
      return activeTab;
    };

    this.setActive = function (tab) {
      if (_.find(tabs, equalsByReference(tab))) {
        activeTab = tab;
        navigateToActiveTab();
      }
    };

    this.isActive = function (tab) {
      return tab === activeTab;
    };

    this.setActiveTabTitle = function (title) {
      activeTab.title = title;
      this.setWindowTitle(activeTab.title);

      TabsStorage.set(tabs);
    };

    this.registerGoBackState = function (options) {
      const titlePrefix = options.module
        ? $rootScope.getDefaultTitlePrefixForModule(options.module) + ' '
        : '';

      // TODO: share logic with `tab-switcher` directive
      parentPageContext = {
        state: options.state || $rootScope.getDefaultStateForModule(options.module),
        params: options.params,
        title: titlePrefix + options.title,
      };
    };

    this.goBack = function () {
      this.goTo(parentPageContext.state, parentPageContext.params, parentPageContext.title);
    };

    this.setWindowTitle = setWindowTitle;

    function initialize() {
      _.remove(tabs, function (tab) {
        return !$state.get(tab.state);
      });

      let currentStateName = $state.current.name,
        currentStateParams = $state.params;

      if (!isWhiteListed(currentStateName)) {
        currentStateName = $rootScope.getDefaultStateForModule('home');
        currentStateParams = {};
      }

      activeTab = findTab(currentStateName, currentStateParams);

      if (!activeTab) {
        activeTab = createTab(currentStateName, currentStateParams);
        tabs.unshift(activeTab);
      }

      setWindowTitle(activeTab.title);
      $rootScope.$on('$stateChangeSuccess', handleStateChange);
    }

    function handleStateChange() {
      const currentStateName = $state.current.name,
        currentStateParams = $state.params;

      if (!isWhiteListed(currentStateName)) {
        return;
      }

      const tab = findTab(currentStateName, currentStateParams);
      if (tab) {
        activeTab = tab;
      } else {
        activeTab.state = currentStateName;
        activeTab.params = currentStateParams;
        activeTab.title = undefined;
      }

      TabsStorage.set(tabs);
    }

    function findTab(stateName, params) {
      if (activeTab && matchTab(activeTab)) {
        return activeTab;
      }

      return _.find(tabs, matchTab);

      function matchTab(tab) {
        return tab.state === stateName && angular.equals(tab.params, params);
      }
    }

    function createTab(stateName, params, title) {
      return {
        state: stateName,
        params: params,
        title: title,
      };
    }

    function isWhiteListed(stateName) {
      return stateName.indexOf('root.platform') === 0;
    }

    function insertAfterActiveTab(tab) {
      const index = _.findIndex(tabs, equalsByReference(activeTab));

      if (index >= 0) {
        tabs.splice(index + 1, 0, tab);
      } else {
        tabs.push(tab);
      }
    }

    function navigateToActiveTab() {
      $state.go(activeTab.state, activeTab.params, {inherit: false});
      setWindowTitle(activeTab.title);
    }

    function equalsByReference(value) {
      return function (anotherValue) {
        return value === anotherValue;
      };
    }

    function setWindowTitle(title) {
      $window.document.title = title + ' - SportContract';
    }
  }
})();
