(function () {
  'use strict';

  angular
    .module('app.general')
    .service('LoadingDispatcherScheduler', LoadingDispatcherSchedulerService)
    .service('LoadingDispatcher', LoadingDispatcherService);

  function LoadingDispatcherSchedulerService() {
    var defaultTimeout = 300,
      defaultTimeoutSpread = 100;

    this.scheduleNextDispatch = function () {
      return defaultTimeout + (Math.random() - 0.5) * defaultTimeoutSpread;
    };
  }

  function LoadingDispatcherService($rootScope, LoadingDispatcherScheduler) {
    var queue = [],
      checkQueueTimeout = null;

    this.enqueue = function (priority, callback) {
      queue.push({
        priority: priority,
        callback: callback,
      });

      if (!checkQueueTimeout) {
        checkQueueTimeout = setTimeout(checkQueue);
      }
    };

    function checkQueue() {
      if (!queue.length) {
        checkQueueTimeout = null;
        return;
      }

      queue = _.sortBy(queue, 'priority');
      $rootScope.$apply(queue.shift().callback);

      checkQueueTimeout = setTimeout(checkQueue, LoadingDispatcherScheduler.scheduleNextDispatch());
    }
  }
})();
