class FaceoffStatsController {
  private faceoffEvents;

  private selectedSpots;
  private team;
  private player;
  private stats;
  private eventFilter: {
    playerId?: string;
    value?: 'totalEvents' | 'winEvents' | 'loseEvents';
    opponentId?: string;
  } = {};
  private viewMode: 'player_on_spot' | 'player_on_ice';
  private playersFaceoffData = [];
  private filteredFaceoffEvents = [];
  private selectedEvents = [];

  constructor(
    private GameUtils,
    private GameService,
  ) {
    this.selectedSpots = this.GameUtils.faceoffSpots.map(() => '');
    this.playersFaceoffData = [];
    this.filteredFaceoffEvents = [];
    this.selectedEvents = [];
    this.viewMode = 'player_on_spot';
  }

  $onChanges(changes) {
    if (changes.player || changes.team || changes.stats || changes.faceoffEvents) {
      this.updateViewMode(this.viewMode);
    }
  }

  selectSpots(value, spotIdx?: string) {
    if (spotIdx !== undefined && spotIdx !== null) {
      this.selectedSpots[spotIdx] = value;
    } else {
      this.selectedSpots = this.GameUtils.faceoffSpots.map(() => value);
    }
    if (!this.filteredFaceoffEvents) {
      return;
    }
    this.selectedEvents = this.filteredFaceoffEvents.filter((evt) => {
      switch (this.selectedSpots[evt.extra.spotTeamPerspective]) {
        case 'totalEvents':
          return true;
        case 'winEvents':
          return evt.extra.isWinner;
        case 'loseEvents':
          return !evt.extra.isWinner;
        default:
          return false;
      }
    });
  }

  updateFilter(playerId, value, opponentId) {
    this.eventFilter = {
      playerId: playerId,
      value: value,
      opponentId: opponentId,
    };
    const playerFaceoffData = this.playersFaceoffData.find(
      (player) => player._id === this.eventFilter.playerId,
    );
    if (!playerFaceoffData) {
      this.filteredFaceoffEvents = this.faceoffEvents;
    } else {
      const opponentFaceoffData = playerFaceoffData.opponents.find(
        (opponent) => opponent._id === this.eventFilter.opponentId,
      );
      if (!opponentFaceoffData) {
        this.filteredFaceoffEvents = playerFaceoffData.totalEvents;
      } else {
        this.filteredFaceoffEvents = opponentFaceoffData.totalEvents;
      }
    }
    this.selectSpots(this.eventFilter.value);
  }

  updateViewMode(newViewMode) {
    this.viewMode = newViewMode;
    if (this.viewMode === 'player_on_spot') {
      this.calculatePlayerOnSpot();
    }
    if (this.viewMode === 'player_on_ice') {
      this.calculatePlayerOnIce();
    }
    this.updateFilter(
      this.playersFaceoffData &&
        this.playersFaceoffData.length === 1 &&
        this.player &&
        this.player._id === this.playersFaceoffData[0]._id
        ? this.player._id
        : '',
      '',
      '',
    );
  }

  calculatePlayerOnSpot() {
    if (!this.faceoffEvents) {
      return;
    }
    const playersFaceoffData = {};
    this.faceoffEvents.forEach((evt) => {
      const {player, opponent} = this.getPlayerAndOpponentFromEvent(evt);
      if (!player || !opponent) {
        return;
      }
      const playerData = playersFaceoffData[player.scId] || {
        _id: player.scId,
        name: player.scName || player.firstName + ' ' + player.lastName,
        jerseyNumber: player.jerseyNumber,
        shoots: player.shoots,
        totalEvents: [],
        winEvents: [],
        loseEvents: [],
        opponents: {},
      };

      const opponentData = playerData.opponents[opponent.scId] || {
        _id: opponent.scId,
        name: opponent.scName || opponent.firstName + ' ' + opponent.lastName,
        jerseyNumber: opponent.jerseyNumber,
        shoots: opponent.shoots,
        totalEvents: [],
        winEvents: [],
        loseEvents: [],
      };
      playerData.totalEvents.push(evt);
      opponentData.totalEvents.push(evt);
      if (evt.extra.isWinner) {
        playerData.winEvents.push(evt);
        opponentData.winEvents.push(evt);
      } else {
        playerData.loseEvents.push(evt);
        opponentData.loseEvents.push(evt);
      }

      playerData.opponents[opponent.scId] = opponentData;
      playersFaceoffData[player.scId] = playerData;
    });
    this.playersFaceoffData = _.orderBy(
      Object.values(playersFaceoffData).map((player: any) => ({
        ...player,
        opponents: _.orderBy(
          Object.values(player.opponents),
          (opponent: any) => opponent.totalEvents.length,
          'desc',
        ),
      })),
      (player) => player.totalEvents.length,
      'desc',
    );
  }

  calculatePlayerOnIce() {
    if (!this.stats || !this.stats.skaters || !this.faceoffEvents) {
      return;
    }
    const playerOnIceFaceoffs = this.stats.skaters.map((player) => {
      const playerStat = player.playerStat;
      const playerWithShifts = {
        _id: playerStat.scId,
        jerseyNumber: playerStat.jerseyNumber,
        name: playerStat.scName || playerStat.firstName + ' ' + playerStat.lastName,
        shoots: playerStat.shoots,
        totalEvents: [],
        winEvents: [],
        loseEvents: [],
        opponents: [],
      };
      this.faceoffEvents.forEach((faceoffEvent) => {
        if (
          faceoffEvent.extra.winner.scId === player.scId ||
          faceoffEvent.extra.loser.scId === player.scId
        ) {
          return;
        }
        const homeOnIce = _.get(faceoffEvent, 'computed.home_on_ice', []);
        const awayOnIce = _.get(faceoffEvent, 'computed.away_on_ice', []);
        if ([...homeOnIce, ...awayOnIce].includes(player.playerStat.id)) {
          playerWithShifts.totalEvents.push(faceoffEvent);
          if (faceoffEvent.extra.isWinner) {
            playerWithShifts.winEvents.push(faceoffEvent);
          } else {
            playerWithShifts.loseEvents.push(faceoffEvent);
          }
        }
      });
      return playerWithShifts;
    });
    this.playersFaceoffData = playerOnIceFaceoffs.filter((player) => player.totalEvents.length);
  }

  getPlayerAndOpponentFromEvent(normalizedEvent) {
    if (this.player) {
      if (this.player._id === normalizedEvent.extra.winner.scId) {
        return {
          player: normalizedEvent.extra.winner,
          opponent: normalizedEvent.extra.loser,
        };
      }
      if (this.player._id === normalizedEvent.extra.loser.scId) {
        return {
          player: normalizedEvent.extra.loser,
          opponent: normalizedEvent.extra.winner,
        };
      }
    }
    if (this.team) {
      const teamInEvent = [normalizedEvent.game.home, normalizedEvent.game.away].find(
        (team) => team.scId === this.team._id,
      );
      if (!teamInEvent) {
        return {
          player: null,
          opponent: null,
        };
      }
      if (normalizedEvent.extra.winner.team === teamInEvent.id) {
        return {
          player: normalizedEvent.extra.winner,
          opponent: normalizedEvent.extra.loser,
        };
      }
      if (normalizedEvent.extra.loser.team === teamInEvent.id) {
        return {
          player: normalizedEvent.extra.loser,
          opponent: normalizedEvent.extra.winner,
        };
      }
    }
    return {
      player: null,
      opponent: null,
    };
  }
  playEvents() {
    this.GameService.openVirtualClipViewer('Faceoffs', this.selectedEvents);
  }

  getTitle() {
    const {playerId, opponentId} = this.eventFilter;
    let title = this.viewMode === 'player_on_ice' ? 'Faceoffs - on ice' : 'Faceoffs';
    const player = this.playersFaceoffData.find((p) => p._id === playerId);
    if (!player) {
      return title;
    }
    title = `${title} - #${player.jerseyNumber} ${player.name}`;
    const opponent = player.opponents.find((o) => o._id === opponentId);
    if (!opponent) {
      return title;
    }
    title = `${title} vs #${opponent.jerseyNumber} ${opponent.name}`;
    return title;
  }
}

angular.module('app.general').component('faceoffsStats', {
  templateUrl: 'general/components/faceoffs-stats.html',
  controller: FaceoffStatsController as any,
  bindings: {
    team: '<',
    player: '<',
    game: '<',
    stats: '<',
    range: '<',
    faceoffEvents: '<',
    viewModes: '<',
    hasGameStats: '<',
    totalStats: '<',
    loadedStats: '<',
  },
});
