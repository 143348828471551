(function () {
  'use strict';

  angular.module('app.general').directive('familyRelations', familyRelationsDirective);

  function familyRelationsDirective() {
    return {
      restrict: 'EA',
      templateUrl: 'general/views/familyRelationsDirectiveView.html',
      transclude: true,
      scope: {familyRelations: '='},

      link: function ($scope, $element, $attr, $ctrl, $transclude) {
        $scope.getRelationTitle = getRelationTitle;
        initialize();
        prependTranscludedContent();

        function getRelationTitle(title, personsCount) {
          title = personsCount > 1 ? title + 'S' : title;
          return 'general.FamilyRelations.' + title;
        }

        function initialize() {
          $scope.$watch(
            'familyRelations',
            function () {
              $scope.relations = _.groupBy($scope.familyRelations, 'familyRelationType');
            },
            true
          );
        }

        function prependTranscludedContent() {
          $transclude(function (clone) {
            $element.prepend(clone);
          });
        }
      },
    };
  }
})();
