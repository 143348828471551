type NotificationType = 'game-tagging-publication' | 'game-video-publication';

class NotificationRegistrationService {
  private notificationRegistrationUrl: string;

  constructor(
    private $http,
    private SCConfiguration,
  ) {
    this.notificationRegistrationUrl =
      this.SCConfiguration.getEndpoint() + '/api/notification-registrations';
  }

  public registerForNotificationsOfCompetition(competitionId: string, type: NotificationType) {
    const params = {competitionId: competitionId};
    return this.$http
      .post(`${this.notificationRegistrationUrl}/${type}/competition`, params)
      .then((response) => response.data);
  }

  public registerForNotificationsOfTeam(teamId: string, type: NotificationType) {
    const params = {teamId: teamId};
    return this.$http
      .post(`${this.notificationRegistrationUrl}/${type}/team`, params)
      .then((response) => response.data);
  }

  public unregisterFromNotifications(registrationId: string) {
    return this.$http
      .delete(`${this.notificationRegistrationUrl}/${registrationId}`)
      .then((response) => response.data);
  }

  public getRegistrationForNotificationsOfCompetition(
    competitionId: string,
    type: NotificationType,
  ) {
    const params = {status: 'active'};
    return this.$http
      .get(`${this.notificationRegistrationUrl}/${type}/competition/${competitionId}`, {
        params,
      })
      .then((response) => {
        return response.data;
      });
  }

  public getRegistrationForNotificationsOfTeam(teamId: string, type: NotificationType) {
    const params = {status: 'active'};
    return this.$http
      .get(`${this.notificationRegistrationUrl}/${type}/team/${teamId}`, {
        params,
      })
      .then((response) => {
        return response.data;
      });
  }
}

angular
  .module('app.general')
  .service('NotificationRegistrationService', NotificationRegistrationService);
