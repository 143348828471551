var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var LanguageService = (function () {
    function LanguageService() {
        this.translations = [
            { name: 'english', code: 'en_US' },
            { name: 'german', code: 'de_DE' },
            { name: 'russian', code: 'ru_RU' },
        ];
        this.languages = __spreadArray(__spreadArray([], this.translations, true), [
            { name: 'bulgarian', code: 'bg_BG' },
            { name: 'croatian', code: 'hr_HR' },
            { name: 'czech', code: 'cs_CZ' },
            { name: 'danish', code: 'da_DK' },
            { name: 'dutch', code: 'nl_NL' },
            { name: 'estonian', code: 'et_EE' },
            { name: 'finnish', code: 'fi_FI' },
            { name: 'french', code: 'fr_FR' },
            { name: 'greek', code: 'el_GR' },
            { name: 'hungarian', code: 'hu_HU' },
            { name: 'icelandic', code: 'is_IS' },
            { name: 'italian', code: 'it_IT' },
            { name: 'japanese', code: 'ja_JP' },
            { name: 'korean', code: 'ko_KR' },
            { name: 'latvian', code: 'lv_LV' },
            { name: 'lithuanian', code: 'lt_LT' },
            { name: 'norwegian', code: 'nn_NO' },
            { name: 'polish', code: 'pl_PL' },
            { name: 'portuguese', code: 'pt_PT' },
            { name: 'romanian', code: 'ro_RO' },
            { name: 'slovak', code: 'sk_SK' },
            { name: 'slovenian', code: 'sl_SI' },
            { name: 'spanish', code: 'es_ES' },
            { name: 'swedish', code: 'sv_SE' },
            { name: 'turkish', code: 'tr_TR' },
            { name: 'ukrainian', code: 'uk_UA' },
        ], false);
        this.levels = [
            { name: 'native', level: 4 },
            { name: 'fluent', level: 3 },
            { name: 'conversational', level: 2 },
            { name: 'basic', level: 1 },
        ];
    }
    return LanguageService;
}());
angular.module('app.general').service('LanguageService', LanguageService);