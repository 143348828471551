var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var FaceoffStatsController = (function () {
    function FaceoffStatsController(GameUtils, GameService) {
        this.GameUtils = GameUtils;
        this.GameService = GameService;
        this.eventFilter = {};
        this.playersFaceoffData = [];
        this.filteredFaceoffEvents = [];
        this.selectedEvents = [];
        this.selectedSpots = this.GameUtils.faceoffSpots.map(function () { return ''; });
        this.playersFaceoffData = [];
        this.filteredFaceoffEvents = [];
        this.selectedEvents = [];
        this.viewMode = 'player_on_spot';
    }
    FaceoffStatsController.prototype.$onChanges = function (changes) {
        if (changes.player || changes.team || changes.stats || changes.faceoffEvents) {
            this.updateViewMode(this.viewMode);
        }
    };
    FaceoffStatsController.prototype.selectSpots = function (value, spotIdx) {
        var _this = this;
        if (spotIdx !== undefined && spotIdx !== null) {
            this.selectedSpots[spotIdx] = value;
        }
        else {
            this.selectedSpots = this.GameUtils.faceoffSpots.map(function () { return value; });
        }
        if (!this.filteredFaceoffEvents) {
            return;
        }
        this.selectedEvents = this.filteredFaceoffEvents.filter(function (evt) {
            switch (_this.selectedSpots[evt.extra.spotTeamPerspective]) {
                case 'totalEvents':
                    return true;
                case 'winEvents':
                    return evt.extra.isWinner;
                case 'loseEvents':
                    return !evt.extra.isWinner;
                default:
                    return false;
            }
        });
    };
    FaceoffStatsController.prototype.updateFilter = function (playerId, value, opponentId) {
        var _this = this;
        this.eventFilter = {
            playerId: playerId,
            value: value,
            opponentId: opponentId,
        };
        var playerFaceoffData = this.playersFaceoffData.find(function (player) { return player._id === _this.eventFilter.playerId; });
        if (!playerFaceoffData) {
            this.filteredFaceoffEvents = this.faceoffEvents;
        }
        else {
            var opponentFaceoffData = playerFaceoffData.opponents.find(function (opponent) { return opponent._id === _this.eventFilter.opponentId; });
            if (!opponentFaceoffData) {
                this.filteredFaceoffEvents = playerFaceoffData.totalEvents;
            }
            else {
                this.filteredFaceoffEvents = opponentFaceoffData.totalEvents;
            }
        }
        this.selectSpots(this.eventFilter.value);
    };
    FaceoffStatsController.prototype.updateViewMode = function (newViewMode) {
        this.viewMode = newViewMode;
        if (this.viewMode === 'player_on_spot') {
            this.calculatePlayerOnSpot();
        }
        if (this.viewMode === 'player_on_ice') {
            this.calculatePlayerOnIce();
        }
        this.updateFilter(this.playersFaceoffData &&
            this.playersFaceoffData.length === 1 &&
            this.player &&
            this.player._id === this.playersFaceoffData[0]._id
            ? this.player._id
            : '', '', '');
    };
    FaceoffStatsController.prototype.calculatePlayerOnSpot = function () {
        var _this = this;
        if (!this.faceoffEvents) {
            return;
        }
        var playersFaceoffData = {};
        this.faceoffEvents.forEach(function (evt) {
            var _a = _this.getPlayerAndOpponentFromEvent(evt), player = _a.player, opponent = _a.opponent;
            if (!player || !opponent) {
                return;
            }
            var playerData = playersFaceoffData[player.scId] || {
                _id: player.scId,
                name: player.scName || player.firstName + ' ' + player.lastName,
                jerseyNumber: player.jerseyNumber,
                shoots: player.shoots,
                totalEvents: [],
                winEvents: [],
                loseEvents: [],
                opponents: {},
            };
            var opponentData = playerData.opponents[opponent.scId] || {
                _id: opponent.scId,
                name: opponent.scName || opponent.firstName + ' ' + opponent.lastName,
                jerseyNumber: opponent.jerseyNumber,
                shoots: opponent.shoots,
                totalEvents: [],
                winEvents: [],
                loseEvents: [],
            };
            playerData.totalEvents.push(evt);
            opponentData.totalEvents.push(evt);
            if (evt.extra.isWinner) {
                playerData.winEvents.push(evt);
                opponentData.winEvents.push(evt);
            }
            else {
                playerData.loseEvents.push(evt);
                opponentData.loseEvents.push(evt);
            }
            playerData.opponents[opponent.scId] = opponentData;
            playersFaceoffData[player.scId] = playerData;
        });
        this.playersFaceoffData = _.orderBy(Object.values(playersFaceoffData).map(function (player) { return (__assign(__assign({}, player), { opponents: _.orderBy(Object.values(player.opponents), function (opponent) { return opponent.totalEvents.length; }, 'desc') })); }), function (player) { return player.totalEvents.length; }, 'desc');
    };
    FaceoffStatsController.prototype.calculatePlayerOnIce = function () {
        var _this = this;
        if (!this.stats || !this.stats.skaters || !this.faceoffEvents) {
            return;
        }
        var playerOnIceFaceoffs = this.stats.skaters.map(function (player) {
            var playerStat = player.playerStat;
            var playerWithShifts = {
                _id: playerStat.scId,
                jerseyNumber: playerStat.jerseyNumber,
                name: playerStat.scName || playerStat.firstName + ' ' + playerStat.lastName,
                shoots: playerStat.shoots,
                totalEvents: [],
                winEvents: [],
                loseEvents: [],
                opponents: [],
            };
            _this.faceoffEvents.forEach(function (faceoffEvent) {
                if (faceoffEvent.extra.winner.scId === player.scId ||
                    faceoffEvent.extra.loser.scId === player.scId) {
                    return;
                }
                var homeOnIce = _.get(faceoffEvent, 'computed.home_on_ice', []);
                var awayOnIce = _.get(faceoffEvent, 'computed.away_on_ice', []);
                if (__spreadArray(__spreadArray([], homeOnIce, true), awayOnIce, true).includes(player.playerStat.id)) {
                    playerWithShifts.totalEvents.push(faceoffEvent);
                    if (faceoffEvent.extra.isWinner) {
                        playerWithShifts.winEvents.push(faceoffEvent);
                    }
                    else {
                        playerWithShifts.loseEvents.push(faceoffEvent);
                    }
                }
            });
            return playerWithShifts;
        });
        this.playersFaceoffData = playerOnIceFaceoffs.filter(function (player) { return player.totalEvents.length; });
    };
    FaceoffStatsController.prototype.getPlayerAndOpponentFromEvent = function (normalizedEvent) {
        var _this = this;
        if (this.player) {
            if (this.player._id === normalizedEvent.extra.winner.scId) {
                return {
                    player: normalizedEvent.extra.winner,
                    opponent: normalizedEvent.extra.loser,
                };
            }
            if (this.player._id === normalizedEvent.extra.loser.scId) {
                return {
                    player: normalizedEvent.extra.loser,
                    opponent: normalizedEvent.extra.winner,
                };
            }
        }
        if (this.team) {
            var teamInEvent = [normalizedEvent.game.home, normalizedEvent.game.away].find(function (team) { return team.scId === _this.team._id; });
            if (!teamInEvent) {
                return {
                    player: null,
                    opponent: null,
                };
            }
            if (normalizedEvent.extra.winner.team === teamInEvent.id) {
                return {
                    player: normalizedEvent.extra.winner,
                    opponent: normalizedEvent.extra.loser,
                };
            }
            if (normalizedEvent.extra.loser.team === teamInEvent.id) {
                return {
                    player: normalizedEvent.extra.loser,
                    opponent: normalizedEvent.extra.winner,
                };
            }
        }
        return {
            player: null,
            opponent: null,
        };
    };
    FaceoffStatsController.prototype.playEvents = function () {
        this.GameService.openVirtualClipViewer('Faceoffs', this.selectedEvents);
    };
    FaceoffStatsController.prototype.getTitle = function () {
        var _a = this.eventFilter, playerId = _a.playerId, opponentId = _a.opponentId;
        var title = this.viewMode === 'player_on_ice' ? 'Faceoffs - on ice' : 'Faceoffs';
        var player = this.playersFaceoffData.find(function (p) { return p._id === playerId; });
        if (!player) {
            return title;
        }
        title = "".concat(title, " - #").concat(player.jerseyNumber, " ").concat(player.name);
        var opponent = player.opponents.find(function (o) { return o._id === opponentId; });
        if (!opponent) {
            return title;
        }
        title = "".concat(title, " vs #").concat(opponent.jerseyNumber, " ").concat(opponent.name);
        return title;
    };
    return FaceoffStatsController;
}());
angular.module('app.general').component('faceoffsStats', {
    templateUrl: 'general/components/faceoffs-stats.html',
    controller: FaceoffStatsController,
    bindings: {
        team: '<',
        player: '<',
        game: '<',
        stats: '<',
        range: '<',
        faceoffEvents: '<',
        viewModes: '<',
        hasGameStats: '<',
        totalStats: '<',
        loadedStats: '<',
    },
});