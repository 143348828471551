'use strict';

class ScheduledMaintenanceMessage {
  constructor(private ScheduledMaintenance) {}

  public $onInit() {}

  public showBanner() {
    this.ScheduledMaintenance.showBanner();
  }

  public isScheduledMaintenanceBannerVisible() {
    return this.ScheduledMaintenance.isScheduledMaintenanceBannerVisible;
  }

  public get nextScheduledMaintenance() {
    return this.ScheduledMaintenance.getNextScheduledMaintenance();
  }
}

angular.module('app.general').component('scheduledMaintenanceMessage', {
  controller: ScheduledMaintenanceMessage,
  templateUrl: 'general/components/scheduled-maintenance-message.html',
});
