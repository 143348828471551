(function () {
  'use strict';

  angular.module('app.general').filter('tabTitle', tabTitleFilter);

  function tabTitleFilter() {
    return function (title) {
      if (/^\s*$/.test(title)) {
        title = undefined;
      }

      return title || '...';
    };
  }
})();
