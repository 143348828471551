class ScProtectedModuleController {
  private module: string;
  private header: string;
  private body: string;
  private imagePlaceholder: string;
  private moduleEnabled: boolean;
  private hasPermission: boolean;
  private $promise: any;

  constructor(
    private $http,
    private SCConfiguration,
  ) {}

  $onInit() {
    this.$promise = this.$http
      .get(this.SCConfiguration.getEndpoint() + `/api/${this.module}/access`)
      .then(() => {
        this.moduleEnabled = true;
        this.hasPermission = true;
      })
      .catch((res) => {
        const code = _.get(res, 'status');
        const message = _.get(res, 'data.error');

        if (code === 403 && message === 'MODULE_DISABLED') {
          this.moduleEnabled = false;
        } else if (code === 403) {
          this.moduleEnabled = true;
          this.hasPermission = false;
        } else {
          throw new Error('Unexpected error...');
        }
      });
  }
}

angular.module('app.general').component('scProtectedModule', {
  templateUrl: 'general/components/sc-protected-module.html',
  controller: ScProtectedModuleController,
  transclude: true,
  bindings: {
    module: '@',
    header: '@',
    body: '@',
    imagePlaceholder: '@',
  },
});
