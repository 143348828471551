(function () {
  'use strict';

  angular
    .module('app.general')
    .directive('scLocation', scLocationDirective)
    .filter('scLocation', scLocationFilter)
    .filter('scParentLocation', scParentLocationFilter);

  function scLocationDirective() {
    return {
      restrict: 'A',
      templateUrl: 'general/views/scLocationDirectiveView.html',
      scope: {location: '=scLocation'},
    };
  }

  function scLocationFilter() {
    return function (location) {
      if (!location) {
        return undefined;
      }

      var output = location.name;
      while (location.parentLocality) {
        output += ', ' + location.parentLocality.name;
        location = location.parentLocality;
      }

      if (location.country) {
        output += ', ' + location.country.name;
      }

      return output;
    };
  }

  function scParentLocationFilter() {
    return function (location) {
      if (!location) {
        return undefined;
      }

      var tail = location;

      while (tail && tail.parentLocality) {
        tail = tail.parentLocality;
      }

      return tail;
    };
  }
})();
