class StandAloneAppService {
  private moduleResolver: {
    urls: Record<
      | 'SCTaggingEditorModule'
      | 'SCUploaderModule'
      | 'SCAdvancedAnalyticsModule'
      | 'SCPlayerRatingModule',
      string
    >;
    scriptUrls: Record<
      | 'SCTaggingEditorModule'
      | 'SCUploaderModule'
      | 'SCAdvancedAnalyticsModule'
      | 'SCPlayerRatingModule',
      string
    >;
    modules: Record<
      | 'SCTaggingEditorModule'
      | 'SCUploaderModule'
      | 'SCAdvancedAnalyticsModule'
      | 'SCPlayerRatingModule',
      any
    >;
  };

  constructor(
    private $q,
    private ENV,
    private $http,
    private Toastr,
    private BUILD,
  ) {
    const cloudfrontUrl = 'https://d3cjdsggcpzzw0.cloudfront.net';
    const TAGGING_EDITOR_MODULE_URL = {
      production: `${cloudfrontUrl}/manifests/manifest.taggingEditor.production.json`,
      staging: `${cloudfrontUrl}/manifests/manifest.taggingEditor.staging.json`,
      development: `${cloudfrontUrl}/manifests/manifest.taggingEditor.development.json`,
      local: `http://localhost:9005/wg-taggingEditor.js`,
    };

    const VIDEO_UPLOAD_MODULE_URL = {
      production: `${cloudfrontUrl}/manifests/manifest.uploader.production.json`,
      staging: `${cloudfrontUrl}/manifests/manifest.uploader.staging.json`,
      development: `${cloudfrontUrl}/manifests/manifest.uploader.development.json`,
      local: `http://localhost:9005/wg-uploader.js`,
    };

    const ADVANCE_ANALYTICS_URL = {
      production: `${cloudfrontUrl}/manifests/manifest.advancedAnalytics.production.json`,
      staging: `${cloudfrontUrl}/manifests/manifest.advancedAnalytics.staging.json`,
      development: `${cloudfrontUrl}/manifests/manifest.advancedAnalytics.development.json`,
      local: `http://localhost:9005/wg-advancedAnalytics.js`,
    };

    const PLAYER_RATING_URL = {
      production: `${cloudfrontUrl}/manifests/manifest.playerRating.production.json`,
      staging: `${cloudfrontUrl}/manifests/manifest.playerRating.staging.json`,
      development: `${cloudfrontUrl}/manifests/manifest.playerRating.development.json`,
      local: `http://localhost:9005/wg-playerRating.js`,
    };

    // ENV = 'local';

    this.moduleResolver = {
      urls: {
        SCTaggingEditorModule: TAGGING_EDITOR_MODULE_URL[ENV],
        SCUploaderModule: VIDEO_UPLOAD_MODULE_URL[ENV],
        SCAdvancedAnalyticsModule: ADVANCE_ANALYTICS_URL[ENV],
        SCPlayerRatingModule: PLAYER_RATING_URL[ENV],
      },
      scriptUrls: {} as any,
      modules: {} as any,
    };
  }

  loadModule(
    moduleName:
      | 'SCTaggingEditorModule'
      | 'SCUploaderModule'
      | 'SCAdvancedAnalyticsModule'
      | 'SCPlayerRatingModule',
  ) {
    const scriptId = `${moduleName}-js`;

    // have to perform removal of the existed script,
    // otherwise the style will be broken for widget that depends on shadow dom
    // (styles not being applied the second time the module is open)
    // -- reason is unknown atm, but we definitely should investigate this
    const existedScript = document.getElementById(scriptId);
    if (existedScript) {
      existedScript.parentNode.removeChild(existedScript);
    }

    const $setScriptUrlPromise = this.setScriptUrl(moduleName);

    this.moduleResolver.modules[moduleName] = this.$q((resolve, reject) => {
      $setScriptUrlPromise
        .then((scriptUrl) => {
          const script = document.createElement('script');
          script.async = true;
          script.id = scriptId;
          script.crossOrigin = 'anonymous';
          script.onload = () => {
            const moduleNs = (window as any)[moduleName];
            if (moduleNs) {
              resolve(moduleNs);
            } else {
              reject({
                mesasge: `Failed to load ${moduleName} widget: no exported namespace`,
              });
            }
          };
          script.onerror = () => {
            reject({
              mesasge: `Failed to load ${moduleName} widget: script download failed`,
            });
          };
          script.src = scriptUrl;
          document.head.appendChild(script);
        })
        .catch((err) => reject(err));
    }).catch((err) => {
      const errorMessage = err.message || `Failed to load ${moduleName}`;
      this.Toastr.error(errorMessage);

      // rpeort to TrackJS
      if ((window as any).trackJs) {
        (window as any).trackJs.track(`WIDGET_LOAD_ERROR: ${errorMessage}`);
      }

      throw new Error(errorMessage);
    });

    return this.moduleResolver.modules[moduleName];
  }

  loadTaggingEditorModule() {
    return this.loadModule('SCTaggingEditorModule');
  }

  loadVideoUploadModule() {
    return this.loadModule('SCUploaderModule');
  }

  loadAdvanceAnalyticsModule() {
    return this.loadModule('SCAdvancedAnalyticsModule');
  }

  loadPlayerRatingModule() {
    return this.loadModule('SCPlayerRatingModule');
  }

  setScriptUrl(moduleName) {
    return this.$q((resolve, reject) => {
      if (this.moduleResolver.scriptUrls[moduleName]) {
        resolve(this.moduleResolver.scriptUrls[moduleName]);
        return;
      }

      const moduleUrl = this.moduleResolver.urls[moduleName];
      if (moduleUrl.endsWith('.json')) {
        this.loadManifest(moduleUrl, moduleName)
          .then((data) => {
            this.moduleResolver.scriptUrls[moduleName] = data.url;
            resolve(data.url);
          })
          .catch(() =>
            reject({
              message: `Failed to load ${moduleName} widget: failed to load module manifest file`,
            }),
          );
      } else {
        this.moduleResolver.scriptUrls[moduleName] = moduleUrl;
        resolve(moduleUrl);
      }
    });
  }

  loadAllManifests() {
    const urlKeys = Object.keys(this.moduleResolver.urls);
    const namesAndUrls = urlKeys.map((key) => {
      return {url: this.moduleResolver.urls[key], name: key};
    });

    const promises = namesAndUrls.map((nameAndUrl) =>
      this.loadManifest(nameAndUrl.url, nameAndUrl.name).then((manifest) => {
        return {
          name: nameAndUrl.name,
          ...manifest,
        };
      }),
    );

    return this.$q.all(promises);
  }

  loadManifest(moduleUrl, moduleName) {
    return this.$q((resolve, reject) => {
      this.$http
        .get(moduleUrl, {headers: {'Cache-Control': 'no-cache'}})
        .then((res) => {
          if (!res || !res.data || !res.data.url) {
            reject({message: `Failed to load manifest file for ${moduleName} widget`});
          } else {
            const {url, version, commit} = res.data;
            resolve({url, version, commit});
          }
        })
        .catch(() => {
          reject({
            message: `Failed to load manifest file for ${moduleName} widget`,
          });
        });
    });
  }
}

angular.module('app.general').service('StandAloneAppService', StandAloneAppService as any);
