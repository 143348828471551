'use strict';

class ScDropdownController {
  private isOpen: boolean;

  constructor(private $scope) {
    if (this.exposedAs && this.exposedAs.namespace) {
      const ns = this.exposedAs.namespace;
      $scope.$parent[ns] = {};
      this.exposedAs.apis.forEach((api) => {
        if (typeof this[api] === 'function') {
          $scope.$parent[ns][api] = this[api].bind(this);
        } else {
          $scope.$parent[ns][api] = this[api];
        }
      });
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  close() {
    this.isOpen = false;
  }
}

angular.module('app.general').component('scDropdown', {
  templateUrl: 'general/components/sc-dropdown.html',
  controller: ScDropdownController,
  controllerAs: '$scDropdownCtrl',
  transclude: {
    button: 'scDropdownButton',
    list: 'scDropdownList',
  },
  bindings: {
    exposedAs: '<',
  },
});
