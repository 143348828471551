var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var VideoUsageTracker = (function () {
    function VideoUsageTracker($http, SCConfiguration) {
        this.$http = $http;
        this.SCConfiguration = SCConfiguration;
        this.reportingUrl = this.SCConfiguration.getEndpoint() + '/api/usage';
        this.accountsUsageUrl = this.SCConfiguration.getEndpoint() + '/api/usage/accounts';
    }
    Object.defineProperty(VideoUsageTracker.prototype, "canWatch", {
        get: function () {
            return this.watchToken && this.checkLimit();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoUsageTracker.prototype, "canDownload", {
        get: function () {
            return this.watchToken && this.currentUsage.canDownload && this.checkLimit();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoUsageTracker.prototype, "unlimitedUsage", {
        get: function () {
            return this.watchToken && this.currentUsage.allow > 1000000000;
        },
        enumerable: false,
        configurable: true
    });
    VideoUsageTracker.prototype.startNewSession = function (onSessionStarted, onQuotaExceeded, onDownloadExceeded) {
        var _this = this;
        this.trackingType = 'WATCH';
        this.usageByUrl = {};
        this.downloadedByUrl = {};
        this.reportedUsageByUrl = {};
        this.lastTrackedTimeByUrl = {};
        this.onSessionStarted = onSessionStarted;
        this.onQuotaExceeded = onQuotaExceeded;
        this.onDownloadExceeded = onDownloadExceeded;
        return this.fetchCurrentUsage()
            .then(function () { return _this.onSessionStarted(null, _this.canWatch, _this.canDownload); })
            .catch(function (err) { return _this.onSessionStarted(err, false, false); });
    };
    VideoUsageTracker.prototype.startNewTaggingSession = function (onSessionStarted, onQuotaExceeded, onDownloadExceeded) {
        this.startNewSession(onSessionStarted, onQuotaExceeded, onDownloadExceeded);
        this.trackingType = 'TAGGING';
    };
    VideoUsageTracker.prototype.fetchCurrentUsage = function () {
        var _this = this;
        return this.$http
            .get(this.reportingUrl)
            .then(function (res) { return res.data; })
            .then(function (currentUsage) {
            _this.currentUsage = currentUsage;
            _this.watchToken = currentUsage.watchToken;
            return currentUsage;
        });
    };
    VideoUsageTracker.prototype.track = function (videoUrl, currentTime) {
        if (!this.currentUsage) {
            return;
        }
        var canWatchPreviously = this.checkLimit();
        var lastTrackedTime = this.lastTrackedTimeByUrl[videoUrl];
        var reportedUsage = this.reportedUsageByUrl[videoUrl] || 0;
        if (lastTrackedTime) {
            var delta = currentTime - lastTrackedTime;
            if (delta > 0 && delta < 2) {
                this.usageByUrl[videoUrl] = this.usageByUrl[videoUrl] || 0;
                this.usageByUrl[videoUrl] += delta;
            }
        }
        this.lastTrackedTimeByUrl[videoUrl] = currentTime;
        var usageDelta = this.usageByUrl[videoUrl] - reportedUsage;
        if (usageDelta >= 5) {
            this.sendReport(videoUrl, this.usageByUrl[videoUrl]);
            this.reportedUsageByUrl[videoUrl] = this.usageByUrl[videoUrl];
        }
        var canWatchNow = this.checkLimit();
        if (!canWatchNow) {
            this.onQuotaExceeded(canWatchNow);
        }
        if (canWatchPreviously && !canWatchNow) {
            this.sendReport(videoUrl, this.usageByUrl[videoUrl]);
        }
    };
    VideoUsageTracker.prototype.trackDownload = function (videoUrl, seconds) {
        this.downloadedByUrl[videoUrl] = this.downloadedByUrl[videoUrl] || 0;
        this.downloadedByUrl[videoUrl] += seconds;
        var canDownload = this.checkLimit();
        if (!canDownload) {
            this.onDownloadExceeded(canDownload);
        }
    };
    VideoUsageTracker.prototype.sendReport = function (videoUrl, usage) {
        var params = {
            watchToken: this.watchToken,
            videoUrl: videoUrl,
            usage: usage,
        };
        if (this.trackingType) {
            params.type = this.trackingType;
        }
        this.$http.post(this.reportingUrl, params);
    };
    VideoUsageTracker.prototype.checkLimit = function () {
        if (this.currentUsage.allow > 1000000000) {
            return true;
        }
        return this.getCurrentLimit() > 0;
    };
    VideoUsageTracker.prototype.getCurrentLimit = function () {
        var sessionTotalUsage = 0;
        _.forOwn(this.usageByUrl, function (value, key) {
            sessionTotalUsage += value;
        });
        _.forOwn(this.downloadedByUrl, function (value, key) {
            sessionTotalUsage += value;
        });
        return (this.currentUsage.allow -
            (this.currentUsage.used + this.currentUsage.downloaded + sessionTotalUsage));
    };
    VideoUsageTracker.prototype.fetchUsagesWithAccounts = function () {
        return this.$http
            .get(this.accountsUsageUrl)
            .then(function (res) { return res.data; })
            .then(function (accountsUsage) {
            return accountsUsage.map(function (usage) {
                var canWatch = false;
                if (usage.allow > 1000000000) {
                    canWatch = true;
                }
                else {
                    canWatch = usage.allow - usage.used - usage.downloaded > 0;
                }
                return __assign(__assign({}, usage), { canWatch: canWatch });
            });
        });
    };
    return VideoUsageTracker;
}());
angular.module('app.general').service('VideoUsageTracker', VideoUsageTracker);