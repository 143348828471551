class SharingHelper {
  public updateShareWithRef(lists, updatedGroup, shouldRemove?: boolean) {
    return lists.map((list) => {
      if (list.shareWith && list.shareWith.length) {
        list.shareWith = shouldRemove
          ? list.shareWith.filter((sw) => sw._id !== updatedGroup._id)
          : list.shareWith.map((sw) => {
              if (sw._id === updatedGroup._id) {
                sw.display = updatedGroup.name;
              }
              return sw;
            });
      }
      if (list.subLists && list.subLists.length) {
        list.subLists = this.updateShareWithRef(list.subLists, updatedGroup, shouldRemove);
      }

      return list;
    });
  }
}

angular.module('app.general').service('SharingHelper', SharingHelper);
