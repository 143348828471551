class NewVersionModalController {
  constructor(private $window) {}

  reload() {
    this.$window.location.reload();
  }
}

angular.module('app.general').component('newVersionModal', {
  templateUrl: 'general/components/new-version-modal.html',
  controller: NewVersionModalController,
});
