class NextgenSwitchAnnouncementModalController {
  private modalInstance;

  close() {
    this.modalInstance.close();
  }
}

angular.module('app.general').component('announcementNextgenSwitchModal', {
  templateUrl: 'general/components/announcement-nextgen-switch-modal.html',
  controller: NextgenSwitchAnnouncementModalController as any,
  bindings: {
    modalInstance: '<',
  },
});
