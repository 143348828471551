class EventStrengthFilter {
  private strengthOptions = {
    all: [],
    es: [],
    pp: [],
    pk: [],
    en: [],
    ea: [],
  };
  private filterValue;
  private onChange;

  public changeStrengthFilter(type, strengthOption) {
    const newFilterValue = {
      type: type,
      strength: strengthOption
        ? this.filterValue.strength.indexOf(strengthOption) > -1
          ? this.filterValue.strength.filter((s) => s !== strengthOption)
          : [...this.filterValue.strength, strengthOption]
        : [],
    };
    this.onChange({$filterValue: newFilterValue});
  }

  public getStrengthFilterTitle() {
    return (
      this.strengthOptions[this.filterValue.type]
        .filter((strengthOption) => this.filterValue.strength.indexOf(strengthOption) > -1)
        .join(', ') || 'All'
    );
  }

  isStrengthSelected(option) {
    return !!this.filterValue.strength.find((strengthOption) => strengthOption === option);
  }
}

angular.module('app.general').component('eventStrengthFilter', {
  templateUrl: 'general/components/event-strength-filter.html',
  controller: EventStrengthFilter as any,
  bindings: {
    strengthOptions: '<',
    filterValue: '<',
    onChange: '&',
  },
});
