class LeakyMapFactory {
  constructor(private $timeout) {}

  createWithTTL(ttl, onStateChanged) {
    return new LeakyMap(this.$timeout, ttl, onStateChanged);
  }
}

class LeakyMap {
  private state: any;

  constructor(
    private $timeout,
    private ttl,
    private onStateChanged,
  ) {
    this.state = {};
  }

  add(key, value) {
    this.state[key] = this.state[key] || [];
    const existing: any = _.find(this.state[key], (item) => _.isEqual((<any>item).value, value));

    if (existing && existing.timeout) {
      this.$timeout.cancel(existing.timeout);
      existing.timeout = this.$timeout(() => this.remove(key, value), this.ttl);
    } else {
      this.state[key] = this.state[key].concat([
        {
          value,
          timeout: this.$timeout(() => this.remove(key, value), this.ttl),
        },
      ]);
      this.onStateChanged(key, this.getCurrentState(key));
    }
  }

  remove(key, value) {
    const existing: any = _.find(this.state[key], (item) => _.isEqual((<any>item).value, value));

    if (!existing) {
      return;
    }

    if (existing.timeout) {
      this.$timeout.cancel(existing.timeout);
    }

    this.state[key] = _.reject(this.state[key], (item) => _.isEqual((<any>item).value, value));
    this.onStateChanged(key, this.getCurrentState(key));
  }

  getCurrentState(key) {
    return this.state[key].map((item) => item.value);
  }
}

angular.module('app.general').service('LeakyMapFactory', LeakyMapFactory);
