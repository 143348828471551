'use strict';

class NextgenSwitchAnnouncementController {
  private LOCAL_STORAGE_KEY = 'announcement:nextgen_editor_switch';
  private VERSION = 2;
  constructor(private $uibModal) {}

  $onInit() {
    if (navigator.userAgent.includes('cypress-automation-test')) {
      return;
    }
    const val = parseInt(localStorage.getItem(this.LOCAL_STORAGE_KEY), 10);
    if (!val || val < this.VERSION) {
      this.showModal();
    }
  }

  showModal() {
    const localStorageKey = this.LOCAL_STORAGE_KEY;
    const modalInstance = this.$uibModal.open({
      keyboard: false,
      backdrop: 'static', // prevent close on clicking background
      template:
        '<announcement-nextgen-switch-modal modal-instance="$ctrl.modalInstance" local-storage-key="$ctrl.localStorageKey"/>',
      controller: [
        '$uibModalInstance',
        function ($uibModalInstance) {
          this.localStorageKey = localStorageKey;
          this.modalInstance = $uibModalInstance;
        },
      ],
      controllerAs: '$ctrl',
    });

    modalInstance.result.then(() => {
      localStorage.setItem(this.LOCAL_STORAGE_KEY, this.VERSION + '');
    });
  }
}

angular.module('app.general').component('announcementNextgenSwitch', {
  template: '<span></span>',
  controller: NextgenSwitchAnnouncementController as any,
});
