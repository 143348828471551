class ScLoadingWrapperController {
  private overlayContent: boolean;
  private isLoading: boolean;
  private minimal: boolean;
  private error: any;
  private promise: any;
  private onRetry: void;
  private promiseUuid = null;
  constructor(private uuid) {}

  $onInit() {
    if (this.isLoading === undefined) {
      this.isLoading = true;
    }
    this.overlayContent = typeof this.overlayContent === 'undefined' ? true : this.overlayContent;
  }

  $onChanges(changes) {
    const currentPromise: any = _.get(changes, 'wait.currentValue');

    if (typeof currentPromise === 'boolean') {
      this.isLoading = currentPromise;
    } else if (currentPromise && currentPromise.then) {
      this.setPromise(currentPromise);
    } else {
      this.isLoading = false;
      this.error = null;
    }
  }

  setPromise(promise) {
    this.isLoading = true;
    this.error = null;
    this.promise = promise;
    this.promiseUuid = this.uuid.v4();
    const uuid = this.promiseUuid;

    this.promise
      .then(() => {
        if (uuid !== this.promiseUuid) {
          return;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        if (uuid !== this.promiseUuid) {
          return;
        }
        this.isLoading = false;
        const code = error.status || error.code;
        const message = error.data ? error.data.error : error.message;
        this.error = {code, message};
        throw error;
      });
  }
}

angular.module('app.general').component('scLoadingWrapper', {
  templateUrl: 'general/components/sc-loading-wrapper.html',
  transclude: true,
  bindings: {
    wait: '<',
    overlayContent: '<',
    minimal: '<',
    onRetry: '&?',
    noOverlayBg: '<',
  },
  controller: ScLoadingWrapperController,
});
