'use strict';

class ScheduledMaintenanceBanner {
  constructor(private ScheduledMaintenance) {}

  public $onInit() {}

  public dismissBanner() {
    this.ScheduledMaintenance.dismissBanner();
  }

  public get nextScheduledMaintenance() {
    return this.ScheduledMaintenance.getNextScheduledMaintenance();
  }
}

angular.module('app.general').component('scheduledMaintenanceBanner', {
  controller: ScheduledMaintenanceBanner,
  templateUrl: 'general/components/scheduled-maintenance-banner.html',
});
