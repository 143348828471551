(function () {
  'use strict';

  angular.module('app.general').filter('scUnitConverter', scUnitConverter);

  function scUnitConverter($filter, UserService) {
    var convert = $filter('metricToImperial');

    return function (input, type, appendUnit) {
      if (!input) {
        return null;
      }

      var isImperial =
        _.get(UserService.getUser(), 'settings.unitType', 'metric').toLocaleLowerCase() ===
        'imperial';

      switch (type) {
        case 'height':
          var unit = isImperial ? '' : ' cm';

          return convert(input, 'cm', isImperial) + (appendUnit ? unit : '');

        case 'weight':
          var unit = isImperial ? ' lbs' : ' kg';

          return convert(input, 'kg', isImperial) + (appendUnit ? unit : '');

        default:
          throw new Error('scUnitConverter: no type has been passed!');
      }
    };
  }
})();
