class RichTextProcessor {
  private $linkify;

  constructor(
    private $location,
    private LinkifyIt,
  ) {
    this.$linkify = new LinkifyIt();
  }

  public process(message) {
    if (!message.text || (message.$meta && message.$meta.processed)) {
      return message;
    }

    const matches = this.$linkify.match(message.text);

    if (!matches || !matches.length) {
      return message;
    }

    const parts = [];
    matches.forEach((match, index) => {
      if (index === 0 && match.index !== 0) {
        parts.push(message.text.substr(0, match.index));
      }

      parts.push(this.buildLink(match));

      if (index < matches.length - 1) {
        const nextMatch = matches[index + 1];
        parts.push(message.text.substr(match.lastIndex, nextMatch.index - match.lastIndex));
      } else {
        parts.push(message.text.substr(match.lastIndex));
      }
    });

    const playerLinks = matches
      .filter(
        (match) => this.isSameHost(match.url) && /platform\/player\/([a-f\d]{24})/.test(match.url),
      )
      .map((match) => match.url);

    const teamLinks = matches
      .filter(
        (match) => this.isSameHost(match.url) && /platform\/team\/([a-f\d]{24})/.test(match.url),
      )
      .map((match) => match.url);

    return {
      ...message,
      text: parts.join(''),
      $meta: {
        processed: true,
        playerLinks,
        teamLinks,
      },
    };
  }

  private replaceAt(text, fromIndex, toIndex, replacement) {
    return text.substr(0, fromIndex) + replacement + text.substr(toIndex);
  }

  private buildLink(match) {
    let link = '<a';

    link += ` href="${match.url}"`;

    if (!this.isSameHost(match.url)) {
      link += ` target="_blank" rel="noopener noreferrer"`;
    }

    link += `>${match.text}</a>`;

    return link;
  }

  private isSameHost(url) {
    const hostIndex = url.indexOf(this.$location.host());
    return hostIndex === 7 || hostIndex === 8;
  }
}

angular.module('app.general').service('RichTextProcessor', RichTextProcessor);
