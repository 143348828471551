class StripeService {
  private stripe;
  private elements;
  private card;

  constructor(
    private $window,
    private STRIPE_PUBLIC_KEY,
  ) {
    this.stripe = $window.Stripe(STRIPE_PUBLIC_KEY);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card');
  }
}

angular.module('app.general').service('StripeService', StripeService);
