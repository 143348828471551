(function () {
  'use strict';

  angular.module('app.general').directive('loadingPriority', loadingPriorityDirective);

  function loadingPriorityDirective(LoadingDispatcher) {
    return {
      restrict: 'A',
      require: '^loadingWrapper',
      priority: -1,

      link: function ($scope, $element, $attr, $loadingWrapper) {
        var priority = +$attr.priority || 100;

        $loadingWrapper.blockRendering();
        $loadingWrapper.onReady = function () {
          LoadingDispatcher.enqueue(priority, function () {
            $loadingWrapper.allowRendering();
          });
        };
      },
    };
  }
})();
