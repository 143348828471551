(function () {
  'use strict';

  angular
    .module('app.general')
    .directive('loadingPriorityByState', loadingPriorityByStateDirective);

  function loadingPriorityByStateDirective($state, LoadingDispatcher) {
    return {
      restrict: 'A',
      require: 'loadingWrapper',
      priority: -1,

      link: function ($scope, $element, $attr, $loadingWrapper) {
        var primaryPriority = +$attr.priority || 100;
        var secondaryPriority = +$attr.secondaryPriority || primaryPriority * 2;
        var primaryState = $attr.loadingPriorityByState;

        $loadingWrapper.blockRendering();
        $loadingWrapper.onReady = function () {
          var priority = $state.is(primaryState) ? primaryPriority : secondaryPriority;

          LoadingDispatcher.enqueue(priority, function () {
            $loadingWrapper.allowRendering();
          });
        };
      },
    };
  }
})();
