'use strict';

const SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX = 'scheduled-maintenance:banner-dismissed:';
const SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

const scheduledMaintenanceList: ScheduledMaintenanceModel[] = [
  // NOTE: Months start with 0
  {
    name: 'infrastructure-update-2023-07-03',
    startDate: new Date(Date.UTC(2023, 6, 3, 0, 0)),
    endDate: new Date(Date.UTC(2023, 6, 14, 23, 59)),
  },
];

type ScheduledMaintenanceModel = {
  name: string;
  startDate: Date;
  endDate: Date;
};

class ScheduledMaintenance {
  constructor() {}

  get isScheduledMaintenanceBannerVisible() {
    const nextScheduledMaintenance = this.getNextScheduledMaintenance();
    if (!nextScheduledMaintenance) {
      return false;
    }

    const item = localStorage.getItem(
      SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name,
    );
    return !item;
  }

  dismissBanner() {
    const nextScheduledMaintenance = this.getNextScheduledMaintenance();
    if (!nextScheduledMaintenance) {
      return;
    }
    localStorage.setItem(
      SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name,
      new Date().toUTCString(),
    );
  }

  showBanner() {
    const nextScheduledMaintenance = this.getNextScheduledMaintenance();
    if (!nextScheduledMaintenance) {
      return;
    }
    localStorage.removeItem(
      SCHEDULED_MAINTENANCE_STORAGE_KEY_PREFIX + nextScheduledMaintenance.name,
    );
  }

  getNextScheduledMaintenance() {
    return scheduledMaintenanceList
      .filter(
        (m) => isScheduledMaintenanceInNextSevenDays(m) || isScheduledMaintenanceAtTheMoment(m),
      )
      .sort((m1, m2) => m1.startDate.valueOf() - m2.startDate.valueOf())[0];
  }
}

function isScheduledMaintenanceAtTheMoment(m: ScheduledMaintenanceModel) {
  return (
    m.startDate.valueOf() <= new Date().valueOf() && m.endDate.valueOf() > new Date().valueOf()
  );
}

function isScheduledMaintenanceInNextSevenDays(m: ScheduledMaintenanceModel) {
  const millisecondsToStartDate = m.startDate.valueOf() - new Date().valueOf();
  return millisecondsToStartDate > 0 && millisecondsToStartDate < SEVEN_DAYS_IN_MILLISECONDS;
}

angular.module('app.general').service('ScheduledMaintenance', ScheduledMaintenance);
