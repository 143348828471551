'use strict';

class ScPopoverController {
  private zIndex: number;
  private allowClick: boolean;

  private $transcludedContent;
  private $transcludedScope;
  private $centralPopoverContainer;

  private showingTimeout;
  private hidingTimeout;
  private isShown;
  private id;

  constructor(
    private $element,
    private $scope,
    private $transclude,
    private $q,
    private uuid,
  ) {
    this.isShown = false;
    this.id = this.uuid.v4();
  }

  public $onDestroy() {
    this.hide();
  }

  public show() {
    if (this.isShown) {
      return;
    }

    if (this.hidingTimeout) {
      clearTimeout(this.hidingTimeout);
    }

    this.showingTimeout = setTimeout(() => {
      if (!this.$centralPopoverContainer) {
        const popoverContainer = document.querySelector('#sc-popover-container');

        if (popoverContainer) {
          this.$centralPopoverContainer = angular.element(popoverContainer);
        } else {
          this.$centralPopoverContainer = angular.element(`
                        <div id="sc-popover-container">
                            <div class="sc-popover-arrow"></div>
                            <div class="sc-popover-content"></div>
                        </div>
                    `);
          angular.element(document).find('body').append(this.$centralPopoverContainer);
        }
      }

      const contentPromise = this.$transcludedContent
        ? this.$q.resolve(this.$transcludedContent)
        : this.$q((resolve) => {
            this.$transclude(
              (clone, scope) => {
                this.$transcludedContent = clone;
                this.$transcludedContent.attr('id', this.id);
                this.$transcludedScope = scope;
                resolve(this.$transcludedContent);
              },
              null,
              'content',
            );
          });

      contentPromise.then(() => {
        this.$centralPopoverContainer.find('.sc-popover-content').html(this.$transcludedContent);

        this.$centralPopoverContainer
          .on(`mouseenter.${this.id}`, this.hoveringPopover.bind(this))
          .on(`mouseout.${this.id}`, this.hoveroutPopover.bind(this));

        if (!this.allowClick) {
          this.$centralPopoverContainer.on(`click.${this.id}`, 'a, button', this.hide.bind(this));
        }

        const offset = this.$element.offset();
        const position = this.$element.position();

        const contentHeight = this.$centralPopoverContainer.height();
        const elementHeight = this.$element.height();
        const delta = contentHeight < offset.top ? -contentHeight - 4 : elementHeight + 3;

        // console.log({contentHeight, elementHeight, delta, positionTop: position.top, offsetTop: offset.top})

        this.$centralPopoverContainer.css({
          position: 'absolute',
          top: offset.top + delta,
          left: offset.left,
          display: 'block',
          zIndex: this.zIndex || 10,
        });

        this.isShown = true;
      });
    }, 900);
  }

  public hoveringPopover(e) {
    if (this.hidingTimeout) {
      clearTimeout(this.hidingTimeout);
    }
  }

  public hoveroutPopover(e) {
    // HEADSUP: only work when jQuery included in the page
    if (angular.element(e.relatedTarget).closest('#sc-popover-container').length) {
      return;
    }
    this.scheduleHide();
  }

  public scheduleHide() {
    if (this.showingTimeout) {
      clearTimeout(this.showingTimeout);
    }

    if (this.isShown) {
      if (this.hidingTimeout) {
        clearTimeout(this.hidingTimeout);
      }
      this.hidingTimeout = setTimeout(() => {
        this.hide();
      }, 300);
    }
  }

  public hide() {
    if (!this.$centralPopoverContainer) {
      return;
    }
    this.$centralPopoverContainer.find('[uib-tooltip]').trigger('mouseout');
    this.$centralPopoverContainer.find(`#${this.id}`).detach();
    this.$centralPopoverContainer.off(`mouseenter.${this.id}`).off(`mouseout.${this.id}`);

    if (!this.allowClick) {
      this.$centralPopoverContainer.off(`click.${this.id}`, 'a, button');
    }

    this.isShown = false;
  }
}

angular.module('app.general').component('scPopover', {
  templateUrl: 'general/components/sc-popover.html',
  controller: ScPopoverController,
  controllerAs: '$scPopoverCtrl',
  transclude: {
    trigger: '?scPopoverTrigger',
    content: 'scPopoverContent',
  },
  bindings: {
    zIndex: '<',
    allowClick: '<',
  },
});
