var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var ShareWithUsersAutocomplete = (function () {
    function ShareWithUsersAutocomplete($rootScope, $q, $filter, $timeout, OrganizationService, UserService, CommunicationAPI, _) {
        this.$rootScope = $rootScope;
        this.$q = $q;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.OrganizationService = OrganizationService;
        this.UserService = UserService;
        this.CommunicationAPI = CommunicationAPI;
        this._ = _;
        this.context = 'organization';
        this.searchText = '';
        this.currentUser = UserService.getUser();
        this.membersPromise = $q(function () {
        });
        this.searchPromise = $q.resolve();
        this.members = [];
        this.staffMembers = [];
        this.playerMembers = [];
        this.playerMembersByPosition = {};
        this.selectAllState = {
            players: false,
            staff: false,
        };
        this.editingGroup = null;
    }
    ShareWithUsersAutocomplete.prototype.$onInit = function () {
        this.loadMembersAndGroups();
    };
    ShareWithUsersAutocomplete.prototype.$onChanges = function (changes) {
        if (changes.selections.currentValue !== changes.selections.previousValue) {
            this._syncState();
        }
    };
    ShareWithUsersAutocomplete.prototype.onSelect = function ($selection) {
        if (!this.isSelected($selection)) {
            var selections = (this.selections || []).concat([$selection]);
            this.onSelectionChange({ $selections: selections });
        }
        else {
            var idx = this.selections.findIndex(function (iter) { return iter._id === $selection._id; });
            this.onUnselect($selection, idx);
        }
    };
    ShareWithUsersAutocomplete.prototype.onUnselect = function ($selection, $index) {
        var selections = (this.selections || []).filter(function (item, index) { return index !== $index; });
        this.onSelectionChange({ $selections: selections });
    };
    ShareWithUsersAutocomplete.prototype.select = function ($scTagInstance, item) {
        if (this.editingGroup) {
            var foundIdx_1 = this.editingGroup.members.findIndex(function (mem) { return mem._id === item._id; });
            if (foundIdx_1 === -1) {
                this.editingGroup.members = __spreadArray(__spreadArray([], this.editingGroup.members, true), [item], false);
            }
            else {
                this.editingGroup.members = this.editingGroup.members.filter(function (iter, idx) { return idx !== foundIdx_1; });
            }
        }
        else {
            this.$timeout(function () {
                $scTagInstance.select(item);
            });
        }
    };
    ShareWithUsersAutocomplete.prototype.selectGroup = function ($scTagInstance, group) {
        var selection = {
            _id: group._id,
            type: 'group',
            display: group.name,
            icon: 'fa fa-users',
        };
        $scTagInstance.select(selection);
    };
    ShareWithUsersAutocomplete.prototype.selectAll = function (users) {
        var _this = this;
        var selections = this.selections || [];
        var tobeSelected = users.filter(function (user) { return !_this.isSelected(user); });
        selections = selections.concat(tobeSelected);
        this.onSelectionChange({ $selections: selections });
    };
    ShareWithUsersAutocomplete.prototype.selectNone = function (users) {
        var selections = (this.selections || []).filter(function (selection) { return !users.some(function (user) { return selection._id === user._id; }); });
        this.onSelectionChange({ $selections: selections });
    };
    ShareWithUsersAutocomplete.prototype.isSelected = function (item) {
        if (this.editingGroup) {
            return this.editingGroup.members.some(function (mem) { return mem._id === item._id; });
        }
        return (this.selections || []).some(function (selection) { return selection._id === item._id; });
    };
    ShareWithUsersAutocomplete.prototype.getSelectionCssClass = function (selection) {
        if (selection &&
            selection.type === 'user' &&
            (!selection.organizationId || selection.organizationId !== this.currentUser.account._id)) {
            return 'outside-organization';
        }
        return '';
    };
    ShareWithUsersAutocomplete.prototype.setContext = function (ctx) {
        this.context = ctx;
        if (this.context === 'all' && this.searchText) {
            this.searchForSuggestions(this.searchText);
        }
    };
    ShareWithUsersAutocomplete.prototype.loadMembersAndGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, members, contactGroups;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.membersPromise = this.$q.all([
                            this.OrganizationService.listMembers(),
                            this.OrganizationService.listContactGroups(),
                        ]);
                        return [4, this.membersPromise];
                    case 1:
                        _a = _b.sent(), members = _a[0], contactGroups = _a[1];
                        this.contactGroups = contactGroups;
                        this.members = members || [];
                        this._syncState();
                        return [2];
                }
            });
        });
    };
    ShareWithUsersAutocomplete.prototype.searchForSuggestions = function (text) {
        var _this = this;
        this.searchText = text;
        if (this.context === 'organization') {
            this._syncState();
            return;
        }
        if (!text) {
            this.staffSuggestions = [];
            this.playerSuggestions = [];
            return;
        }
        var excludeIds = this.excludeIds || [];
        this.searchPromise = this.CommunicationAPI.searchContacts(text).then(function (_a) {
            var users = _a.users;
            var userRows = (users || [])
                .map(function (result) { return result._source; })
                .filter(function (user) { return excludeIds.indexOf(user._id) === -1; })
                .map(function (user) { return _this._getSuggestionDisplay(user); });
            _this.playerSuggestions = userRows.filter(function (user) {
                var organizationRoles = user.roles.filter(function (role) { return /-organization/.test(role.context); });
                return (organizationRoles &&
                    organizationRoles.length &&
                    organizationRoles.every(function (role) { return role.roles.every(function (r) { return r.name === 'player'; }); }));
            });
            _this.staffSuggestions = _.xorBy(userRows, _this.playerSuggestions, '_id');
        });
        return this.searchPromise;
    };
    ShareWithUsersAutocomplete.prototype._syncState = function () {
        var _this = this;
        var excludeIds = this.excludeIds || [];
        var members = this.members.filter(function (user) { return excludeIds.indexOf(user._id) === -1; });
        if (!this.editingGroup) {
            members = members.filter(function (user) { return user._id !== _this.currentUser._id; });
        }
        this.staffMembers = members
            .filter(function (member) { return member.roles.length !== 1 || member.roles[0] !== 'Player'; })
            .map(function (user) { return _this._getMemberDisplay(user); });
        this.playerMembers = members
            .filter(function (member) { return member.roles.length === 1 && member.roles[0] === 'Player'; })
            .map(function (user) { return _this._getMemberDisplay(user); });
        this.visibleStaffMembers = this.searchText
            ? this.$filter('filter')(this.staffMembers, this.searchText)
            : this.staffMembers;
        this.visiblePlayerMembers = this.searchText
            ? this.$filter('filter')(this.playerMembers, this.searchText)
            : this.playerMembers;
        this.playerMembersByPosition = _.groupBy(this.visiblePlayerMembers, 'position');
        Object.keys(this.playerMembersByPosition).forEach(function (position) {
            _this.selectAllState[position] = _this.playerMembersByPosition[position].every(function (user) {
                return _this.isSelected(user);
            });
        });
        this.selectAllState.players = this.playerMembers.every(function (user) { return _this.isSelected(user); });
        this.selectAllState.staff = this.staffMembers.every(function (user) { return _this.isSelected(user); });
    };
    ShareWithUsersAutocomplete.prototype._getMemberDisplay = function (user) {
        var firstName = this._.get(user, 'profile.firstName', '');
        var lastName = this._.get(user, 'profile.lastName', '');
        var jerseyNumber = this._.get(user, 'profile.jerseyNumber', '');
        var position = this._.get(user, 'profile.playerPosition', '');
        var fullName = this._.joinIfPresent(' ', firstName, lastName);
        var displayingUser = {
            _id: this._.get(user, '_id', ''),
            type: 'user',
            organizationId: this.currentUser.account._id,
            roles: (user.roles || []).join(', '),
            display: fullName,
            position: position,
            jerseyNumber: jerseyNumber,
        };
        return displayingUser;
    };
    ShareWithUsersAutocomplete.prototype._getSuggestionDisplay = function (user) {
        var _this = this;
        var firstName = this._.get(user, 'profile.firstName', '');
        var lastName = this._.get(user, 'profile.lastName', '');
        var fullName = this._.joinIfPresent(' ', firstName, lastName);
        var belongsToCurrentOrg = (user.roles || []).some(function (role) { return role.id && role.id === _this.currentUser.account._id; });
        return {
            _id: this._.get(user, '_id', ''),
            type: 'user',
            organizationId: belongsToCurrentOrg ? this.currentUser.account._id : null,
            roles: (user.roles || [])
                .filter(function (role) { return !!role.resourceName; })
                .map(function (role) { return (__assign(__assign({}, role), { displayText: role.functions && role.functions.length
                    ? role.functions.join(', ')
                    : role.roles.map(function (r) { return r.display; }).join(', ') })); }),
            display: fullName,
        };
    };
    ShareWithUsersAutocomplete.prototype.showCreateNewGroup = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.updateGroupErrorMsg = '';
        this.editingGroup = {
            name: '',
            members: [],
        };
        this._syncState();
    };
    ShareWithUsersAutocomplete.prototype.cancelCreateNewGroup = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.quitEditingGroup();
    };
    ShareWithUsersAutocomplete.prototype.startEditingGroup = function ($event, group) {
        var _this = this;
        $event.preventDefault();
        $event.stopPropagation();
        this.editingGroup = angular.copy(group);
        this.editingGroupLegacyMembers = group.members.filter(function (member) {
            return (member._id !== _this.currentUser._id &&
                _this.staffMembers.findIndex(function (sug) { return sug._id === member._id; }) === -1 &&
                _this.playerMembers.findIndex(function (sug) { return sug._id === member._id; }) === -1);
        });
        this._syncState();
    };
    ShareWithUsersAutocomplete.prototype.noLongerInOrganization = function (member) {
        return this.editingGroupLegacyMembers.some(function (iter) { return iter._id === member._id; });
    };
    ShareWithUsersAutocomplete.prototype.removeGroupMember = function ($event, member) {
        $event.preventDefault();
        $event.stopPropagation();
        if (!this.editingGroup) {
            return;
        }
        var foundIdx = this.editingGroup.members.indexOf(member);
        this.editingGroup.members = this.editingGroup.members.filter(function (item, idx) { return idx !== foundIdx; });
    };
    ShareWithUsersAutocomplete.prototype.confirmEditingGroup = function ($event) {
        return __awaiter(this, void 0, void 0, function () {
            var editingGroup, id, original, updatedGroup_1, oldGroup, hasGroupNameChanged, selections, newGroup, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.preventDefault();
                        $event.stopPropagation();
                        this.updateGroupErrorMsg = '';
                        editingGroup = this.editingGroup;
                        id = editingGroup._id;
                        original = id && this.contactGroups.find(function (cg) { return cg._id === id; });
                        if (original && angular.equals(editingGroup, original)) {
                            this.quitEditingGroup();
                            return [2];
                        }
                        if (!!this.isUpdatingGroup) return [3, 7];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        this.isUpdatingGroup = true;
                        if (!id) return [3, 3];
                        return [4, this.OrganizationService.updateContactGroup(id, editingGroup)];
                    case 2:
                        updatedGroup_1 = _a.sent();
                        oldGroup = this.contactGroups.find(function (cg) { return cg._id === id; });
                        this.contactGroups = this.contactGroups.map(function (cg) { return (cg._id === id ? updatedGroup_1 : cg); });
                        hasGroupNameChanged = oldGroup.name !== updatedGroup_1.name;
                        if (hasGroupNameChanged) {
                            if (this.selections && this.selections.length) {
                                selections = this.selections.map(function (sel) {
                                    if (sel._id === updatedGroup_1._id) {
                                        sel.display = updatedGroup_1.name;
                                    }
                                    return sel;
                                });
                                this.onSelectionChange({ $selections: selections });
                            }
                            this.$rootScope.$broadcast('contactgroupupdated', updatedGroup_1);
                        }
                        return [3, 5];
                    case 3: return [4, this.OrganizationService.createContactGroup(editingGroup)];
                    case 4:
                        newGroup = _a.sent();
                        this.contactGroups = __spreadArray([newGroup], this.contactGroups, true);
                        _a.label = 5;
                    case 5:
                        this.quitEditingGroup();
                        this.isUpdatingGroup = false;
                        return [3, 7];
                    case 6:
                        e_1 = _a.sent();
                        this.updateGroupErrorMsg = (e_1 && e_1.data && e_1.data.message) || 'Failed to update group.';
                        this.isUpdatingGroup = false;
                        return [3, 7];
                    case 7: return [2];
                }
            });
        });
    };
    ShareWithUsersAutocomplete.prototype.deleteGroup = function ($event, tobeDeleted) {
        return __awaiter(this, void 0, void 0, function () {
            var isGroupSelected;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        $event.preventDefault();
                        $event.stopPropagation();
                        if (!window.confirm('Are you sure you want to delete this group?')) return [3, 2];
                        return [4, this.OrganizationService.deleteContactGroup(tobeDeleted._id)];
                    case 1:
                        _a.sent();
                        this.contactGroups = this.contactGroups.filter(function (cg) { return cg._id !== tobeDeleted._id; });
                        if (this.selections && this.selections.length) {
                            isGroupSelected = this.selections.find(function (sel) { return sel._id === tobeDeleted._id; });
                            if (isGroupSelected) {
                                this.onSelectionChange({
                                    $selections: this.selections.filter(function (sel) { return sel._id !== tobeDeleted._id; }),
                                });
                            }
                        }
                        this.$rootScope.$broadcast('contactgroupdeleted', tobeDeleted);
                        this.quitEditingGroup();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ShareWithUsersAutocomplete.prototype.quitEditingGroup = function () {
        this.editingGroup = null;
        this.updateGroupErrorMsg = '';
        this.editingGroupLegacyMembers = null;
        this._syncState();
    };
    return ShareWithUsersAutocomplete;
}());
angular.module('app.general').component('shareWithUsersAutocomplete', {
    templateUrl: 'general/components/sharing/share-with-users-autocomplete.html',
    controller: ShareWithUsersAutocomplete,
    bindings: {
        selections: '<',
        fixedSelections: '<',
        excludeIds: '<',
        placeholder: '@',
        onSelectionChange: '&?',
    },
});