(function () {
  'use strict';

  angular.module('app.general').component('browserUnsupported', {
    templateUrl: 'general/components/browser-unsupported.html',
    controller: BrowserUnsupportedController,
  });

  function BrowserUnsupportedController($location, RuntimeRequirements) {
    this.basicRuntimeAvailable = RuntimeRequirements.isMinimal;

    this.canBeUpdatedToRecommended = RuntimeRequirements.isVendorRecommended;
    this.recommended = RuntimeRequirements.recommendedVersions;
    this.minimal = RuntimeRequirements.minimalVersions;

    this.proceed = function () {
      RuntimeRequirements.ignoreRecommendation();
      RuntimeRequirements.returnBack();
    };
  }
})();
