import {Representation, RepresentationConflict} from '../../profile/services/RepresentationService';

interface ChangeRegion {
  filter: {region?: string; country?: string};
  options: ChangeOption[];
}

interface ChangeOption {
  kind: Kind;
  action: Action;

  conflict?: RepresentationConflict;
  representations?: Representation[];
}

enum Action {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  Undecided = 'UNDECIDED',
}

enum Kind {
  Conflict = 'CONFLICT',
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED',
  Placeholder = 'PLACEHOLDER',
}

interface ChangeDecision {
  kind: 'confirm' | 'remove' | 'conflict-accept' | 'conflict-reject';
  conflict?: {_id: string};
  representation?: {_id: string};
}

class PalyerRepresentationChangesModalController {
  protected $uibModal;
  protected $representationService;

  protected confirmed: Representation[];
  protected unconfirmed: Representation[];
  protected conflicts: RepresentationConflict[];

  protected choices: ChangeRegion[];

  protected isChanged: boolean;
  protected promise;

  constructor(RepresentationService) {
    this.$representationService = RepresentationService;
  }

  $onInit() {
    const playerIds = [
      ...this.unconfirmed.map((item) => item.player._id),
      ...this.conflicts.map((item) => item.player._id),
    ];

    this.promise = this.$representationService.queryPlayerRerepresentation(
      playerIds.find((item) => !!item),
    ).$promise;
    this.promise.then((representations) => {
      this.confirmed = representations.filter((item) => !!item.confirmed);
      this.recalculateChoices();
    });
  }

  cancel() {
    this.$uibModal.dismiss();
  }

  confirm() {
    this.promise = this.$representationService.applyChangesBulk(this.collectChanges());
    this.promise.then(() => this.$uibModal.close());
  }

  toggle(choice: ChangeRegion, option: ChangeOption) {
    switch (option.action) {
      case Action.Undecided:
      case Action.Reject:
        this.enable(choice, option);
        choice.options
          .filter((item) => item !== option)
          .forEach((option) => (option.action = Action.Reject));
        break;
    }

    this.isChanged = this.collectChanges().length > 0;
  }

  private enable(choice: ChangeRegion, option: ChangeOption) {
    option.action = Action.Accept;

    if (option.kind === Kind.Placeholder) {
      return;
    }

    if (option.kind === Kind.Conflict) {
      this.findNestedRelatedChoices(choice).forEach((choice) => {
        for (const option of choice.options) {
          switch (option.kind) {
            case Kind.Confirmed:
            case Kind.Unconfirmed:
            case Kind.Conflict:
              option.action = Action.Reject;
              break;

            case Kind.Placeholder:
              option.action = Action.Accept;
              break;
          }
        }
      });
    }

    this.findParentRelatedChoices(choice).forEach((choice) => {
      if (
        choice.options.some((item) => item.kind === Kind.Conflict && item.action === Action.Accept)
      ) {
        for (const option of choice.options) {
          option.action = Action.Undecided;
        }
      }
    });
  }

  private findNestedRelatedChoices(choice: ChangeRegion) {
    return this.choices
      .filter((item) => item !== choice)
      .filter((item) => _.isMatch(item.filter, choice.filter));
  }

  private findParentRelatedChoices(choice: ChangeRegion) {
    return this.choices
      .filter((item) => item !== choice)
      .filter(
        (item) =>
          !item.filter.region ||
          (item.filter.region === choice.filter.region && !item.filter.country),
      );
  }

  private recalculateChoices() {
    this.choices = [];

    const affectedRegions = [
      ...this.unconfirmed.map((item) => _.pick(item, ['region', 'country'])),
      ...this.conflicts.map((item) => item.filter),
    ];

    for (const region of _.uniqWith(affectedRegions, _.isEqual)) {
      const options: ChangeOption[] = [];

      this.conflicts.filter(isConflictInRegionStrict(region)).forEach((conflict) => {
        options.push({
          kind: Kind.Conflict,
          action: Action.Undecided,
          conflict,
        });
      });

      this.unconfirmed.filter(isRepresentationInRegionStrict(region)).forEach((representation) => {
        options.push({
          kind: Kind.Unconfirmed,
          action: Action.Undecided,
          representations: [representation],
        });
      });

      if (this.confirmed.filter(isRepresentationInRegionRelaxed(region)).length) {
        options.push({
          kind: Kind.Confirmed,
          action: Action.Undecided,
          representations: this.confirmed.filter(isRepresentationInRegionRelaxed(region)),
        });
      }

      if (options.length === 1 && _.has(options[0], 'representations.0.type') === false) {
        options.push({
          kind: Kind.Placeholder,
          action: Action.Undecided,
        });
      }

      this.choices.push({
        filter: region,
        options,
      });
    }

    this.choices = _.sortBy(
      this.choices,
      (item) => `${item.filter.region || ''}:${item.filter.country || ''}`,
    );
  }

  private collectChanges(): ChangeDecision[] {
    const options = _.flatten(this.choices.map((item) => item.options));
    const changes = options.map((option): ChangeDecision[] => {
      if (option.action === Action.Accept) {
        switch (option.kind) {
          case Kind.Conflict:
            return [
              {
                kind: 'conflict-accept',
                conflict: {_id: option.conflict._id},
              },
            ];

          case Kind.Unconfirmed:
            return [
              {
                kind: 'confirm',
                representation: {_id: option.representations[0]._id},
              },
            ];

          case Kind.Confirmed:
          case Kind.Placeholder:
            return null;
        }
      }

      if (option.action === Action.Reject) {
        switch (option.kind) {
          case Kind.Conflict:
            return [
              {
                kind: 'conflict-reject',
                conflict: {_id: option.conflict._id},
              },
            ];

          case Kind.Unconfirmed:
          case Kind.Confirmed:
            return option.representations.map(
              (item): ChangeDecision => ({
                kind: 'remove',
                representation: {_id: item._id},
              }),
            );

          case Kind.Placeholder:
            return null;
        }
      }
    });

    return _.flatten(changes).filter((item) => !!item);
  }
}

function isConflictInRegionStrict(region: any) {
  return (conflict: RepresentationConflict) => {
    return conflict.filter.region === region.region && conflict.filter.country === region.country;
  };
}

function isRepresentationInRegionStrict(region: any) {
  return (representation: Representation) => {
    return representation.region === region.region && representation.country === region.country;
  };
}

function isRepresentationInRegionRelaxed(region: any) {
  return (representation: Representation) => {
    return _.isMatch(representation, region);
  };
}

angular.module('app.organisation').component('modalPlayerRepresentationChanges', {
  templateUrl: 'general/modals/player-representation-changes.html',
  controller: PalyerRepresentationChangesModalController,
  bindings: {
    $uibModal: '<modalInstance',
    unconfirmed: '<',
    conflicts: '<',
  },
});
