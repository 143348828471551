(function () {
  'use strict';

  angular.module('app.general').filter('scTranslate', scTranslate);

  function scTranslate($translate) {
    return function (input, params) {
      return $translate.instant(input, params);
    };
  }
})();
