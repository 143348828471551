(function () {
  'use strict';

  angular.module('app.general').directive('tabName', tabNameDirective);

  function tabNameDirective(Tabs) {
    function checkRequirements(attributes) {
      if (attributes.name === undefined) {
        throw new Error('You should specify a tab name with "name" attribute');
      }
    }

    function setTabName(name) {
      if (name) {
        Tabs.setActiveTabTitle(name);
      }
    }
    function isActiveTabTitleEmpty() {
      var activeTab = Tabs.getActive();
      return !activeTab.title;
    }

    return {
      restrict: 'E',

      link: function ($scope, element, attributes, tabStateActive) {
        checkRequirements(attributes);

        if (isActiveTabTitleEmpty()) {
          attributes.$observe('name', function (changedValue) {
            setTabName(changedValue);
          });
        }
      },
    };
  }
})();
