var VersionChangeDetectorController = (function () {
    function VersionChangeDetectorController(ENV, $http, $uibModal, WakeUpTimer, $timeout, StandAloneAppService) {
        this.ENV = ENV;
        this.$http = $http;
        this.$uibModal = $uibModal;
        this.WakeUpTimer = WakeUpTimer;
        this.$timeout = $timeout;
        this.StandAloneAppService = StandAloneAppService;
        this.widgetManifests = [];
        this.checkInterval = 10000;
        this.modalVisible = false;
    }
    VersionChangeDetectorController.prototype.$onInit = function () {
        var _this = this;
        this.$http
            .get('/version.json?t=' + Date.now())
            .then(function (res) {
            _this.currentVersion = res.data && res.data.sha;
        })
            .then(function () {
            _this.StandAloneAppService.loadAllManifests().then(function (manifests) {
                _this.widgetManifests = manifests;
            });
        })
            .catch(angular.noop);
        this.WakeUpTimer.onWakeUp(function () { return _this.doCheck(); });
        this.timer = setInterval(function () {
            _this.doCheck();
        }, this.checkInterval);
    };
    VersionChangeDetectorController.prototype.doCheck = function () {
        var _this = this;
        this.$http
            .get('/version.json?t=' + Date.now())
            .then(function (res) {
            if (res.data &&
                res.data.sha &&
                _this.currentVersion &&
                res.data.sha !== _this.currentVersion) {
                _this.onNewVersionAvailable();
            }
        })
            .then(function () { return _this.StandAloneAppService.loadAllManifests(); })
            .then(function (manifests) {
            var _loop_1 = function (manifest) {
                var currentManifest = _this.widgetManifests.find(function (m) { return m.name === manifest.name; });
                if (currentManifest && currentManifest.commit !== manifest.commit) {
                    _this.onNewVersionAvailable();
                    return "break";
                }
            };
            for (var _i = 0, manifests_1 = manifests; _i < manifests_1.length; _i++) {
                var manifest = manifests_1[_i];
                var state_1 = _loop_1(manifest);
                if (state_1 === "break")
                    break;
            }
        })
            .catch(angular.noop);
    };
    VersionChangeDetectorController.prototype.onNewVersionAvailable = function () {
        if (this.timer) {
            clearInterval(this.timer);
        }
        if (this.modalVisible) {
            return;
        }
        this.modalVisible = true;
        this.$uibModal.open({
            size: 'sm',
            template: '<new-version-modal />',
            backdrop: 'static',
            keyboard: false,
        });
    };
    return VersionChangeDetectorController;
}());
angular.module('app.general').component('versionChangeDetector', {
    template: '<span></span>',
    controller: VersionChangeDetectorController,
});