function OnReturnObjectChangedOnce(scopeGetter, handler) {
  return function (target, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function () {
      // eslint-disable-next-line prefer-rest-params
      const value = original.apply(this, arguments);
      // eslint-disable-next-line prefer-rest-params
      const args = Array.from(arguments);

      const unsubscribe = scopeGetter(this).$watch(
        () => value,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            handler(this, value, args);
            unsubscribe();
          }
        },
        true,
      );

      return value;
    };
  };
}
