(function () {
  'use strict';

  angular.module('app.general').directive('scSearchResultItem', scSearchResultItemDirective);

  function scSearchResultItemDirective() {
    return {
      restrict: 'A',
      templateUrl: 'general/views/scSearchResultItemDirectiveView.html',
      transclude: true,

      scope: {
        item: '=scSearchResultItem',
        title: '@',
        module: '@',
        params: '=',
        onClick: '&',
      },

      link: function ($scope) {
        $scope.tabTitle = $scope.title || getTitle($scope.module, $scope.item);
        $scope.tabParams = angular.extend({id: _.get($scope.item, '_id')}, $scope.params);
      },
    };

    function getTitle(module, item) {
      switch (module) {
        case 'player':
        case 'staff':
        case 'agent':
          return _.get(item, 'firstName') + ' ' + _.get(item, 'lastName');

        default:
          return _.get(item, 'name');
      }
    }
  }
})();
