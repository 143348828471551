var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var RichTextProcessor = (function () {
    function RichTextProcessor($location, LinkifyIt) {
        this.$location = $location;
        this.LinkifyIt = LinkifyIt;
        this.$linkify = new LinkifyIt();
    }
    RichTextProcessor.prototype.process = function (message) {
        var _this = this;
        if (!message.text || (message.$meta && message.$meta.processed)) {
            return message;
        }
        var matches = this.$linkify.match(message.text);
        if (!matches || !matches.length) {
            return message;
        }
        var parts = [];
        matches.forEach(function (match, index) {
            if (index === 0 && match.index !== 0) {
                parts.push(message.text.substr(0, match.index));
            }
            parts.push(_this.buildLink(match));
            if (index < matches.length - 1) {
                var nextMatch = matches[index + 1];
                parts.push(message.text.substr(match.lastIndex, nextMatch.index - match.lastIndex));
            }
            else {
                parts.push(message.text.substr(match.lastIndex));
            }
        });
        var playerLinks = matches
            .filter(function (match) { return _this.isSameHost(match.url) && /platform\/player\/([a-f\d]{24})/.test(match.url); })
            .map(function (match) { return match.url; });
        var teamLinks = matches
            .filter(function (match) { return _this.isSameHost(match.url) && /platform\/team\/([a-f\d]{24})/.test(match.url); })
            .map(function (match) { return match.url; });
        return __assign(__assign({}, message), { text: parts.join(''), $meta: {
                processed: true,
                playerLinks: playerLinks,
                teamLinks: teamLinks,
            } });
    };
    RichTextProcessor.prototype.replaceAt = function (text, fromIndex, toIndex, replacement) {
        return text.substr(0, fromIndex) + replacement + text.substr(toIndex);
    };
    RichTextProcessor.prototype.buildLink = function (match) {
        var link = '<a';
        link += " href=\"".concat(match.url, "\"");
        if (!this.isSameHost(match.url)) {
            link += " target=\"_blank\" rel=\"noopener noreferrer\"";
        }
        link += ">".concat(match.text, "</a>");
        return link;
    };
    RichTextProcessor.prototype.isSameHost = function (url) {
        var hostIndex = url.indexOf(this.$location.host());
        return hostIndex === 7 || hostIndex === 8;
    };
    return RichTextProcessor;
}());
angular.module('app.general').service('RichTextProcessor', RichTextProcessor);