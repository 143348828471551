// gamesFromEvents of the events from the player page has the home and away in the full Team object, which has the ID is `_id`
// The events from the other places have home and away in a compact Team object, which has the ID is `scId`
type GameFromEvent = {
  home: {
    scId: null | string;
    _id: null | string;
  };
  away: {
    scId: null | string;
    _id: null | string;
  };
  date: string;
};

class PlaylistGamesController {
  private list;
  private games;
  private $gamesPromise;

  constructor(
    private $q,
    private GameService,
  ) {}

  $onInit() {
    if (this.list.game) {
      return;
    }

    const unifyGameId = (game: GameFromEvent) => {
      if (!game.home._id) {
        game.home._id = game.home.scId;
      }
      if (!game.away._id) {
        game.away._id = game.away.scId;
      }

      return game;
    };
    const gamesFromEvents: GameFromEvent[] = _.uniqBy(
      (this.list.events || []).map((item) => unifyGameId(item.game)),
      (item: GameFromEvent) => `${item.home._id}-${item.away._id}-${item.date}`,
    );

    if (!gamesFromEvents) {
      return;
    }

    this.$gamesPromise = this.$q
      .all(
        gamesFromEvents.map((game: GameFromEvent) => {
          return this.GameService.getGameDetail(game.home._id, game.away._id, game.date).then(
            (response) => ({
              // We need this key to link the gamesFromEvents with fetched games, the response games may has the different game.date
              key: `${game.home._id}-${game.away._id}-${game.date}`,
              ...response.game,
            }),
          );
        }),
      )
      .then((games) => {
        this.games = games;
      });
  }
}

angular.module('app.general').component('playlistGames', {
  templateUrl: 'general/components/playlist-games.html',
  controller: PlaylistGamesController,
  bindings: {
    list: '<',
  },
});
