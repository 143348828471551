'use strict';

interface Window {
  Notification: any;
}

export interface NotificationContent {
  title: string;
  text: string;
  image?: string;
  avatar?: string;
}

export interface NotificationBackend {
  showText(title: string, content: string): angular.IPromise<void>;
  show(params: NotificationContent): angular.IPromise<void>;
}

class NotificationCenter implements NotificationBackend {
  private backends;
  private audioElement: HTMLAudioElement;

  constructor(
    private PageVisibility,
    NativeNotificationBackend,
    MaterialNotificationBackend,
  ) {
    this.backends = {
      native: NativeNotificationBackend,
      material: MaterialNotificationBackend,
    };

    this.audioElement = document.createElement('audio');
    this.audioElement.src = '/assets/sounds/notification.ogg';
  }

  get native() {
    return this.backends.native;
  }
  get material() {
    return this.backends.material;
  }

  playSound() {
    this.audioElement.play();
  }

  showText(title: string, content: string) {
    const backend = this.chooseBackend();
    return backend.showText(title, content);
  }

  show(params: NotificationContent) {
    const backend = this.chooseBackend();
    return backend.show(params);
  }

  private chooseBackend() {
    if (
      this.native.isEnabled &&
      (!this.PageVisibility.state || !this.PageVisibility.state.isVisible)
    ) {
      return this.native;
    } else {
      return this.material;
    }
  }
}

class NativeNotificationBackend implements NotificationBackend {
  private handler;

  constructor(
    private $q,
    private $rootScope,
  ) {
    this.handler = window.Notification;
  }

  get isSupported() {
    return !!this.handler;
  }

  get isEnabled() {
    return this.isSupported && this.handler.permission === 'granted';
  }

  get isDenied() {
    return this.isSupported && this.handler.permission === 'denied';
  }

  requestPermissions() {
    this.handler.requestPermission(() => {
      this.$rootScope.$digest();
    });
  }

  showText(title: string, content: string) {
    const notification = new this.handler(title, {body: content});

    return new this.$q((resolve) => {
      notification.onclick = (event) => {
        resolve();
        window.focus();
        notification.close();
      };
    });
  }

  show(params: NotificationContent) {
    const notification = new this.handler(params.title, {
      body: params.text,
      image: params.image,
      icon: params.avatar,
    });

    return new this.$q((resolve) => {
      notification.onclick = (event) => {
        resolve();
        window.focus();
        notification.close();
      };
    });
  }
}

class MaterialNotificationBackend implements NotificationBackend {
  private resolve;
  private toast;

  constructor(
    private $q,
    private $mdToast,
  ) {}

  showText(title: string, content: string) {
    this.toast = this.$mdToast.show(
      this.$mdToast
        .scNotification()
        .title(title)
        .content(content)
        .resolve(() => this.onClick()),
    );

    return new this.$q((resolve) => (this.resolve = resolve));
  }

  show(params: NotificationContent) {
    this.toast = this.$mdToast.show(
      this.$mdToast
        .scNotification()
        .title(params.title)
        .content(params.text)
        .image(params.image)
        .icon(params.avatar)
        .resolve(() => this.onClick()),
    );

    return new this.$q((resolve) => (this.resolve = resolve));
  }

  onClick() {
    this.$mdToast.hide(this.toast);
    this.resolve();
  }
}

function createMaterialNotificationTemplates($mdToastProvider) {
  $mdToastProvider.addPreset('scNotification', {
    methods: ['content', 'title', 'image', 'icon', 'resolve'],
    options: function () {
      return {
        template: `
                    <md-toast ng-click="$ctrl.resolve()">
                        <div class="md-toast-content">
                            <img class="sc-toast-icon" ng-src="{{ $ctrl.icon }}" ng-if="$ctrl.icon">

                            <div class="sc-toast-wrapper">
                                <div class="md-subhead" role="alert" aria-relevant="all" aria-atomic="true">
                                    {{ $ctrl.title }}
                                </div>

                                {{ $ctrl.content }}

                                <div style="max-width: 100px" ng-if="$ctrl.image">
                                    <img ng-src="{{ $ctrl.image }}" />
                                </div>
                            </div>
                        </div>
                    </md-toast>
                `,

        controller: angular.noop,
        controllerAs: '$ctrl',
        bindToController: true,

        position: 'left bottom',
        hideDelay: 5000,
      };
    },
  });
}

angular
  .module('app.general')
  .config(createMaterialNotificationTemplates)
  .service('NotificationCenter', NotificationCenter)
  .service('NativeNotificationBackend', NativeNotificationBackend)
  .service('MaterialNotificationBackend', MaterialNotificationBackend);
