angular.module('app.general').directive('scFlowExternalPictureDrop', scFlowExternalPictureDrop);

function scFlowExternalPictureDrop($http, API_END_POINT) {
  return {
    scope: false,
    restrict: 'A',
    require: '^flowInit',

    link: function ($scope, $element, $attr) {
      $element.bind('drop', (event: {originalEvent: DragEvent}) => {
        if (event.originalEvent.dataTransfer.types.indexOf('text/html') > -1) {
          const html = $(event.originalEvent.dataTransfer.getData('text/html'));
          const image = html.filter('img').length
            ? (html.filter('img')[0] as HTMLImageElement)
            : (html.find('img')[0] as HTMLImageElement);

          if (image) {
            const url = image.src
              ? image.src
              : (_.get(/^(http[^ ]+) ?/.exec(image.srcset), '1') as string);

            loadImageAsFile(url).then((file) => {
              // run outside of the digect cycle
              setTimeout(() => {
                $scope.$flow.addFile(file);
              });
            });
          }
        }
      });
    },
  };

  function loadImageAsFile(url: string): Promise<File> {
    if (!url) {
      throw new Error('Cant get image url');
    }

    return $http
      .get(`${API_END_POINT}/api/cors?url=${encodeURIComponent(url)}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const type = response.headers('content-type');
        const file = new Blob([response.data], {type}) as any;

        if (!type.startsWith('image/')) {
          throw new Error('Not an image!');
        }

        let extension = '.jpg';

        switch (type) {
          case 'image/png':
            extension = '.png';
            break;

          case 'image/webm':
            extension = '.webm';
            break;

          case 'image/gif':
            extension = '.gif';
            break;
        }

        Object.assign(file, {
          name: `TEMP_IMAGE${extension}`,
        });

        return file;
      });
  }
}
