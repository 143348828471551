(function () {
  angular
    .module('app.general')
    .directive('scTranslate', scTranslateDirective)
    .directive('scTranslateMessage', scTranslateMessageDirective)
    .directive('translate', translateDirectiveOverride);

  // super fast translations!
  function scTranslateDirective($filter) {
    return {
      restrict: 'AE',

      link: function ($scope, $element, $attr) {
        if ($attr.dynamicKey) {
          $attr.$observe('dynamicKey', function (dynamicKey) {
            var inputParams = $attr.params ? $scope.$eval($attr.params) : undefined;
            var outputHtml = $attr.outputHtml ? $scope.$eval($attr.outputHtml) : undefined;

            if (!outputHtml) {
              $element.text($filter('scTranslate')(dynamicKey, inputParams));
            } else {
              $element.html($filter('scTranslate')(dynamicKey, inputParams));
            }
          });
        } else {
          $scope.$$postDigest(function () {
            var inputText = $element.text(),
              inputParams = $attr.params ? $scope.$eval($attr.params) : undefined,
              outputHtml = $attr.outputHtml ? $scope.$eval($attr.outputHtml) : undefined;

            if (!outputHtml) {
              $element.text($filter('scTranslate')(inputText, inputParams));
            } else {
              $element.html($filter('scTranslate')(inputText, inputParams));
            }
          });
        }
      },
    };
  }

  // priority workaround for `ng-message`
  function scTranslateMessageDirective($filter) {
    return {
      restrict: 'AE',
      priority: -100, // workaround for elements with terminal directive and transclusion, run `link` after the transclusion :)

      link: function ($scope, $element, $attr) {
        $scope.$$postDigest(function () {
          var inputText = $element.text(),
            inputParams = $attr.params ? $scope.$eval($attr.params) : undefined;

          $element.text($filter('scTranslate')(inputText, inputParams));
        });
      },
    };
  }

  // The original `translate` directive have recently became too heavy for us...
  // It began to use own $scope, $watchers, attribute $observers...
  //
  // Most of the original directive's features we don't really use:
  // * usually we have a constant "translation id"
  // * usually we have a constant "translations params"
  //
  // original implementation:
  // https://github.com/angular-translate/angular-translate/blob/master/src/directive/translate.js
  function translateDirectiveOverride() {
    console.warn(
      'DEPRECATION: directive "translate" is very slow and costly, and it should be avoided. Please use "sc-translate" instead.'
    );

    return {
      restrict: 'AE',
    };
  }
})();
