class TimeTextInputController {
  private time: string;
  private previousTime: string;
  private inputRef;

  static COLON_MERGE_REGEX = /:{2,}/g;
  static TIME_CLEANUP_REGEX = /^(\d{1,2}:\d{1,2}|\d{1,2}:?)/;

  protected notifyChange: ({$time: number}) => void;

  constructor(private $filter) {}

  onChange() {
    [this.time] = TimeTextInputController.TIME_CLEANUP_REGEX.exec(
      this.time.replace(TimeTextInputController.COLON_MERGE_REGEX, ':'),
    ) || [null];

    if (
      this.time &&
      this.previousTime &&
      this.previousTime.length === 1 &&
      this.time.length === 2 &&
      !this.time.includes(':')
    ) {
      this.time += ':';
    }

    this.previousTime = this.time;
  }

  onSubmit() {
    this.inputRef.blur();
    this.notifyChange({$time: this.$filter('timeToSeconds')(this.time)});
  }
}

angular.module('app.general').component('timeTextInput', {
  templateUrl: 'general/components/time-text-input.html',
  controller: TimeTextInputController as any,
  bindings: {
    notifyChange: '&onChange',
  },
});
