(function () {
  'use strict';

  angular.module('app.general').directive('stopPropagation', stopPropagationDirective);

  function stopPropagationDirective() {
    return {
      restrict: 'A',
      link: function ($scope, $element) {
        $element.on('click', (event) => event.stopPropagation());
      },
    };
  }
})();
